import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useAxios from 'api/axiosCustom';
import { useSelector, useDispatch } from 'react-redux';
import { addHealthyCounseling, allReset } from 'store/reducer/counselingReducer';
import useObserver from 'shared/hook/useObserver';
import { LoadingModal } from 'components/modal';
import { SearchInputContainer } from 'components/search';
import { PreDiagnosisCard } from 'components/card';
import { RootState } from 'store/reducer/rootReducer';
import moment from 'moment';

export function HealthyCounseling() {
	const navigate = useNavigate();
	const axios = useAxios();
	const dispatch = useDispatch();

	const [page, setPage] = useState<number>(-10);
	const [isLimit, setIsLimit] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [searchName, setSearchName] = useState<string>('');
	const [searchBirth, setSearchBirth] = useState<string>('');

	const { setRef } = useObserver(
		async (entry: any, observer) => {
			observer.observe(entry.target);
			setPage((prevState) => (prevState += 10));
		},
		{ threshold: 0.8 }
	);

	useEffect(() => {
		if (searchName || searchBirth) {
			(async () => {
				setPage(0);
				setIsLimit(true);
				dispatch(allReset());
				setIsLoading(true);

				const res = await axios.get(
					`/counseling/admin/ishealthy?name=${searchName}&birthDate=${searchBirth}`
				);
				dispatch(addHealthyCounseling(res.data));
				if (res.data.length < 10) {
					setIsLimit(false);
				}
				setIsLoading(false);
				page === 0 && setPage(10);
			})();
		} else {
			(async () => {
				setPage(0);
				setIsLimit(true);
				dispatch(allReset());
				setIsLoading(true);
				const res = await axios.get(`/counseling/admin/ishealthy`);
				dispatch(addHealthyCounseling(res.data));
				if (res.data.length < 10) {
					setIsLimit(false);
				}
				setIsLoading(false);
				page === 0 && setPage(10);
			})();
		}
	}, [searchName, searchBirth]);

	useEffect(() => {
		if (page > 9 && isLimit) {
			if (searchName || searchBirth) {
				(async () => {
					setIsLoading(true);
					const res = await axios.get(
						`/counseling/admin/ishealthy?offset=${page}&name=${searchName}&birthDate=${searchBirth}`
					);

					setTimeout(() => {
						dispatch(addHealthyCounseling(res.data));
					}, 200);
					if (res.data.length < 10) {
						setIsLimit(false);
					}
					setIsLoading(false);
				})();
			} else {
				(async () => {
					setIsLoading(true);
					const res = await axios.get(
						`/counseling/admin/ishealthy?offset=${page}`
					);
					setTimeout(() => {
						dispatch(addHealthyCounseling(res.data));
					}, 200);
					if (res.data.length < 10) {
						setIsLimit(false);
					}
					setIsLoading(false);
				})();
			}
		}
	}, [page]);

	const counselingArr = useSelector((state: RootState) => state.counseling.healthyArr);

	function onCardClick(element: any) {
		navigate(`${element.id}`, {
			state: { id: element.id, profileId: element.ProfileId },
		});
	}

	return (
		<Cover>
			<LoadingModal isModalVisible={isLoading} />
			<SearchInputContainer
				name={searchName}
				setName={setSearchName}
				birth={searchBirth}
				setBirth={setSearchBirth}
			/>
			{/* {counselingArr.length <= 1
        ? null */}
			{counselingArr?.map((element, idx) => (
				<PreDiagnosisCard
					key={idx}
					name={element.Profile.name}
					hospitalName={element.hospital?.hospitalName}
					gender={element.Profile.gender}
					birth={`${element.Profile.birthDate.slice(0, 4)}년생`}
					date={moment(element.createdAt).format('MM월 DD일 HH:mm')}
					state={element.status}
					healthy={element.isHealthy}
					department={element.department}
					symptom={element.symptom}
					mainText={element.additionalSymptom}
					onClick={() => onCardClick(element)}
				/>
			))}
			<div ref={setRef} style={{ height: '10px' }} />
		</Cover>
	);
}

const Cover = styled.div`
	margin: 0 0 32px;
	display: flex;
	flex-wrap: wrap;
	gap: 24px 20px;
`;
