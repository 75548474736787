import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextInput } from 'components/input';
import { PrimaryBtn } from 'components/button';
import { useSelector, useDispatch } from 'react-redux';
import { answerSubmit } from 'store/reducer/testPreviewReducer';
import { RootState } from 'store/reducer/rootReducer';
import { TwoBtnModal } from 'components/modal';

export default function InputText() {
	const questionIndex = useSelector((state: RootState) => state.testPreview.index);
	const question = useSelector((state: RootState) => state.testPreview.question[questionIndex]);
	const questionLength = useSelector((state: RootState) => state.testPreview.question.length);
	const answerArr = useSelector((state: RootState) => state.testPreview.answered);

	const dispatch = useDispatch();

	const [textValue, setTextValue] = useState<string>('');
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const locale = localStorage.getItem('locale');

	useEffect(() => {
		window.scrollTo(0, 0);
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		answerArr[questionIndex] === undefined
			? null
			: setTextValue(answerArr[questionIndex].answer);
	}, [questionIndex]);

	function onInputTextNextBtn() {
		if (textValue.length === 0) {
			setIsModalVisible(true);
		} else {
			dispatch(
				answerSubmit({
					id: question.id,
					answer: textValue,
					enAnswer: textValue,
				})
			);
			setTextValue('');
		}
	}

	function onInputTextChange({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) {
		setTextValue(value);
	}

	function onModalLeftBtn() {
		setIsModalVisible(false);
	}

	function onModalNextBtn() {
		dispatch(
			answerSubmit({
				id: question.id,
				answer: textValue,
				enAnswer: textValue,
			})
		);
		setTextValue('');
	}

	return (
		<Cover>
			<TwoBtnModal
				isModalVisible={isModalVisible}
				mainText='증상을 작성하지 않고 진행하시겠어요?'
				leftBtnText='아니요'
				leftBtnClick={onModalLeftBtn}
				rightBtnText='진행하기'
				rightBtnClick={onModalNextBtn}
			/>
			<p className='mainText'>
				{locale === 'en' ? question.EnQuestion.mainQuestion : question.mainQuestion}
			</p>
			<p className='subText'>
				{locale === 'en' ? question.EnQuestion.subQuestion : question.subQuestion}
			</p>
			<AnswerContainer>
				<TextInput value={textValue} onChange={onInputTextChange} />
			</AnswerContainer>
			{questionLength - 1 === questionIndex ? (
				<PrimaryBtn
					label={locale === 'ko' ? '제출' : 'Submit'}
					onClick={onInputTextNextBtn}
				/>
			) : (
				<PrimaryBtn
					label={locale === 'ko' ? '다음' : 'Continue'}
					onClick={onInputTextNextBtn}
				/>
			)}
		</Cover>
	);
}

const Cover = styled.div`
	margin-top: 24px;
	padding-bottom: 32px;
	.mainText {
		font-size: 24px;
		line-height: 133%;
		font-weight: bold;
		margin: 0 0 24px;
		word-break: keep-all;
	}
`;

const AnswerContainer = styled.div`
	margin: 0 0 50px;
`;
