import React from 'react';
import styled from 'styled-components';
import styleConstant from 'data/constant/styleConstant';

interface UserInfoProps {
	name: string;
	hospitalName?: string;
	birth?: string;
	gender?: string;
	job?: string;
}

export function UserInfo({ name, hospitalName, birth, gender, job }: UserInfoProps) {
	const genderSwitch = (genderNum: number) => {
		switch (genderNum) {
			case 1:
				return '남자';
			case 2:
				return '여자';
			default:
				return '둘 다 아니에요';
		}
	};
	return (
		<Cover>
			<Header>
				<div>상담자 정보</div>
			</Header>
			<InfoContainer>
				<p>이름</p>
				<p className='info'>{name}</p>
				<section className='container'>
					<div>
						<p>나이</p>
						<p className='info last'>{birth}</p>
					</div>
					<div>
						<p>성별</p>
						<p className='info last'>{genderSwitch(Number(gender))}</p>
					</div>
				</section>
				<p>문진 진행 병원</p>
				<p className='info'>{hospitalName}</p>
				{/* <p>직업</p>
        <p className="info last">{job}</p> */}
			</InfoContainer>
		</Cover>
	);
}

const Cover = styled.div`
	margin-bottom: 48px;
	width: 328px;
`;

const Header = styled.section`
	display: flex;
	justify-content: space-between;
	font-size: ${styleConstant.FONT_SIZE.small};
	font-weight: bold;
`;

const InfoContainer = styled.div`
	width: 100%;
	background-color: #f8f8f8;
	padding: 16px;
	margin-top: 8px;
	border-radius: 20px;
	font-size: ${styleConstant.FONT_SIZE.small};
	p {
		margin: 0 0 10px 0;
	}
	.info {
		font-size: ${styleConstant.FONT_SIZE.medium};
		font-weight: bold;
		margin: 0 0 10px 0;
	}
	.last {
		margin: 0 0 10px;
	}
	.container {
		display: flex;
		div {
			width: 120px;
			margin: 20px 0;
		}
	}
`;
