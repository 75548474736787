import { PrimaryBtn } from 'components/button';
import styleConstant from 'data/constant/styleConstant';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AskCard } from './Askcard';
import { HalfAskCard } from './HalfAskCard';
import { NumberInput } from './NumInput';
interface StateProps {
	mainText: string;
	subText: string;
	answerList: string;
	inputUnit: string;
	nanBtnLabel: string;
	answerType: string;
	s3Img: string;
	inputType: string;
}
export function InputNumBtn(state: StateProps) {
	let answers: string[];
	let EnAnswers: string[];
	const [locale, setLocale] = useState(0);
	const toggleLocale = () => {
		locale === 0 ? setLocale(1) : setLocale(0);
	};
	if (state.answerList) {
		answers = state.answerList.split('@@')[0].split(';').slice(0, -1);
		EnAnswers = state.answerList.split('@@')[1].split(';').slice(0, -1);
	} else {
		answers = [];
		EnAnswers = [];
	}
	return (
		<>
			<Cover>
				{/* <p>{state.answerType + "/" + state.inputType}</p> */}
				<p className='mainText'>{state.mainText.split('@@')[locale]}</p>
				<p className='subText'>{state?.subText?.split('@@')[locale]}</p>
				{state.s3Img ? (
					<ImgContainer>
						<img
							src={state.s3Img}
							alt='question image'
							width='380px'
							height='200x'
							// objectFit="contain"
						/>
					</ImgContainer>
				) : null}
				<NumberInput
					label={
						state.inputUnit === null
							? ''
							: state.inputUnit.split('@@')[locale]
					}
				/>
				<CardContainer>
					{state.nanBtnLabel === '있음' ? (
						<AskCard
							item={state.nanBtnLabel.split('@@')[locale]}
							checked={false}
						/>
					) : null}
					{locale === 0
						? answers.map((text, index) =>
								text.trim() ? (
									<HalfAskCard
										key={index}
										item={text.replace(/^\s+|\s+$/gm, '')}
									/>
								) : null
						  )
						: EnAnswers.map((text, index) =>
								text.trim() ? (
									<HalfAskCard
										key={index}
										item={text.replace(/^\s+|\s+$/gm, '')}
									/>
								) : null
						  )}
				</CardContainer>
				<PrimaryBtn onClick={toggleLocale} label={'다른 언어로 보기'} />
			</Cover>
		</>
	);
}

const Cover = styled.div`
	width: 375px;
	margin: auto;
	margin-top: 24px;
	padding-bottom: 32px;
	position: relative;
	font-weight: bold;
	.mainText {
		font-size: ${styleConstant.FONT_SIZE.large};
		margin: 0;
	}
	.subText {
		font-size: ${styleConstant.FONT_SIZE.medium};
		margin: 8px 0 24px;
	}
`;

const ImgContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	height: auto;
	margin: 0 0 24px;
	img {
		object-fit: scale-down;
	}
`;
const AnswerContainer = styled.div<{ isInput: true | false }>`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin: ${({ isInput }) => (isInput ? '0' : '0 0 32px')};
`;

const InputContainer = styled.div`
	/* margin: 24px 0 32px; */
	display: flex;
	flex-direction: column;
	gap: 16px;
	p {
		font-size: ${styleConstant.FONT_SIZE.medium};
		font-weight: bold;
		margin: 0;
	}
`;
const CardContainer = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	justify-content: space-between;
	margin: 32px 0;
`;
