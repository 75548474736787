import React, { useState } from 'react';
import styled from 'styled-components';
import { HeaderBar } from 'components/header';
import { SelectItem } from 'components/recommendationManage';
import styleConstant from 'data/constant/styleConstant';
import menuConstant from 'data/constant/menuConstant';
import { useNavigate } from 'react-router-dom';
import routerConstant from 'data/constant/routerConstant';

export default function RecommendationManagePage() {
	const navigate = useNavigate();

	return (
		<Cover>
			<HeaderBar noBack={true} nowPath={menuConstant.RECOMMENDATION_MANAGE} />
			<p className='mainText'>추천 관리</p>
			<MenuContainer>
				<p className={'select'}>추천 정보</p>
				<p className={''} onClick={() => navigate(routerConstant.CONDITION)}>
					조건 설정
				</p>
			</MenuContainer>
			<SelectItem />
		</Cover>
	);
}

const Cover = styled.div`
	width: 1024px;
	margin: 0 auto;
	.mainText {
		font-size: 40px;
		font-weight: bold;
		margin-top: 48px;
	}
`;

const MenuContainer = styled.div`
	display: flex;
	gap: 16px;
	font-size: ${styleConstant.FONT_SIZE.medium};
	p {
		cursor: pointer;
	}
	.select {
		font-weight: bold;
		padding-bottom: 4px;
		border-bottom: solid 2px #1bbcff;
	}
`;
