import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import styleConstant from 'data/constant/styleConstant';
import { PrimaryInput, TextInput } from 'components/input';
import { PrimaryDropDown } from 'components/dropDown';
import { PrimaryBtn } from 'components/button';

import { useLocation, useNavigate } from 'react-router-dom';
import routerConstant from 'data/constant/routerConstant';
import useAxios from 'api/axiosCustom';
import { ErrorAlert } from 'components/alert';

export function RecommendationInfo() {
	const [title, setTitle] = useState('');
	const [category, setCategory] = useState('영양제');
	const [introText, setIntroText] = useState('');
	const [link, setLink] = useState('');
	const [ifListId, setIfListId] = useState('');
	const [mainText, setMainText] = useState('');
	const [isActive, setIsActive] = useState(1);
	const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);

	const navigate = useNavigate();
	const axios = useAxios();
	const location = useLocation();

	const isBlank = /^\s+|\s+$/g;

	useEffect(() => {
		if (location.state) {
			setTitle(location.state.value.title);
			setCategory(location.state.value.category);
			setIsActive(location.state.value.isActive);
			setIntroText(location.state.value.introText);
			setMainText(location.state.value.mainText);
			setIfListId(location.state.value.ifListId);
			setLink(location.state.value.link);
		}
	}, []);

	const handleInput = (e: any, key: string) => {
		switch (key) {
			case 'title': {
				setTitle(e.target.value);
				break;
			}

			case 'introText': {
				setIntroText(e.target.value);
				break;
			}
			case 'link': {
				setLink(e.target.value);
				break;
			}
			case 'mainText': {
				setMainText(e.target.value);
				break;
			}
			case 'ifListId': {
				setIfListId(e.target.value);
				break;
			}
		}
	};
	const handleDropdown = (e: any) => {
		setCategory(e);
	};

	const handleToggleActive = () => {
		setIsActive(isActive === 0 ? 1 : 0);
	};

	const handleSaveBtn = async () => {
		if (
			title.replace(isBlank, '') === '' ||
			introText === '' ||
			link === '' ||
			mainText === ''
		) {
			setIsAlertOpen(true);
			setTimeout(() => {
				setIsAlertOpen(false);
			}, 1000);
		} else {
			const body = {
				title,
				category,
				introText,
				mainText,
				codeText: '123456',
				link,
				isActive,
				ifListId,
			};
			let res;
			location.state
				? (res = await axios.put(
						`/admin/recommend/item/${location.state?.value.id}`,
						body
				  ))
				: (res = await axios.post(`/admin/recommend/item`, body));

			if (res.status === 200) {
				navigate(routerConstant.RECOMMENDATION);
			}
		}
	};

	return (
		<Cover>
			<ErrorAlert
				label={
					title === ''
						? '제목을 입력해주세요.'
						: title.replace(isBlank, '') === ''
						? '제목에 공백 이외의 문자를 입력해주세요'
						: introText === ''
						? '짧은소개를 입력해주세요.'
						: link === ''
						? '링크를 입력해주세요.'
						: mainText === ''
						? '설명을 입력해주세요'
						: ''
				}
				toggle={isAlertOpen}
			/>
			<BtnContainer>
				<PrimaryBtn
					label={isActive === 0 ? '활성화' : '비활성화'}
					color={isActive === 0 ? 'none' : 'red'}
					onClick={handleToggleActive}
				/>
				<PrimaryBtn
					label={location.state ? '수정' : '저장'}
					onClick={handleSaveBtn}
				/>
			</BtnContainer>
			<InfoContainer>
				<InfoGrid>
					<p>제목</p>
					<PrimaryInput
						value={title}
						onChange={(e) => {
							handleInput(e, 'title');
						}}
					/>
					<p>카테고리</p>
					<PrimaryDropDown
						selectItem={category}
						items={['영양제', '병원']}
						itemClick={(e) => {
							handleDropdown(e);
						}}
					/>
					<p>짧은소개</p>
					<TextInput
						value={introText}
						onChange={(e) => {
							handleInput(e, 'introText');
						}}
					/>
					<p>링크</p>
					<PrimaryInput
						value={link}
						onChange={(e) => {
							handleInput(e, 'link');
						}}
					/>
					<p>연결할 조건 idx. (공백 없이 ;로 구분)</p>
					<PrimaryInput
						value={ifListId}
						onChange={(e) => {
							handleInput(e, 'ifListId');
						}}
					/>
				</InfoGrid>
				<InfoGrid>
					<p>설명</p>
					<TextInput
						value={mainText}
						height='calc( 100% - 50px )'
						onChange={(e) => {
							handleInput(e, 'mainText');
						}}
					/>
				</InfoGrid>
			</InfoContainer>
		</Cover>
	);
}

const Cover = styled.div`
	margin-bottom: 48px;
	width: 100%;
`;

const BtnContainer = styled.div`
	width: 250px;
	position: absolute;
	top: -96px;
	right: 0;
	display: flex;
	gap: 20px;
`;

const InfoContainer = styled.div`
	display: flex;
	gap: 20px;
	width: 100%;
	background-color: #f8f8f8;
	padding: 16px;
	margin-top: 8px;
	border-radius: 20px;
	font-size: ${styleConstant.FONT_SIZE.small};
`;
const InfoGrid = styled.div`
	width: 50%;
	p {
		margin: 0 0 -3px 0;
	}
`;
