import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import useAxios from 'api/axiosCustom';
import { useNavigate } from 'react-router-dom';
import { PrimaryInput } from 'components/input';
import { PrimaryBtn, TestBtn } from 'components/button';
import { TwoBtnModal, LocaleModal } from 'components/modal';

import styleConstant from 'data/constant/styleConstant';
import { LoadingModal } from 'components/modal';
import { Switch, Table } from 'antd';
import { ISymptom } from 'data/interface/interviewManage';
import { SymptomAddModal } from './symptomAddModal';
import { PrimaryDropDown } from 'components/dropDown';
import { relative } from 'path';

export function SelectSymptom() {
	const axios = useAxios();
	const [columnData, setColumnData] = useState<ISymptom[]>([]);
	const [totalCount, setTotalCount] = useState<number>(0);
	const [page, setPage] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [searchSymptom, setSearchSymptom] = useState<string>('');
	const [searchQurstionId, setSearchQurstionId] = useState<string>('');
	const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [isSearch, setIsSearch] = useState<boolean>(false);
	const [symptomEditValue, setSymptomEditValue] = useState<any>({});
	const [isLocaleModalVisible, setIsLocaleModalVisible] = useState<boolean>(false);
	const [selectId, setSelectId] = useState<number>();

	const listRequest = useCallback(() => {
		(async () => {
			setIsLoading(true);
			if (isSearch) {
				const res = await axios.get(
					`/department/findSymptomById?search=${searchSymptom}&searchId=${searchQurstionId}&offset=${page}`
				);
				setColumnData(res.data.symptoms);

				setTotalCount(res.data.totalCount);
			} else {
				const res = await axios.get(
					`/department/findSymptomById?search=&offset=${page}`
				);
				setColumnData(res.data.symptoms);
				setTotalCount(res.data.totalCount);
			}
			setIsLoading(false);
		})();
	}, [page]);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const res = await axios.get(`/department/findSymptomById?search=&offset=${page}`);
			setColumnData(res.data.symptoms);
			setTotalCount(res.data.totalCount);
			setIsLoading(false);
		})();
	}, []);

	useEffect(() => {
		listRequest();
	}, [page]);

	useEffect(() => {
		if (!isModalVisible) {
			setSymptomEditValue({});
		}
	}, [isModalVisible]);

	function onSymptomEdit(value: any) {
		setSymptomEditValue(value);
		setIsModalVisible(true);
	}

	const handleTestBtn = (id: number) => {
		setIsLocaleModalVisible(true);
		setSelectId(id);
	};

	const handleChangeActivation = async (e: boolean, value: any) => {
		if (e) {
			const res = await axios.put(`/symptom/${value.id}`, {
				name: value.name + '@@' + value.EnSymptom?.name,
				isActive: false,
			});
			res.status === 200 && listRequest();
		} else {
			const res = await axios.put(`/symptom/${value.id}`, {
				name: value.name + '@@' + value.EnSymptom?.name,
				isActive: true,
			});
			res.status === 200 && listRequest();
		}
	};

	const columns = [
		{
			title: 'idx.',
			dataIndex: 'id',
			key: 'idx',
			render: (text: any, value: any, index: any) => (
				<p
					className={
						index === 0 || index % 2 === 0 ? 'text idx color' : 'text idx'
					}
					onClick={() => onSymptomEdit(value)}>
					{text}
				</p>
			),
		},
		{
			title: '증상',
			dataIndex: 'name',
			key: 'symptom',
			render: (text: any, value: any, index: any) => (
				<p
					className={
						index === 0 || index % 2 === 0
							? 'text symptom color'
							: 'text symptom'
					}
					onClick={() => onSymptomEdit(value)}>
					{text}
				</p>
			),
		},
		{
			title: '관련 질문 idx.',
			dataIndex: 'questionIds',
			key: 'qeustionIds',
			render: (text: any, value: any, index: any) => (
				<p
					className={
						index === 0 || index % 2 === 0
							? 'text questionIdx color'
							: 'text questionIdx'
					}
					onClick={() => onSymptomEdit(value)}>
					{text.replace(/;/g, ', ').replace(/,\s*$/, '')}
				</p>
			),
		},
		{
			title: '',
			dataIndex: 'isActive',
			key: 'isActive',
			render: (isActive: boolean, value: any, index: number) => (
				<Test
					className={index === 0 || index % 2 === 0 ? 'color' : ''}
					style={{ width: '120px', position: 'relative' }}
					onClick={() => setSymptomEditValue(value)}>
					{/* <TestBtn
						label='질문 편집'
						onClick={() => handleAddQuestionBtn(value)}
					/> */}
					<TestBtn label='테스트하기' onClick={() => handleTestBtn(value.id)} />
				</Test>
			),
		},

		{
			title: '활성 상태',
			dataIndex: `isActive`,
			key: 'isActive',
			render: (isActive: boolean, value: any, index: number) => (
				<Test
					style={{ width: '100px' }}
					className={index === 0 || index % 2 === 0 ? 'color' : ''}>
					<CustomSwitch
						checkedChildren='ON'
						unCheckedChildren='OFF'
						style={{
							margin: '0 0 0 20px',
							backgroundColor: styleConstant.COLOR.CTA.DEFAULT,
						}}
						onChange={() => handleChangeActivation(isActive, value)}
						checked={isActive}
						loading={isLoading}
					/>
				</Test>
			),
		},
		{
			title: '',
			dataIndex: `isActive`,
			key: 'isActive',
			render: (text: number, value: any, index: number) => (
				<p
					style={{ width: '75px' }}
					className={
						index === 0 || index % 2 === 0
							? 'color delete text'
							: 'delete text'
					}
					onClick={() => {
						setSymptomEditValue(value);
						setIsDeleteModal(true);
					}}>
					삭제
				</p>
			),
		},
	];

	function onDeleteModalCancelBtn() {
		setIsDeleteModal(false);
	}

	async function onDeleteModalCheckBtn() {
		if (symptomEditValue) {
			await axios.delete(`/symptom/${symptomEditValue.id}`);
			setIsDeleteModal(false);
			setSymptomEditValue({});
			listRequest();
		}
	}

	function onModalCancelBtn() {
		setIsModalVisible(false);
	}

	function handleInput(e: any, key: string) {
		switch (key) {
			case 'symptom':
				setSearchSymptom(e.target.value);
				break;
			case 'questionId':
				setSearchQurstionId(e.target.value);
				break;
		}
	}

	function onAddSymptomBtn() {
		setIsModalVisible(true);
	}

	function onPageChange(e: any) {
		setPage(10 * (e - 1));
	}

	async function onSearchBtn() {
		setIsLoading(true);
		setIsSearch(true);
		setPage(0);
		const res = await axios.get(
			`/department/findSymptomById?search=${searchSymptom}&searchId=${searchQurstionId}&offset=${page}`
		);

		setTotalCount(res.data.totalCount);
		setColumnData(res.data.symptoms);
		setIsLoading(false);
	}

	const handleKorean = () => {
		localStorage.setItem('locale', 'ko');
		window.open(`/test?id=${selectId}`, 'test', 'width=400, height=800');
		setIsLocaleModalVisible(false);
	};

	const handleEnglishBtn = () => {
		localStorage.setItem('locale', 'en');
		window.open(`/test?id=${selectId}`, 'test', 'width=400, height=800');
		setIsLocaleModalVisible(false);
	};
	return (
		<Cover>
			<LoadingModal isModalVisible={isLoading} />
			<TwoBtnModal
				isModalVisible={isDeleteModal}
				mainText='정말로 삭제하시겠습니까?'
				leftBtnText='아니요'
				leftBtnClick={onDeleteModalCancelBtn}
				rightBtnText='삭제하기'
				rightBtnColor='red'
				rightBtnClick={onDeleteModalCheckBtn}
			/>
			<SymptomAddModal
				isModalVisible={isModalVisible}
				setIsModalVisible={setIsModalVisible}
				value={symptomEditValue}
				setValue={setSymptomEditValue}
				onClick={onModalCancelBtn}
				listRequest={listRequest}
			/>
			<LocaleModal
				isModalVisible={isLocaleModalVisible}
				setIsModalVisible={setIsLocaleModalVisible}
				onEnglishBtn={handleEnglishBtn}
				onKoreanBtn={handleKorean}
			/>
			<SearchContainer>
				<p className='searchMainText'>검색하실 정보를 입력해주세요.</p>
				<InputContainer>
					<div className='input'>
						<PrimaryInput
							label='증상'
							value={searchSymptom}
							onChange={(e) => handleInput(e, 'symptom')}
						/>
					</div>
					<div className='input'>
						<PrimaryInput
							label='질문 idx.'
							value={searchQurstionId}
							onChange={(e) => handleInput(e, 'questionId')}
						/>
					</div>
					<div className='btn'>
						<PrimaryBtn label='검색' onClick={onSearchBtn} />
					</div>
				</InputContainer>
			</SearchContainer>
			<div className='btnContainer'>
				<PrimaryBtn label='새 증상 추가' onClick={onAddSymptomBtn} />
			</div>
			<CustomTable
				columns={columns}
				dataSource={columnData}
				pagination={{
					total: totalCount,
					showSizeChanger: false,
					onChange: onPageChange,
				}}
			/>
		</Cover>
	);
}

const Cover = styled.div`
	margin-top: 48px;
	.btnContainer {
		width: 210px;
		margin: 0 auto 32px;
	}
	.delete {
		color: #e44b55;
		cursor: pointer;
	}
	.text {
		/* text-align: center; */
		padding-left: 17px;
		line-height: 56px;
		height: 56px;
		margin: 0;
		cursor: pointer;
		/* white-space: nowrap; */
		word-break: break-all;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
		white-space: nowrap;
	}
	.color {
		background-color: #f8f8f8;
	}
	.idx {
		width: 74px;
	}
	.symptom {
		width: 150px;
	}
	.questionIdx {
		width: 505px;
	}
`;

const SearchContainer = styled.div`
	width: 100%;
	height: 188px;
	box-sizing: border-box;
	padding: 24px 24px 32px;
	margin-bottom: 48px;
	border-radius: 20px;
	box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.08);
	.searchMainText {
		font-size: ${styleConstant.FONT_SIZE.large};
		font-weight: bold;
		margin: 0;
	}
`;

const InputContainer = styled.div`
	display: flex;
	gap: 16px;
	margin-top: 12px;
	align-items: center;
	.input {
		width: 50%;
		margin: 0;
		padding: 0;
	}
	.btn {
		width: 120px;
		margin: 0;
		padding-top: 24px;
	}
`;

const CustomTable = styled(Table)`
	.ant-table-thead {
		th {
			padding: 8px 16px;
			border-top: 1px solid #f0f0f0;
			background-color: #fff;
			&::before {
				display: none;
			}
		}
	}
	.ant-table-tbody {
		td {
			padding: 0;
		}
	}
	/* .ant-table-cell-row-hover {
    background-color: #000 !important;
  } */
`;

const Test = styled.div`
	display: flex;
	align-items: center;
	height: 55px;
	gap: 20px;
`;

const CustomSwitch = styled(Switch)`
	margin: 2px 0 0;
	transform: scale(1.1);
`;
