import React from "react";
import styled from "styled-components";
import { ReactComponent as CheckCircle } from "assets/icon/interview/checkCircle.svg";

export interface AskCardProps {
  item: string;
  checked: boolean;
  isAlert?: boolean;
  clicked?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export function AskCard({
  item,
  checked,
  isAlert = false,
  clicked,
}: AskCardProps) {
  return (
    <Cover checked={checked} isAlert={isAlert} onClick={clicked}>
      <ItemContainer>{item}</ItemContainer>
      {checked ? <CheckCircle /> : <UnCheckContainer />}
    </Cover>
  );
}

const Cover = styled.div<{ checked: boolean; isAlert: boolean }>`
  width: 100%;
  height: 56px;
  padding: ${({ isAlert }) => (isAlert ? "15px" : "16px")};
  border: ${({ checked, isAlert }) =>
    checked
      ? "solid 1px #1bbcff"
      : isAlert
      ? "solid 2px #ffbf3f"
      : "solid 1px #cfcfcf"};
  border-radius: 12px;
  font-family: "Noto Sans KR", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  :hover {
    border: 1px solid #27b4eb;
  }
  :active {
    border: 1px solid #0083b1;
  }
`;

const ItemContainer = styled.div`
  width: 90%;
  font-size: 16px;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: -0.2px;
`;

const UnCheckContainer = styled.div`
  min-width: 24px;
  min-height: 24px;
  border: solid 2px #cfcfcf;
  border-radius: 12px;
`;
