import React from 'react';
import styled from 'styled-components';
import styleConstant from 'data/constant/styleConstant';

export interface primaryBtnProps {
	type?: 'button' | 'submit';
	label: string;
	color?: 'none' | 'blue' | 'red';
	className?: string;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function PrimaryBtn({
	type = 'button',
	label,
	color = 'blue',
	className,
	onClick,
}: primaryBtnProps) {
	return (
		<Primary className={className} type={type} onClick={onClick} color={color}>
			{label}
		</Primary>
	);
}

const Primary = styled.button<{ color: 'none' | 'blue' | 'red' }>`
	width: 100%;
	height: 48px;
	margin: 8px 0;
	text-align: center;
	background-color: ${({ color }) => (color === 'blue' ? '#1bbcff' : '#fff')};
	border: ${({ color }) => (color === 'blue' ? 'solid 1px #1bbcff' : 'solid 1px #cfcfcf')};
	color: ${({ color }) => (color === 'blue' ? '#fff' : color === 'none' ? '#222' : '#e44b55')};
	border-radius: 15px;

	font-size: ${styleConstant.FONT_SIZE.medium};
	font-weight: ${({ color }) => (color === 'blue' ? 'bold' : '500')};
	letter-spacing: -0.2px;
	cursor: pointer;
`;
