import { createSlice } from "@reduxjs/toolkit";
import { ICounseling } from "data/interface/counseling";
import { IChat } from "data/interface/chat";

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    allCounselingList: [{} as ICounseling],
    selectCounseling: {} as ICounseling,
    allChatList: [{} as IChat],
    selectChat: {} as IChat,
    ischat: false,
  },
  reducers: {
    addAllCounselingList: (state, action) => {
      let classificationArr: ICounseling[] = [];
      action.payload?.map((counseling: ICounseling) => {
        if (counseling.Chats.length === 0) {
          classificationArr.push({ ...counseling, status: "waiting" });
        } else {
          counseling.Chats[0].isEnd
            ? classificationArr.push({ ...counseling, status: "end" })
            : classificationArr.push({ ...counseling, status: "progress" });
        }
      });

      state.allCounselingList = classificationArr;
    },
    selectCard: (state, action) => {
      state.allCounselingList.map((element) => {
        if (action.payload === element.id) {
          state.selectCounseling = element;
        }
      });
    },
    addAllChatList: (state, action) => {
      state.allChatList = action.payload;
    },
    selectChat: (state, action) => {
      state.ischat = false;
      state.allChatList.map((element) => {
        if (action.payload === element.UserCounselingId) {
          state.selectChat = element;
          state.ischat = true;
        }
      });
    },
    selectChatRoom: (state, action) => {
      state.ischat = false;
      state.allChatList.map((element) => {
        if (action.payload === element.id) {
          state.selectChat = element;
          state.ischat = true;
        }
      });
    },
    selectDirectChat: (state) => {
      state.ischat = false;
    },
  },
});

export const {
  addAllCounselingList,
  selectCard,
  addAllChatList,
  selectChat,
  selectChatRoom,
  selectDirectChat,
} = chatSlice.actions;
export default chatSlice.reducer;
