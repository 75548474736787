import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useAxios from 'api/axiosCustom';
import { PrimaryInput } from 'components/input';
import styleConstant from 'data/constant/styleConstant';
import { ReactComponent as SearchIcon } from 'assets/icon/interview/searchIcon.svg';
import { useDebounce } from 'hooks/useDebounce';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { IQuestion, ISymptom } from 'data/interface/interviewManage';
import { ItemBox } from './itemBox';
import { SearchResultBox } from './searchResultBox';

interface QuestionAddComponentProps {
	value?: ISymptom;
	questionList: IQuestion[];
	setQuestionList: any;
	questionIdList: string[];
	setQuestionIdList: any;
}

export function QuestionAddComponent({
	value,
	questionList,
	setQuestionList,
	questionIdList,
	setQuestionIdList,
}: QuestionAddComponentProps) {
	const axios = useAxios();
	const [inputText, setInputText] = useState<string>('');
	const [searchData, setSearchData] = useState<any>([]);

	useEffect(() => {
		setInputText('');
	}, [value]);

	const debouncedInputText = useDebounce(inputText, 200);
	useEffect(() => {
		if (debouncedInputText) {
			questionSearch(debouncedInputText);
		}
	}, [debouncedInputText]);

	function onMemberInfoChange(e: any) {
		setInputText(e.target.value);
	}

	const questionSearch = async (debouncedInputText: any) => {
		const res = await axios.get(`/question?limit=9999&mainQuestion=${debouncedInputText}`);

		const newQuestionIdList = questionList?.map((question) => question?.id);
		const filteredQuestionList = res.data?.questions?.filter(
			(question: any) => !newQuestionIdList?.includes(question.id)
		);
		setSearchData(filteredQuestionList);
	};

	const handleAddQuestion = (question: any) => {
		questionIdList?.indexOf(question?.id) === -1 &&
			questionList?.push(question) &&
			questionIdList?.push(question?.id.toString());
		setSearchData([]);
		setInputText('');
	};

	const handleDeleteQuestion = (deletedQuestion: any) => {
		const newQuestionList = questionList.filter(
			(question: any) => question?.id !== deletedQuestion?.id
		);
		setQuestionList(newQuestionList);
		const newQuestionIdList = newQuestionList.map((question) => question.id.toString());
		setQuestionIdList(newQuestionIdList);
	};

	const handleEnd = (result: any) => {
		if (!result.destination) return;
		const newQuestionList = questionList;
		const [reorderedQuestion] = questionList.splice(result.source.index, 1);
		newQuestionList.splice(result.destination.index, 0, reorderedQuestion);
		setQuestionList(newQuestionList);
		setQuestionIdList(newQuestionList.map((question: IQuestion) => question.id.toString()));
	};

	return (
		<QuestionComponent>
			<SearchContainer>
				<PrimaryInput
					label='질문 검색'
					icon={<SearchIcon />}
					value={inputText}
					onChange={onMemberInfoChange}
					placeHolder=' 원하는 질문을 입력해주세요.'
				/>
				{inputText !== '' &&
					(searchData.length > 0 ? (
						<ResultContainer>
							{searchData.map((question: any) => (
								<SearchResultBox
									item={question}
									itemName={question.mainQuestion}
									handleAddItem={handleAddQuestion}
								/>
							))}
						</ResultContainer>
					) : (
						<ResultContainer>
							<Result>조회된 데이터가 없습니다.</Result>
						</ResultContainer>
					))}
			</SearchContainer>
			<QuestionTitle>질문 리스트</QuestionTitle>
			<DragDropContext onDragEnd={handleEnd}>
				<Droppable droppableId='droppable'>
					{(provided, snapshot) => {
						return (
							<QuestionContainer
								isDragging={snapshot.isDraggingOver}
								{...provided.droppableProps}
								ref={provided.innerRef}>
								{questionList?.length ? (
									questionList?.map((question: any, index) => (
										<Draggable
											key={question?.id}
											draggableId={question?.id?.toString()}
											index={index}>
											{(provided, snapshot) => (
												<ItemBox
													key={question?.id}
													id={question?.id}
													title={
														question?.mainQuestion
													}
													provided={provided}
													snapshot={snapshot}
													handleDeleteItem={
														handleDeleteQuestion
													}
													item={
														question
													}></ItemBox>
											)}
										</Draggable>
									))
								) : (
									<NoQuestion>질문을 포함해주세요.</NoQuestion>
								)}
							</QuestionContainer>
						);
					}}
				</Droppable>
			</DragDropContext>
		</QuestionComponent>
	);
}

const QuestionComponent = styled.div`
	border: 1px solid ${styleConstant.COLOR.GRAY_3};
	border-radius: 16px;
	padding: 10px 10px 20px;
	box-shadow: 0 8px 15px rgba(0, 0, 0, 0.08);
`;
const SearchContainer = styled.div`
	position: relative;
`;

const QuestionTitle = styled.div`
	height: 20px;
	color: #919d9d;
	font-size: 12px;
	line-height: 20px;
	margin-bottom: 10px;
`;
const ResultContainer = styled.div`
	position: absolute;
	width: 100%;
	max-height: 350px;
	overflow: auto;
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	border: 1px solid #ecf0f0;
	border-radius: 16px;
	padding: 10px 0px;
	z-index: 1000;
	box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
		0 9px 28px 8px rgb(0 0 0 / 5%);
`;
const Result = styled.div`
	background-color: #ffffff;
	padding: 10px 16px;
	line-height: 24px;
	border-top: 1px solid ${styleConstant.COLOR.GRAY_1};
	border-bottom: 1px solid ${styleConstant.COLOR.GRAY_1};
	:hover {
		background: #f4fbfe;
	}
`;

const QuestionContainer = styled.div<{ isDragging: true | false }>`
	display: flex;
	flex-direction: column;
	border: 1px solid #bec6c4;
	border-radius: 16px;
	padding: ${({ isDragging }) => (isDragging ? '15px 0 59px' : '15px 0')};
	z-index: 800;
`;

const NoQuestion = styled.div`
	color: ${styleConstant.COLOR.GRAY_4};
	margin: 0 20px;
`;
