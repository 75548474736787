import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useAxios from "api/axiosCustom";
import { ConditionCard } from "components/card";
import { ConditionModal, LoadingModal, LocaleModal } from "components/modal";
import { IExtraQuestion } from "data/interface/interviewManage";

const Cover = styled.div``;

const Container = styled.div`
  display: flex;
  gap: 20px;
`;

export function SelectCondition() {
  const axios = useAxios();
  const [isListEditModalVisible, setIsListEditModalVisible] =
    useState<boolean>(false);
  const [selectTitle, setSelectTitle] = useState<string>("");
  const [selectIndexList, setSelectIndexList] = useState<string>("");
  const [selectId, setSelectId] = useState<string>("");
  const [categoryData, setCategoryData] = useState<IExtraQuestion[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLocaleModalVisible, setIsLocaleModalVisible] =
    useState<boolean>(false);

  const categoryTitle: string[] = ["프로필", "남성", "여성"];

  useEffect(() => {
    if (!isListEditModalVisible) {
      (async () => {
        setIsLoading(true);
        const res = await axios.get("/extraquestion");
        setCategoryData(res.data);
        setIsLoading(false);
      })();
    }
  }, [isListEditModalVisible]);

  const questionIndexFilter = (category: string) => {
    const filter = categoryData.filter((data) => data.category === category);
    if (filter.length > 0) {
      return filter[0].questionIds;
    } else {
      return "";
    }
  };

  const idFilter = (category: string) => {
    const filter = categoryData.filter((data) => data.category === category);
    if (filter.length > 0) {
      return String(filter[0].id);
    } else {
      return "";
    }
  };

  const handleCardClick = (
    cardTitle: string,
    cardIndexList: string,
    id: string,
  ) => {
    setSelectTitle(cardTitle);
    setSelectIndexList(cardIndexList);
    setSelectId(id);
    setIsListEditModalVisible(true);
  };

  const handlePreviewBtn = () => {
    setIsListEditModalVisible(false);
    setIsLocaleModalVisible(true);
  };

  const handleKorean = () => {
    localStorage.setItem("locale", "ko");
    window.open(
      `/extra_test?id=${selectId}`,
      "preview",
      "top=200, left=300, width=400, height=800",
    );
    setIsLocaleModalVisible(false);
  };

  const handleEnglishBtn = () => {
    localStorage.setItem("locale", "en");
    window.open(`/extra_test?id=${selectId}`, "test", "width=400, height=800");
    setIsLocaleModalVisible(false);
  };

  return (
    <Cover>
      <LoadingModal isModalVisible={isLoading} />
      <ConditionModal
        isVisible={isListEditModalVisible}
        setIsVisible={setIsListEditModalVisible}
        title={selectTitle}
        value={selectIndexList}
        handlePreviewBtn={handlePreviewBtn}
      />
      <LocaleModal
        isModalVisible={isLocaleModalVisible}
        setIsModalVisible={setIsLocaleModalVisible}
        onEnglishBtn={handleEnglishBtn}
        onKoreanBtn={handleKorean}
      />
      <Container>
        {categoryTitle.map((category, idx: number) => (
          <ConditionCard
            key={idx}
            title={category}
            questionIndex={questionIndexFilter(category)}
            id={idFilter(category)}
            onClick={handleCardClick}
          />
        ))}
      </Container>
    </Cover>
  );
}
