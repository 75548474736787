import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NumberInput } from "components/input";
import { HalfAskCard, AskCard } from "components/card";
import { PrimaryBtn } from "components/button";
import { ErrorAlert } from "components/alert";
import { useSelector, useDispatch } from "react-redux";
import { answerSubmit, inputUnitReset } from "store/reducer/testPreviewReducer";
import { RootState } from "store/reducer/rootReducer";

export default function InputNumBtn() {
  const questionIndex = useSelector(
    (state: RootState) => state.testPreview.index,
  );
  const question = useSelector(
    (state: RootState) => state.testPreview.question[questionIndex],
  );
  const questionLength = useSelector(
    (state: RootState) => state.testPreview.question.length,
  );
  const preAnswer = useSelector(
    (state: RootState) => state.testPreview.answered[questionIndex],
  );

  const dispatch = useDispatch();

  const [numValue, setNumValue] = useState<string | undefined>(undefined);
  const [checkAnswer, setCheckAnswer] = useState<string>("");
  const [checkEnAnswer, setCheckEnAnswer] = useState<string>("");
  const [alertToggle, setAlertToggle] = useState<boolean>(false);
  const locale = localStorage.getItem("locale");

  let answers: string[];
  let EnAnswers: string[];

  if (question.answerList) {
    answers = question.answerList.split(";").slice(0, -1);
    EnAnswers = question.EnQuestion.answerList.split(";").slice(0, -1);
  } else {
    answers = [];
    EnAnswers = [];
  }

  useEffect(() => {
    dispatch(inputUnitReset(questionIndex));
    window.scrollTo(0, 0);
    if (preAnswer === undefined) {
      setNumValue("");
      setCheckAnswer("");
      setCheckEnAnswer("");
    } else {
      if (preAnswer.answer === question.noneAnswerText) {
        setNumValue("");
        setCheckAnswer(question.noneAnswerText);
        setCheckEnAnswer(question.EnQuestion.noneAnswerText);
      } else {
        const splitAnswer = preAnswer.answer.split(" ");
        const splitEnAnswer = preAnswer.enAnswer.split(" ");
        setNumValue(splitAnswer[0]);
        setCheckAnswer(splitAnswer[1]);
        setCheckEnAnswer(splitEnAnswer[1]);
      }
    }
  }, [questionIndex]);

  function onNextBtn() {
    if (
      (!numValue && checkAnswer !== question.noneAnswerText) ||
      !checkAnswer
    ) {
      setAlertToggle(true);
      setTimeout(() => setAlertToggle(false), 2000);
    } else {
      if (checkAnswer === question.noneAnswerText) {
        dispatch(
          answerSubmit({
            id: question.id,
            answer: checkAnswer,
            enAnswer: checkEnAnswer,
          }),
        );
      } else {
        dispatch(
          answerSubmit({
            id: question.id,
            answer: `${numValue} ${checkAnswer}`,
            enAnswer: `${numValue} ${checkEnAnswer}`,
          }),
        );
      }
    }
  }

  function onInputNumChange({ target: { value } }: any) {
    if (value[0] !== "0") {
      const valueTest = value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1");
      setNumValue(valueTest);
    }
  }

  function onCheckCardBtn(e: React.MouseEvent<HTMLElement>, idx: number) {
    setCheckAnswer(answers[idx].trim());
    setCheckEnAnswer(EnAnswers[idx].trim());
  }

  function onNoneAnswerBtn() {
    setNumValue("");
    setCheckAnswer(question.noneAnswerText.trim());
    setCheckEnAnswer(question.EnQuestion.noneAnswerText.trim());
  }

  return (
    <Cover>
      <ErrorAlert toggle={alertToggle} label="답변을 입력해주세요." />
      <p className="mainText">
        {locale === "en"
          ? question.EnQuestion.mainQuestion
          : question.mainQuestion}
      </p>
      <p className="subText">
        {locale === "en"
          ? question.EnQuestion.subQuestion
          : question.subQuestion}
      </p>
      {question.imgSrc ? (
        <ImgContainer>
          <img
            src={question.imgSrc}
            alt="question"
            width="450x"
            height="200x"
          />
        </ImgContainer>
      ) : null}
      <NumberInput
        type="text"
        value={numValue}
        label={
          checkAnswer === question.noneAnswerText
            ? "reset"
            : checkAnswer === undefined
            ? locale === "en"
              ? question.EnQuestion.inputUnit
              : question.inputUnit
            : `${locale === "en" ? checkEnAnswer : checkAnswer} ${
                locale === "en"
                  ? question.EnQuestion.inputUnit
                  : question.inputUnit
              }`
        }
        inputUnit={
          locale === "en" ? question.EnQuestion.inputUnit : question.inputUnit
        }
        onChange={onInputNumChange}
      />
      <CardContainer>
        {question.isNoneAnswer ? (
          <AskCard
            item={
              locale === "en"
                ? question.EnQuestion.noneAnswerText
                : question.noneAnswerText
            }
            checked={checkAnswer === question.noneAnswerText}
            clicked={onNoneAnswerBtn}
          />
        ) : null}
        {locale === "en"
          ? EnAnswers.map((text, index) => (
              <HalfAskCard
                key={index}
                item={text}
                checked={checkEnAnswer === text.trim()}
                clicked={(event: any) => onCheckCardBtn(event, index)}
              />
            ))
          : answers.map((text, index) => (
              <HalfAskCard
                key={index}
                item={text}
                checked={checkAnswer === text.trim()}
                clicked={(event: any) => onCheckCardBtn(event, index)}
              />
            ))}
      </CardContainer>
      {questionLength - 1 === questionIndex ? (
        <PrimaryBtn
          label={locale === "ko" ? "제출" : "Submit"}
          onClick={onNextBtn}
        />
      ) : (
        <PrimaryBtn
          label={locale === "ko" ? "다음" : "Continue"}
          onClick={onNextBtn}
        />
      )}
    </Cover>
  );
}

const Cover = styled.div`
  font-weight: bold;
  margin-top: 24px;
  padding-bottom: 32px;
  .mainText {
    font-size: 24px;
    line-height: 133%;
    word-break: keep-all;
    margin: 0 0 0;
  }
  .subText {
    font-size: 16px;
    line-height: 150%;
    margin: 8px 0 24px;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  margin: 0 0 24px;
`;

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
  margin: 32px 0 50px;
`;
