import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import useAxios from 'api/axiosCustom';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { ErrorAlert } from 'components/alert';
import { PrimaryInput, SearchInput, BtnInput, SearchInputTextArea } from 'components/input';
import { PrimaryBtn } from 'components/button';
import { PrimaryDropDown } from 'components/dropDown';
import styleConstant from 'data/constant/styleConstant';

interface QuestionAddModalProps {
	isModalVisible: boolean;
	setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	value?: any;
	onCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
	listRequest: () => void;
}

interface IQuestionValue {
	bigCategory: string;
	smallCategory: string;
	mainText: string;
	s3Img: string;
	subText: string;
	answerType: string;
	answerCount: string;
	inputType: string;
	answerList: string;
	inputSelect: string;
	inputOpenBtnLabel: string;
	inputTypeQuestion: string;
	inputUnit: string;
	nanSelect: string;
	nanBtnLabel: string;
}

interface IIsInputValueCheck {
	all: boolean;
	bigCategory: boolean;
	smallCategory: boolean;
	mainText: boolean;
	subText: boolean;
	answerList: boolean;
	inputTypeQuestion: boolean;
	inputUnit: boolean;
	nonBtnLabel: boolean;
}

export function QuestionAddModal({
	isModalVisible,
	setIsModalVisible,
	value = {},
	onCancel,
	listRequest,
}: QuestionAddModalProps) {
	const axios = useAxios();
	const navigate = useNavigate();

	const checkEnKey: string[] = [
		'bigCategory',
		'smallCategory',
		'mainText',
		'subText',
		'answerList',
		'inputTypeQuestion',
		'inputUnit',
		'nanBtnLabel',
	];
	const [isCheck, setIsCheck] = useState<boolean>(false);
	const [questionValue, setQuestionValue] = useState<any>({
		bigCategory: '공통@@Common',
		smallCategory: '',
		mainText: '',
		s3Img: '',
		subText: '',
		answerType: '단수 선택',
		answerCount: '',
		inputType: 'button',
		answerList: '',
		activeList: '',
		inputSelect: '없음',
		inputOpenBtnLabel: '',
		inputTypeQuestion: '',
		inputUnit: '',
		nanSelect: '없음',
		nanBtnLabel: '',
	});

	const [isInputValueCheck, setIsInputValueCheck] = useState<any>({
		all: true,
		bigCategory: true,
		smallCategory: true,
		mainText: true,
		subText: true,
		answerList: true,
		activeList: true,
		inputTypeQuestion: true,
		inputUnit: true,
		nonBtnLabel: true,
	});

	const [img, setImg] = useState<any>('');

	const imgInputRef = useRef<any>(null);
	const inputType: { [key: string]: string } = {
		버튼: 'button',
		숫자: 'number',
		'이미지 첨부': 'image',
		문자: 'string',
	};
	const bigCategoryType: { [key: string]: string } = {
		공통: '공통@@Common',
		특수: '특수@@Specific',
		남성: '남성@@Male',
		여성: '여성@@Female',
		프로필: '프로필@@Profile',
	};

	useEffect(() => {
		if (value.id) {
			const valueIsInput: string = value.isInput ? '있음' : '없음';
			const valueIsNoneAnswer: string = value.isNoneAnswer ? '있음' : '없음';
			setQuestionValue({
				bigCategory:
					value.bigCategory !== null
						? `${value.bigCategory}@@${value.EnQuestion?.bigCategory}`
						: value.bigCategory,
				smallCategory:
					value.smallCategory !== null
						? `${value.smallCategory}@@${value.EnQuestion?.smallCategory}`
						: value.smallCategory,
				mainText:
					value.mainQuestion !== null
						? `${value.mainQuestion}@@${value.EnQuestion?.mainQuestion}`
						: value.mainQuestion,
				s3Img: value.imgSrc,
				subText:
					value.subQuestion !== null
						? `${value.subQuestion}@@${value.EnQuestion?.subQuestion}`
						: value.subQuestion,
				answerType: value.answerType,
				answerCount: value.answerCount,
				inputType: value.inputType,
				answerList:
					value.answerList !== null
						? `${value.answerList}@@${value.EnQuestion?.answerList}`
						: value.answerList,
				activeList:
					value.activeList !== null
						? `${value.activeList}@@${value.EnQuestion?.activeList}`
						: value.activeList,
				inputTypeQuestion:
					value.inputQuestion !== null
						? `${value.inputQuestion}@@${value.EnQuestion?.inputQuestion}`
						: value.inputQuestion,
				inputUnit:
					value.inputUnit !== null
						? `${value.inputUnit}@@${value.EnQuestion?.inputUnit}`
						: value.inputUnit,
				nanBtnLabel:
					value.noneAnswerText !== null
						? `${value.noneAnswerText}@@${value.EnQuestion?.noneAnswerText}`
						: value.noneAnswerText,
				inputOpenBtnLabel: '',
				inputSelect: valueIsInput,
				nanSelect: valueIsNoneAnswer,
			});
			setImg({ name: value.imgSrc });
		}
	}, [value]);

	useEffect(() => {
		if (!isModalVisible) {
			setQuestionValue({
				bigCategory: '공통@@Common',
				smallCategory: '',
				mainText: '',
				s3Img: '',
				subText: '',
				answerType: '단수 선택',
				answerCount: '',
				inputType: 'button',
				answerList: '',
				activeList: '',
				inputSelect: '없음',
				inputOpenBtnLabel: '',
				inputTypeQuestion: '',
				inputUnit: '',
				nanSelect: '없음',
				nanBtnLabel: '',
			});
			setImg('');
		}
	}, [isModalVisible]);

	useEffect(() => {
		if (isCheck) {
			setTimeout(() => {
				setIsCheck(false);
			}, 2000);
		}
	}, [isCheck]);

	// function onBigCategoryInput(e: any) {
	// 	setQuestionValue({ ...questionValue, bigCategory: e.target.value });
	// }

	// function onBigCategoryCancelBtn() {
	// 	setQuestionValue({ ...questionValue, bigCategory: '' });
	// }

	function onBigCategoryDropdownClick(label: string) {
		setQuestionValue({ ...questionValue, bigCategory: bigCategoryType[label] });
	}

	function onSmallCategoryInput(e: any) {
		setQuestionValue({ ...questionValue, smallCategory: e.target.value });
	}

	function onSmallCategoryCancelBtn() {
		setQuestionValue({ ...questionValue, smallCategory: '' });
	}

	function onMainTextInput(e: any) {
		setQuestionValue({ ...questionValue, mainText: e.target.value });
	}

	function onMainTextCancelBtn() {
		setQuestionValue({ ...questionValue, mainText: '' });
	}

	function onImgAddBtn(e: any) {
		e.preventDefault();
		imgInputRef.current.click();
	}

	async function onImgInput(e: any) {
		if (e.target.files[0]) {
			const formData = new FormData();
			formData.append('image', e.target.files[0]);
			const res = await axios.post('/image?type=questions', formData);
			setQuestionValue({ ...questionValue, s3Img: res.data.src });
			setImg(e.target.files[0]);
		}
	}

	function onSubTextInput(e: any) {
		setQuestionValue({ ...questionValue, subText: e.target.value });
	}

	function onSubTextCancelBtn() {
		setQuestionValue({ ...questionValue, subText: '' });
	}

	function onAnswerTypeBtn(label: string) {
		setQuestionValue({ ...questionValue, answerType: label });
	}

	function onAnswerCountInput(e: any) {
		setQuestionValue({ ...questionValue, answerCount: e.target.value });
	}

	function onInputTypeBtn(label: string) {
		setQuestionValue({ ...questionValue, inputType: inputType[label] });
	}

	function onAnswerListInput(e: any) {
		setQuestionValue({ ...questionValue, answerList: e.target.value });
	}

	function onAnswerListCancelBtn() {
		setQuestionValue({ ...questionValue, answerList: '' });
	}

	function onInputSelectBtn(label: string) {
		setQuestionValue({ ...questionValue, inputSelect: label });
	}

	function onInputOpenBtnLabelInput(e: any) {
		setQuestionValue({ ...questionValue, inputOpenBtnLabel: e.target.value });
	}

	function onInputOpenBtnLabelCancelBtn() {
		setQuestionValue({ ...questionValue, inputOpenBtnLabel: '' });
	}

	function onInputTypeQuestionInput(e: any) {
		setQuestionValue({ ...questionValue, inputTypeQuestion: e.target.value });
	}

	function onInputTypeQuestionCancelBtn() {
		setQuestionValue({ ...questionValue, inputTypeQuestion: '' });
	}

	function onInputUnitInput(e: any) {
		setQuestionValue({ ...questionValue, inputUnit: e.target.value });
	}

	function onActiveListInput(e: any) {
		setQuestionValue({ ...questionValue, activeList: e.target.value });
	}

	function onActiveListCancelBtn() {
		setQuestionValue({ ...questionValue, activeList: '' });
	}

	function onNanSelectBtn(label: string) {
		setQuestionValue({ ...questionValue, nanSelect: label });
	}

	function onNanBtnLabelInput(e: any) {
		setQuestionValue({ ...questionValue, nanBtnLabel: e.target.value });
	}

	function onNanBtnLabelCnacelBtn() {
		setQuestionValue({ ...questionValue, nanBtnLabel: '' });
	}

	function checkHaveEnWord() {
		let checkReslut: boolean = true;
		checkEnKey.forEach((key) => {
			if (questionValue.hasOwnProperty(key)) {
				if (questionValue[key] && questionValue[key].length > 0) {
					const regExp = /@@/gi;
					const answerListRegExp = /;@@/gi;
					if (key === 'answerList') {
						if (
							questionValue[key] &&
							!answerListRegExp.test(questionValue[key])
						) {
							setIsCheck(true);
							checkReslut = false;
						}
					} else {
						if (questionValue && !regExp.test(questionValue[key])) {
							setIsCheck(true);
							checkReslut = false;
						}
					}
				}
			}
		});
		return checkReslut;
	}
	function onPreview() {
		localStorage.setItem('state', JSON.stringify(questionValue));
		// navigate("/preview", { state: { ...questionValue } });
		window.open('/preview', 'preview', 'width=400, height=800');
	}
	async function onSaveBtn() {
		const enCheck = checkHaveEnWord();
		if (enCheck) {
			let submitAnswerList: string;
			if (
				questionValue.answerList &&
				questionValue.answerList[questionValue.answerList.length - 1] !== ';'
			) {
				submitAnswerList = questionValue.answerList + ';';
			} else {
				submitAnswerList = questionValue.answerList;
			}
			const res = await axios.post('/question', {
				bigCategory: questionValue.bigCategory,
				midCategory: '',
				smallCategory: questionValue.smallCategory,
				isImg: questionValue.s3Img.length !== 0,
				imgSrc: questionValue.s3Img,
				mainQuestion: questionValue.mainText,
				subQuestion: questionValue.subText,
				answerType: questionValue.answerType,
				answerList: submitAnswerList,
				activeList: questionValue.activeList,
				answerCount: questionValue.answerCount,
				inputType: questionValue.inputType,
				isInput: questionValue.inputSelect === '있음',
				inputUnit: questionValue.inputUnit,
				inputQuestion: questionValue.inputTypeQuestion,
				isNoneAnswer: questionValue.nanSelect === '있음',
				noneAnswerText: questionValue.nanBtnLabel,
			});
			if (res.status === 200) {
				setIsModalVisible(false);
				listRequest();
			} else {
				setIsCheck(true);
			}
		}
	}

	async function onModifyBtn() {
		if (value.id) {
			const enCheck = checkHaveEnWord();

			if (enCheck) {
				let submitAnswerList: string;
				if (
					questionValue.answerList &&
					questionValue.answerList[questionValue.answerList.length - 1] !== ';'
				) {
					submitAnswerList = questionValue.answerList + ';';
				} else {
					submitAnswerList = questionValue.answerList;
				}
				const res = await axios.put(`/question/${value.id}`, {
					bigCategory: questionValue.bigCategory,
					midCategory: '',
					smallCategory: questionValue.smallCategory,
					isImg: questionValue.s3Img.length !== 0,
					imgSrc: questionValue.s3Img,
					mainQuestion: questionValue.mainText,
					subQuestion: questionValue.subText,
					answerType: questionValue.answerType,
					answerList: submitAnswerList,
					activeList: questionValue.activeList,
					answerCount: questionValue.answerCount,
					inputType: questionValue.inputType,
					isInput: questionValue.inputSelect === '있음',
					inputUnit: questionValue.inputUnit,
					inputQuestion: questionValue.inputTypeQuestion,
					isNoneAnswer: questionValue.nanSelect === '있음',
					noneAnswerText: questionValue.nanBtnLabel,
				});
				if (res.status === 200) {
					setIsModalVisible(false);
					listRequest();
				} else {
					setIsCheck(true);
				}
			}
		}
	}

	return (
		<StyledModal
			visible={isModalVisible}
			maskClosable={false}
			centered={true}
			closable={false}
			footer={null}>
			<ErrorAlert toggle={isCheck} label='입력값을 확인해주세요' />
			<div className='flexContainer'>
				<PrimaryDropDown
					label='대분류'
					items={Object.keys(bigCategoryType)}
					selectItem={Object.keys(bigCategoryType).find(
						(key) => bigCategoryType[key] === questionValue.bigCategory
					)}
					itemClick={onBigCategoryDropdownClick}
				/>
				<SearchInput
					label='소분류(@@ 영어)'
					value={questionValue.smallCategory}
					onChange={onSmallCategoryInput}
					onCancel={onSmallCategoryCancelBtn}
				/>
			</div>
			<SearchInput
				label='질문 main text(@@ 영어)'
				value={questionValue.mainText}
				onChange={onMainTextInput}
				onCancel={onMainTextCancelBtn}
			/>
			<BtnInput
				label='관련 이미지'
				inputRef={imgInputRef}
				type='file'
				btnLabel='이미지 찾기'
				onClick={onImgAddBtn}
				value={img.name}
				onChange={onImgInput}
			/>
			<SearchInput
				label='질문 sub text(@@ 영어)'
				value={questionValue.subText}
				onChange={onSubTextInput}
				onCancel={onSubTextCancelBtn}
			/>
			<div className='flexContainer'>
				<PrimaryDropDown
					label='답변 종류'
					selectItem={questionValue.answerType}
					items={[
						'단수 선택',
						'복수 선택',
						'주관식',
						'업로드',
						'날짜 입력(단일)',
						'날짜 입력(기간)',
					]}
					itemClick={onAnswerTypeBtn}
				/>
				<PrimaryInput
					label='답변 수'
					value={questionValue.answerCount}
					onChange={onAnswerCountInput}
					innerLabel='개'
				/>
				{/* <PrimaryDropDown
					label='인풋 Type'
					selectItem={questionValue.inputType}
					items={['button', 'number', 'image', 'string']}
					itemClick={onInputTypeBtn}
				/> */}
				<PrimaryDropDown
					label='인풋 Type'
					selectItem={Object.keys(inputType).find(
						(key) => inputType[key] === questionValue.inputType
					)}
					items={Object.keys(inputType)}
					itemClick={onInputTypeBtn}
				/>
			</div>
			<SearchInputTextArea
				label='답변 리스트(@@ 영어)'
				value={questionValue.answerList}
				onChange={onAnswerListInput}
				onCancel={onAnswerListCancelBtn}
			/>
			<div className='flexContainer'>
				<PrimaryDropDown
					label='직접 입력 유/무'
					selectItem={questionValue.inputSelect}
					items={['있음', '없음']}
					itemClick={onInputSelectBtn}
					dangerText='없음'
				/>
				{/* <SearchInput
          label="직접 입력 버튼 값"
          value={questionValue.inputOpenBtnLabel}
          onChange={onInputOpenBtnLabelInput}
          onCancel={onInputOpenBtnLabelCancelBtn}
        /> */}
				<PrimaryInput
					label='직접 입력 Unit(@@ 영어)'
					value={questionValue.inputUnit}
					onChange={onInputUnitInput}
				/>
			</div>
			{/* <div className="flexContainer"> */}
			<SearchInput
				label='직접 입력 시 질문(@@ 영어)'
				value={questionValue.inputTypeQuestion}
				onChange={onInputTypeQuestionInput}
				onCancel={onInputTypeQuestionCancelBtn}
			/>
			<SearchInputTextArea
				label='직접 입력 활성화 답변(@@ 영어)'
				value={questionValue.activeList}
				onChange={onActiveListInput}
				onCancel={onActiveListCancelBtn}
			/>
			{/* </div> */}
			<div className='flexContainer'>
				<PrimaryDropDown
					label='해당 없음 응답 유/무'
					selectItem={questionValue.nanSelect}
					items={['있음', '없음']}
					itemClick={onNanSelectBtn}
					dangerText='없음'
				/>
				<SearchInput
					label='해당 없음 버튼 값(@@ 영어)'
					value={questionValue.nanBtnLabel}
					onChange={onNanBtnLabelInput}
					onCancel={onNanBtnLabelCnacelBtn}
				/>
			</div>
			<div className='flexContainer'>
				<PrimaryBtn label='취소' color='none' onClick={onCancel} />
				<PrimaryBtn label='미리보기' color='none' onClick={onPreview} />
				{value.id ? (
					<PrimaryBtn label='저장하기' onClick={onModifyBtn} />
				) : (
					<PrimaryBtn label='저장하기' onClick={onSaveBtn} />
				)}
			</div>
		</StyledModal>
	);
}

const StyledModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 20px;
		width: 676px;
	}
	.flexContainer {
		display: flex;
		gap: 16px;
	}
`;
