import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useAxios from 'api/axiosCustom';
import { PrimaryInput } from 'components/input';
import styleConstant from 'data/constant/styleConstant';
import { ReactComponent as SearchIcon } from 'assets/icon/interview/searchIcon.svg';
import { useDebounce } from 'hooks/useDebounce';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { IDepartment } from 'data/interface/interviewManage';
import { ItemBox } from './itemBox';
import { SearchResultBox } from './searchResultBox';

interface DepartmentAddComponentProps {
	value?: IDepartment;
	departmentList: IDepartment[];
	setDepartmentList: any;
	departmentIdList: string[];
	setDepartmentIdList: any;
	deleteDepartmentIdList: any;
	setDeleteDepartmentIdList: any;
}

export function DepartmentAddComponent({
	value,
	departmentList,
	setDepartmentList,
	departmentIdList,
	setDepartmentIdList,
	deleteDepartmentIdList,
	setDeleteDepartmentIdList,
}: DepartmentAddComponentProps) {
	const axios = useAxios();
	const [inputText, setInputText] = useState<string>('');
	const [searchData, setSearchData] = useState<any>([]);

	useEffect(() => {
		setInputText('');
	}, [value]);

	const debouncedInputText = useDebounce(inputText, 200);
	useEffect(() => {
		if (debouncedInputText) {
			DepartmentSearch(debouncedInputText);
		}
	}, [debouncedInputText]);

	function onMemberInfoChange(e: any) {
		setInputText(e.target.value);
	}

	const DepartmentSearch = async (debouncedInputText: any) => {
		const searchDepartmentyArr: any[] = [];
		let res;
		debouncedInputText === ' '
			? (res = await axios.get(`/department/findDepartmentId?&limit=9999`))
			: (res = await axios.get(
					`/department/findDepartmentId?search=${debouncedInputText}&limit=9999`
			  ));

		res?.data?.findDepartment?.map((Department: IDepartment) => {
			searchDepartmentyArr.push(Department);
		});

		const newDepartmentIdList = departmentList?.map((department) => department?.id);
		const filteredDepartmentList = searchDepartmentyArr.filter(
			(Department: any) => !newDepartmentIdList?.includes(Department.id)
		);
		setSearchData(filteredDepartmentList);
	};

	const handleAddDepartment = (Department: any) => {
		departmentIdList?.indexOf(Department?.id) === -1 &&
			departmentList?.push(Department) &&
			departmentIdList?.push(Department?.id.toString());
		setSearchData([]);
		setInputText('');
	};

	const handleDeleteDepartment = (deletedDepartment: any) => {
		const newDepartmentList = departmentList.filter(
			(department: any) => department?.id !== deletedDepartment?.id
		);
		setDepartmentList(newDepartmentList);
		const newDepartmentIdList = newDepartmentList.map((department) =>
			department.id.toString()
		);
		setDepartmentIdList(newDepartmentIdList);

		setDeleteDepartmentIdList((prev: any[]) => [...prev, deletedDepartment?.id]);
	};

	const handleEnd = (result: any) => {
		if (!result.destination) return;
		const newDepartmentList = departmentList;
		const [reorderedDepartment] = departmentList.splice(result.source.index, 1);
		newDepartmentList.splice(result.destination.index, 0, reorderedDepartment);
		setDepartmentList(newDepartmentList);
		setDepartmentIdList(
			newDepartmentList.map((Department: IDepartment) => Department.id.toString())
		);
	};

	return (
		<DepartmentComponent>
			<SearchContainer>
				<PrimaryInput
					label='진료과 검색'
					icon={<SearchIcon />}
					value={inputText}
					onChange={onMemberInfoChange}
					placeHolder=' 원하는 진료과를 입력해주세요.'
				/>
				{inputText !== '' &&
					(searchData.length > 0 ? (
						<ResultContainer>
							{searchData.map((department: any) => (
								<SearchResultBox
									item={department}
									itemName={department.name}
									handleAddItem={handleAddDepartment}
								/>
							))}
						</ResultContainer>
					) : (
						<ResultContainer>
							<Result>조회된 데이터가 없습니다.</Result>
						</ResultContainer>
					))}
			</SearchContainer>
			<DepartmentTitle>진료과 리스트</DepartmentTitle>
			<DragDropContext onDragEnd={handleEnd}>
				<Droppable droppableId='droppable'>
					{(provided, snapshot) => {
						return (
							<DepartmentContainer
								isDragging={snapshot.isDraggingOver}
								{...provided.droppableProps}
								ref={provided.innerRef}>
								{departmentList?.length ? (
									departmentList?.map(
										(department: any, index) => (
											<Draggable
												key={department?.id}
												draggableId={department?.id?.toString()}
												index={index}>
												{(provided, snapshot) => (
													<ItemBox
														key={
															department?.id
														}
														id={
															department?.id
														}
														title={
															department?.name
														}
														provided={
															provided
														}
														snapshot={
															snapshot
														}
														handleDeleteItem={
															handleDeleteDepartment
														}
														item={
															department
														}></ItemBox>
												)}
											</Draggable>
										)
									)
								) : (
									<NoDepartment>
										증상을 포함해주세요.
									</NoDepartment>
								)}
							</DepartmentContainer>
						);
					}}
				</Droppable>
			</DragDropContext>
		</DepartmentComponent>
	);
}

const DepartmentComponent = styled.div`
	border: 1px solid ${styleConstant.COLOR.GRAY_3};
	border-radius: 16px;
	padding: 10px 10px 20px;
	box-shadow: 0 8px 15px rgba(0, 0, 0, 0.08);
`;
const SearchContainer = styled.div`
	position: relative;
`;

const DepartmentTitle = styled.div`
	height: 20px;
	color: #919d9d;
	font-size: 12px;
	line-height: 20px;
	margin-bottom: 10px;
`;
const ResultContainer = styled.div`
	position: absolute;
	width: 100%;
	max-height: 350px;
	overflow: auto;
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	border: 1px solid #ecf0f0;
	border-radius: 16px;
	padding: 10px 0px;
	z-index: 1000;
	box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
		0 9px 28px 8px rgb(0 0 0 / 5%);
`;
const Result = styled.div`
	background-color: #ffffff;
	padding: 10px 16px;
	line-height: 24px;
	border-top: 1px solid ${styleConstant.COLOR.GRAY_1};
	border-bottom: 1px solid ${styleConstant.COLOR.GRAY_1};
	:hover {
		background: #f4fbfe;
	}
`;

const DepartmentContainer = styled.div<{ isDragging: true | false }>`
	display: flex;
	flex-direction: column;
	border: 1px solid #bec6c4;
	border-radius: 16px;
	padding: ${({ isDragging }) => (isDragging ? '15px 0 59px' : '15px 0')};
	z-index: 800;
`;

const NoDepartment = styled.div`
	color: ${styleConstant.COLOR.GRAY_4};
	margin: 0 20px;
`;
