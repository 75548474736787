import styleConstant from "data/constant/styleConstant";
import styled from "styled-components";
import { ReactComponent as MenuCloseIcon } from "assets/icon/preview/menuclose.svg";
import { ReactComponent as HeaderLogo } from "assets/icon/preview/headericon.svg";

export function PreviewHeader() {
  return (
    <Header>
      <div className="close" onClick={() => window.close()}>
        <MenuCloseIcon />
      </div>
      <div className="logo">
        <HeaderLogo />
      </div>
    </Header>
  );
}
const Header = styled.div`
  margin: auto;
  width: 375px;
  margin-top: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
  .close {
    margin-right: 16px;
    z-index: 3;
    cursor: pointer;
  }
  .logo {
    z-index: 1;
    margin-top: 2px;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
