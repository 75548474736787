import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducer/rootReducer";

const store = configureStore({
  reducer: rootReducer,
});

// export type AppStore = ReturnType<typeof store>;

export default store;
