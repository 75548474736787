import React, { useState, useRef } from "react";
import styled from "styled-components";

export interface NumberInputProps {
  value?: number | string;
  type?: string;
  label: string;
  inputUnit?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function NumberInput({
  value,
  label,
  type = "number",
  inputUnit = "",
  onChange,
}: NumberInputProps) {
  const [handleFocus, setHandleFocus] = useState<boolean>(false);
  const inputRef = useRef<any>(null);

  function onInputFocus() {
    setHandleFocus(!handleFocus);
  }

  function onCoverClick() {
    inputRef.current.focus();
  }

  return (
    <Cover focus={handleFocus} reset={label === "reset"} onClick={onCoverClick}>
      <NumInput
        type={type}
        ref={inputRef}
        value={value}
        onChange={onChange}
        onFocus={onInputFocus}
        onBlur={onInputFocus}
        disabled={label === "reset"}
        inputMode="numeric"
        pattern="[0-9]*"
      />
      <span>{label === "reset" ? inputUnit : label}</span>
    </Cover>
  );
}

const Cover = styled.div<{ focus: true | false; reset: true | false }>`
  width: 100%;
  height: 60px;
  padding: 12px 16px;
  border: solid 1px #cfcfcf;
  :hover {
    border: 1px solid #27b4eb;
  }
  :focus-within {
    border: solid 1px #1bbcff;
    box-shadow: 0 0 0 1px #1bbcff;
  }
  border-radius: 16px;
  margin: 0;
  background-color: ${({ reset }) => (reset ? "#e6e6e6" : "#fff")};
  span {
    color: #787878;
    font-size: 16px;
    font-weight: normal;
  }
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      padding: 8px 16px;
      line-height: 38px;
    }
    @supports (-webkit-overflow-scrolling: touch) {
      padding: 2px 16px;
      line-height: 54px;
    }
  }
`;

const NumInput = styled.input`
  width: 50%;
  height: 100%;
  border: none;
  font-size: 32px;
  font-weight: bold;
  :disabled {
    background-color: #e6e6e6;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      position: relative;
      top: -6px;
      padding: 2px 0 0;
      margin-left: 4px;
    }
    @supports (-webkit-overflow-scrolling: touch) {
      background-color: transparent;
      padding: 0;
      position: relative;
      top: -2px;
    }
  }
`;
