import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useAxios from 'api/axiosCustom';
import { Modal } from 'antd';
import { ErrorAlert } from 'components/alert';
import { PrimaryInput } from 'components/input';
import { PrimaryBtn } from 'components/button';
import { PrimaryDropDown } from 'components/dropDown';
import { ISymptom } from 'data/interface/interviewManage';
import { SymptomAddComponent } from './symptomAddComponent';

interface DepartmentAddModalProps {
	isModalVisible: boolean;
	setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	value?: any;
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
	listRequest: () => void;
}

export function DepartmentAddModal({
	isModalVisible,
	setIsModalVisible,
	value = {},
	onClick,
	listRequest,
}: DepartmentAddModalProps) {
	const axios = useAxios();
	const [isCheck, setIsCheck] = useState<boolean>(false);
	const [department, setDepartment] = useState<string>('');
	const [selectActive, setSelectActive] = useState<string>(
		value.isActive === 0 ? '비활성' : '활성'
	);
	const [isUpdate, setIsUpdate] = useState<boolean>(false);
	const [symptomList, setSymptomList] = useState<ISymptom[]>([]);
	const [symptomIdList, setSymptomIdList] = useState<any>([]);
	const [isSymptomIdCheck, setIsSymptomIdCheck] = useState<boolean>(false);

	useEffect(() => {
		if (value.id) {
			setDepartment(value.name + '@@' + value.enName);
			const listifySympptomId = value.symptomId.split(';');
			listifySympptomId.pop();
			setSymptomIdList(listifySympptomId);
			setIsUpdate(true);
			value.isActive === 0 ? setSelectActive('비활성') : setSelectActive('활성');

			(async () => {
				const selectedSymptomId = value?.symptomId
					?.split(';')
					?.filter((questionId: any) => questionId !== '');

				const res = await axios.get(`/department/findSymptomById?limit=9999`);

				const newSymptom = selectedSymptomId?.map((symptomId: string) => {
					return res.data?.symptoms?.filter(
						(symptom: ISymptom) => symptom?.id === Number(symptomId)
					)[0];
				});

				setSymptomIdList(selectedSymptomId);
				setSymptomList(newSymptom || []);
			})();
		} else {
			setDepartment('');
			setSymptomList([]);
			setSymptomIdList([]);
		}
	}, [value]);

	useEffect(() => {
		if (!isModalVisible) {
			setDepartment('');
			setSymptomList([]);
			setIsUpdate(false);
		}
	}, [isModalVisible]);

	const onInputChange = (e: any, key: string) => {
		switch (key) {
			case 'department':
				setDepartment(e.target.value);
				break;
			case 'symptomList':
				setSymptomList(e.target.value);
				break;
		}
	};

	function checkHaveEnWord() {
		let checkReslut: boolean = true;
		const regExpDe = /@@/gi;
		if (!regExpDe.test(department)) {
			setIsCheck(true);
			checkReslut = false;
		}
		return checkReslut;
	}

	async function onSaveBtn() {
		let enCheck = checkHaveEnWord();
		if (enCheck) {
			if (symptomIdList.length) {
				const res = await axios.post('/department/makeDepartment', {
					name: department,
					symptomId: symptomIdList.join(';') + ';',
					isActive: selectActive === '활성' ? 1 : 0,
					analyze: 0,
				});
				if (res.status === 200) {
					setIsModalVisible(false);
					listRequest();
				} else {
					setIsCheck(true);
				}
			} else {
				setIsSymptomIdCheck(true);
				setTimeout(() => {
					setIsSymptomIdCheck(false);
				}, 3000);
			}
		}
	}
	async function onModifyBtn() {
		if (value.id) {
			if (symptomIdList.length) {
				const res = await axios.put(`/department/putDepartment/${value.id}`, {
					name: department,
					symptomId: symptomIdList.join(';') + ';',
					isActive: selectActive === '활성' ? 1 : 0,
					analyze: value.analyze,
				});

				if (res.status === 200) {
					setIsModalVisible(false);
					listRequest();
				} else if (res.request.status) {
					setIsSymptomIdCheck(true);
					setTimeout(() => {
						setIsSymptomIdCheck(false);
					}, 3000);
				} else {
					setIsCheck(true);
				}
			} else {
				setIsSymptomIdCheck(true);
				setTimeout(() => {
					setIsSymptomIdCheck(false);
				}, 3000);
			}
		}
	}
	const onCancelBtn = (e: any) => {
		setDepartment('');
		setSymptomList([]);
		setSelectActive('활성');
		onClick(e);
	};

	const handleSelectActive = (e: any) => {
		setSelectActive(e);
	};

	return (
		<>
			<ErrorAlert toggle={isCheck} label='입력값을 확인해주세요' />
			<ErrorAlert toggle={isSymptomIdCheck} label='증상을 입력해 주세요' />
			<StyledModal
				visible={isModalVisible}
				maskClosable={true}
				centered={true}
				closable={false}
				width='676px'
				onCancel={() => setIsModalVisible(false)}
				footer={null}>
				<Content>
					<PrimaryInput
						label='진료과(@@ 영어)'
						value={department}
						onChange={(e) => {
							onInputChange(e, 'department');
						}}
						placeHolder={value.hospitalName}
					/>
					{/* <PrimaryInput
						label='관련 증상 리스트'
						value={symptomList}
						onChange={(e) => {
							onInputChange(e, 'symptomList');
						}}
						placeHolder={value.pathName}
					/> */}

					<SymptomAddComponent
						value={value}
						symptomList={symptomList}
						setSymptomList={setSymptomList}
						symptomIdList={symptomIdList}
						setSymptomIdList={setSymptomIdList}
					/>

					<PrimaryDropDown
						label='활성 여부'
						selectItem={selectActive}
						items={['활성', '비활성']}
						dangerText='비활성'
						itemClick={handleSelectActive}
					/>
					<BtnContainer>
						<PrimaryBtn label='취소' color='none' onClick={onCancelBtn} />
						{value.id ? (
							<PrimaryBtn label='수정하기' onClick={onModifyBtn} />
						) : (
							<PrimaryBtn label='저장하기' onClick={onSaveBtn} />
						)}
					</BtnContainer>
				</Content>
			</StyledModal>
		</>
	);
}

const StyledModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 20px;
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
`;

const BtnContainer = styled.div`
	display: flex;
	gap: 16px;
	margin: 14px 0 0;
`;
