import React from "react";
import styled from "styled-components";
import { Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styleConstant from "data/constant/styleConstant";

interface LoadingModalProps {
  isModalVisible: boolean;
}

export function LoadingModal({ isModalVisible }: LoadingModalProps) {
  return (
    <StyledModal
      visible={isModalVisible}
      maskClosable={false}
      centered={true}
      closable={false}
      footer={null}
      width="200px"
      zIndex={9999999999}
    >
      <Loading />
      <p>잠시만 기다려주세요</p>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
    text-align: center;
    z-index: 9999999999999999999;
  }
  p {
    font-size: ${styleConstant.FONT_SIZE.medium};
    font-weight: bold;
    margin: 27px 0 10px 0;
  }
`;

const Loading = styled(LoadingOutlined)`
  font-size: 65px;
  color: #1bbcff;
  margin-top: 25px;
`;
