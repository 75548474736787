import { PrimaryBtn } from 'components/button';
import styleConstant from 'data/constant/styleConstant';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as PictureIcon } from 'assets/icon/pictureIcon.svg';

interface StateProps {
	mainText: string;
	subText: string;
	answerType: string;
	s3Img: string;
	inputType: string;
}
export function Upload(state: StateProps) {
	const [locale, setLocale] = useState(0);
	const toggleLocale = () => {
		locale === 0 ? setLocale(1) : setLocale(0);
	};
	return (
		<>
			<Cover>
				<p className='mainText'>{state.mainText.split('@@')[locale]}</p>
				<p className='subText'>{state?.subText?.split('@@')[locale]}</p>
				<InputImgContainer>
					<input
						//   ref={imgInput}
						type='file'
						// accept="image/*;capture=camera"
						//   onChange={onImgChange}
					/>
					<PictureIcon />
					<p>{locale === 0 ? '사진 올리기' : 'Upload image'}</p>
				</InputImgContainer>
				<SelectContainer className={'noSelect'}>
					<p className='text'>{locale === 0 ? '첨부된 사진' : 'Attachment'}</p>
				</SelectContainer>
				<BtnContainer>
					<PrimaryBtn onClick={toggleLocale} label={'다른 언어로 변경하기'} />
				</BtnContainer>
			</Cover>
		</>
	);
}

const Cover = styled.div`
	width: 375px;
	margin: auto;
	margin-top: 24px;
	padding-bottom: 32px;
	.mainText {
		font-size: ${styleConstant.FONT_SIZE.large};
		font-weight: bold;
		margin: 0 0 24px;
		word-break: keep-all;
	}
`;

const InputImgContainer = styled.div`
	width: 100%;
	height: 128px;
	padding: 16px;
	border: solid 1px #cfcfcf;
	border-radius: 20px;
	text-align: center;
	cursor: pointer;
	input {
		display: none;
	}
	p {
		font-size: ${styleConstant.FONT_SIZE.medium};
		font-weight: bold;
		margin: 0px;
	}
`;

const SelectContainer = styled.div`
	width: 100%;
	background-color: #f8f8f8;
	border-radius: 16px;
	padding: 16px;
	margin-top: 24px;
	.text {
		font-size: ${styleConstant.FONT_SIZE.small};
		font-weight: normal;
		margin: 0;
	}
	p {
		width: 100%;
		margin: 8px 0 0;
		display: flex;
		justify-content: space-between;
		.imgName {
			width: 85%;
			font-size: ${styleConstant.FONT_SIZE.medium};
			font-weight: bold;
			letter-spacing: -0.2px;
			color: #222222;
			cursor: auto;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		span {
			cursor: pointer;
		}
	}
`;

const BtnContainer = styled.div`
	margin-top: 32px;
`;
