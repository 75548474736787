import React, { useState } from 'react';
import styled from 'styled-components';
import { HeaderBar } from 'components/header';
import { SelectSymptom, SelectQuestion, SelectCondition } from 'components/interviewManage';
import styleConstant from 'data/constant/styleConstant';
import menuConstant from 'data/constant/menuConstant';

export default function InterviewManagePage() {
	const [selectMenu, setSelectMenu] = useState<string>('문진 질문');
	const selectMenuComponent: { [key: string]: JSX.Element } = {
		// '증상 관리': <SelectSymptom />,
		'문진 질문': <SelectQuestion />,
		'조건 질문': <SelectCondition />,
	};

	function onMenuBtn(menu: string) {
		setSelectMenu(menu);
	}

	return (
		<Cover>
			<HeaderBar noBack={true} nowPath={menuConstant.INTERVIEW_MANAGE} />
			<p className='mainText'>문진 관리</p>
			<MenuContainer>
				{/* <p
					className={selectMenu === '증상 관리' ? 'select' : ''}
					onClick={() => onMenuBtn('증상 관리')}>
					증상 관리
				</p> */}
				<p
					className={selectMenu === '문진 질문' ? 'select' : ''}
					onClick={() => onMenuBtn('문진 질문')}>
					문진 질문
				</p>
				<p
					className={selectMenu === '조건 질문' ? 'select' : ''}
					onClick={() => onMenuBtn('조건 질문')}>
					조건 질문
				</p>
			</MenuContainer>
			{selectMenuComponent[selectMenu]}
		</Cover>
	);
}

const Cover = styled.div`
	width: 1024px;
	margin: 0 auto;
	.mainText {
		font-size: 40px;
		font-weight: bold;
		margin-top: 48px;
	}
`;

const MenuContainer = styled.div`
	display: flex;
	gap: 16px;
	font-size: ${styleConstant.FONT_SIZE.medium};
	p {
		cursor: pointer;
	}
	.select {
		font-weight: bold;
		padding-bottom: 4px;
		border-bottom: solid 2px #1bbcff;
	}
`;
