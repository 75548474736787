import styleConstant from 'data/constant/styleConstant';
import React from 'react';
import styled from 'styled-components';

interface Props {
	label: string;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Btn = styled.button`
	height: 40px;
	margin: 0;
	padding: 9.5px 16px;
	border: 1px solid #bec6c4;
	border-radius: 15px;
	line-height: 150%;
	letter-spacing: -0.3px;
	color: #3f4443;
	background-color: #fff;
	font-size: ${styleConstant.FONT_SIZE.small};
	font-weight: 500;
	white-space: nowrap;
	cursor: pointer;
`;

export function TestBtn({ label, onClick }: Props) {
	return <Btn onClick={onClick}>{label}</Btn>;
}
