import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as KdIcon } from 'assets/icon/kdIcon.svg';
import styleConstant from 'data/constant/styleConstant';
import axios from 'axios';

interface InterlocutorChatProps {
	name: string;
	msgText: string;
	msgDate: string;
}

export function InterlocutorChat({ name, msgDate, msgText }: InterlocutorChatProps) {
	const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
	const [lang, setLang] = useState<string>('en');
	const [text, setText] = useState<any>(msgText);
	const [detectedLang, setDetectedLang] = useState<string>('');
	const [isNormalText, setIsNormalText] = useState(true);

	async function translateText(text: any) {
		const apiUrl = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;

		setLang((prev) => (prev === 'en' ? 'ko' : 'en'));

		try {
			const response = await axios.post(apiUrl, {
				q: text,
				target: 'ko',
			});

			const newText = response.data.data.translations[0].translatedText;

			setText(lang === 'ko' ? msgText : newText);
		} catch (error) {
			console.error('Error during translation:', error);
			return null;
		}
	}

	useEffect(() => {
		const apiUrlDetect = `https://translation.googleapis.com/language/translate/v2/detect?key=${apiKey}`;

		(async () => {
			const responseDetect = await axios.post(apiUrlDetect, {
				q: msgText,
			});
			setDetectedLang(responseDetect.data.data.detections[0][0].language);
		})();
	}, [msgText]);

	useEffect(() => {
		const urlRegex = /(https?:\/\/|www\.|\.com|{"src":"http)/g;

		if (urlRegex.test(msgText)) {
			setIsNormalText(false);
		} else {
			setIsNormalText(true);
		}
	}, [msgText]);

	const findLink = () => {
		const words = msgText.split(' ');
		return (
			<>
				{words.map((word) =>
					word.includes('http') ? (
						<a
							style={{ textDecoration: 'underline' }}
							href={word}
							target='_blank'
							rel='noopener noreferrer'>
							{word}
						</a>
					) : word.includes('www') || word.includes('.com') ? (
						<a
							style={{ textDecoration: 'underline' }}
							href={`https://${word}`}
							target='_blank'
							rel='noopener noreferrer'>
							{word}
						</a>
					) : (
						<span>{word + ' '}</span>
					)
				)}
			</>
		);
	};

	const isFile = msgText.includes('{"src":"http');
	const findFile = () => {
		if (isFile) {
			const file = JSON.parse(msgText);
			const imgRegex = /image/;

			return (
				<>
					<div className='priviewItem'>
						{imgRegex.test(file?.type) && (
							<img
								src={file?.src}
								alt='uploaded'
								onClick={() => window.open(file?.src)}
							/>
						)}
						<span
							className='fileName'
							onClick={() => window.open(file?.src)}>
							{file?.name}
						</span>
					</div>
				</>
			);
		}
	};

	return (
		<Cover>
			<section>
				<div className='img'>
					<KdIcon />
				</div>
			</section>
			<section className='msgSection'>
				<p className='name'>{name}</p>
				<div className='msg'>
					{isNormalText ? text : !isFile ? findLink() : findFile()}

					{isNormalText && detectedLang !== 'ko' && (
						<div className='translate' onClick={() => translateText(text)}>
							번역하기
						</div>
					)}

					<p className='date'>{msgDate}</p>
				</div>
			</section>
		</Cover>
	);
}

const Cover = styled.div`
  display: flex;
  section {
    height: 100%;
    margin: 0 16px 20px 0;
    .img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      border: solid 1px #e6e6e6;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      cursor: pointer;
    }
    .name {
      font-size: ${styleConstant.FONT_SIZE.medium};
      font-weight: bold;
      margin-bottom: 8px;
      cursor: pointer;
    }
    .msg {
      border-radius: 0 20px 20px 20px;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
      padding: 16px 16px 12px 16px;
      background-color: #fff;
      white-space: pre-wrap;
      word-break: break-all;
      p {
        font-size: ${styleConstant.FONT_SIZE.small};
      }
      .date {
        font-size: ${styleConstant.FONT_SIZE.moreSmall};
        color: #969696;
        margin: 0;
      }
    }
		.translate {
		display: inline-block;
		font-weight: 500;
		font-size: ${styleConstant.FONT_SIZE.small};
		color: ${styleConstant.COLOR.CTA.DEFAULT};
		width: 65px;
		height: 23px;
		cursor: pointer;
		transform: translate(5px, 0px);
		//background-color: ${styleConstant.COLOR.WHITE_BG};
		//border-radius: 10px;
		//border: 1px solid ${styleConstant.COLOR.CTA.DEFAULT};
		text-align: center;
		line-height: 23px;
		:hover {
			filter: brightness(1.15);
		}
  }
  .msgSection {
    max-width: 75%;
  }

  .file {
		margin: 0 !important;
		padding: 0 !important;
		display: flex;
	}

	.priviewItem {
		display: flex;
		flex-direction: column;
		justify-content: right;
		align-items: right;
		width: 100%;
		margin: 0;
		padding: 0;

		img {
			height: auto;
			width: 200px;
			padding: 10px;
			margin: 0 0 5px;
			background-color: ${styleConstant.COLOR.GRAY_2};
			border-radius: 10px;
			display: flex;
		}
		.fileName {
			width: min-content;
			white-space: nowrap;
			font-size: 16px;
			margin: 0px;
			padding: 4px 13px;
			border-radius: 15px;
			background-color: ${styleConstant.COLOR.GRAY_2};
			font-weight: 500;
			display: flex;
			justify-content: right;
			align-items: right;
		}
`;
