import React, { useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import useAxios from "api/axiosCustom";
import { useDispatch, useSelector } from "react-redux";
import { questionAdd } from "store/reducer/testPreviewReducer";
import { TestPreviewHeader } from "components/header";
import Interview from "components/interview";
import { RootState } from "store/reducer/rootReducer";
import { goBack, allReset } from "store/reducer/testPreviewReducer";
import { PrimaryBtn } from "components/button";

const Cover = styled.div`
  padding: 24px 16px 34px;
  .titleText {
    margin: 0 0 32px;
    line-height: 32px;
    color: #222;
    font-size: 24px;
    font-weight: 700;
  }
`;

export function ExtraTestPage() {
  const location = useLocation();
  const extraId = location.search.split("=")[1];
  const axios = useAxios();
  const dispatch = useDispatch();

  const questionArr = useSelector(
    (state: RootState) => state.testPreview.question,
  );
  const questionIndex = useSelector(
    (state: RootState) => state.testPreview.index,
  );

  useEffect(() => {
    dispatch(allReset());
    (async () => {
      const res = await axios.get(`/extraquestion/${extraId}`);
      dispatch(questionAdd(res.data));
    })();
  }, []);

  const handleBackBtn = () => {
    dispatch(goBack());
  };

  const handleCloseBtn = () => {
    window.close();
  };

  return (
    <>
      <TestPreviewHeader
        // prettier-ignore
        progress={String((questionIndex / questionArr.length) * 100 + (1 / questionArr.length * 100))}
        noBack={questionIndex === 0}
        onBackClick={handleBackBtn}
      />
      <Cover>
        {questionArr.length &&
          (questionArr.length > questionIndex ? (
            <Interview />
          ) : (
            <>
              <p className="titleText">테스트를 완료했습니다.</p>
              <PrimaryBtn label="닫기" onClick={handleCloseBtn} />
            </>
          ))}
      </Cover>
    </>
  );
}
