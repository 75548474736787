import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useAxios from 'api/axiosCustom';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { ErrorAlert } from 'components/alert';
import { PrimaryInput } from 'components/input';
import { PrimaryBtn } from 'components/button';
import { PrimaryDropDown } from 'components/dropDown';
import styleConstant from 'data/constant/styleConstant';

interface SymptomAddModalProps {
	isModalVisible: boolean;
	setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	value?: any;
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
	listRequest: () => void;
}

export function SymptomAddModal({
	isModalVisible,
	setIsModalVisible,
	value = {},
	onClick,
	listRequest,
}: SymptomAddModalProps) {
	const axios = useAxios();
	const navigate = useNavigate();

	const [isCheck, setIsCheck] = useState<boolean>(false);
	const [isQuestionIdxCheck, setIsQuestionidxCheck] = useState<boolean>(false);
	const [department, setDepartment] = useState<string>('');
	const [symptom, setSymptom] = useState<string>('');
	const [connectQuestionList, setConnectQuestionList] = useState<string>('');
	const [selectActive, setSelectActive] = useState<string>('활성');
	const [selectGender, setSelectGender] = useState<string>('없음');
	const [isUpdate, setIsUpdate] = useState<boolean>(false);
	const [isActive, setIsActive] = useState<boolean>(true);

	useEffect(() => {
		if (value.id) {
			setDepartment(`${value.department}@@${value.EnSymptom?.department}`);
			setSymptom(`${value.name}@@${value.EnSymptom?.name}`);
			setConnectQuestionList(value.questionIds);
			setIsUpdate(true);
			setIsActive(value.isActive);

			switch (value.Departments[0]?.classifiCation) {
				case 'common':
					setHospital('아는의사');
					break;
				case 'twc':
					setHospital('TWC');
					break;
				case 'common,twc':
					setHospital('아는의사 & TWC');
					break;
				default:
					setHospital('아는의사');
			}

			value.isActive ? setSelectActive('활성') : setSelectActive('비활성');
			if (value.isMenQuestion) {
				if (value.isWomenQuestion) {
					setSelectGender('남성, 여성 모두');
				} else {
					setSelectGender('남성만');
				}
			} else if (value.isWomenQuestion) {
				setSelectGender('여성만');
			} else {
				setSelectGender('없음');
			}
		}
	}, [value]);

	useEffect(() => {
		if (!isModalVisible) {
			setDepartment('');
			setSymptom('');
			setConnectQuestionList('');
			setIsUpdate(false);
			setIsActive(true);
			setSelectGender('없음');
		}
	}, [isModalVisible]);

	function onDepartmentInput(e: any) {
		setDepartment(e.target.value);
	}

	function onSymptomtInput(e: any) {
		setSymptom(e.target.value);
	}

	function onConnectQuestionListInput(e: any) {
		setConnectQuestionList(e.target.value);
	}

	function onActiveDropdownClick(label: string) {
		setSelectActive(label);
		label === '활성' ? setIsActive(true) : setIsActive(false);
	}
	//임시 병원 선택 기능//
	const [hospital, setHospital] = useState('아는의사');
	const [hospitalKey, setHospitalKey] = useState('common');

	const handleHospitalDropdown = (e: any) => {
		setHospital(e);
		switch (e) {
			case '아는의사':
				setHospitalKey('common');
				break;
			case 'TWC':
				setHospitalKey('twc');
				break;
			case '아는의사 & TWC':
				setHospitalKey('common,twc');
				break;
		}
	};

	function onGenderDropdownClick(label: string) {
		setSelectGender(label);
	}

	function checkHaveEnWord() {
		let checkReslut: boolean = true;
		const regExpDe = /;@@/gi;
		const regExpSy = /@@/gi;
		if (!regExpDe.test(department)) {
			setIsCheck(true);
			checkReslut = false;
		}
		if (!regExpSy.test(symptom)) {
			setIsCheck(true);
			checkReslut = false;
		}
		return checkReslut;
	}

	async function onSaveBtn() {
		let submitConnectQuestionList: string;
		let submitDepartment: string;
		if (connectQuestionList[connectQuestionList.length - 1] !== ';') {
			submitConnectQuestionList = connectQuestionList + ';';
		} else {
			submitConnectQuestionList = connectQuestionList;
		}
		if (department[department.length - 1] !== ';') {
			submitDepartment = department + ';';
		} else {
			submitDepartment = department;
		}
		const enCheck = checkHaveEnWord();
		if (enCheck) {
			const res = await axios.post('/symptom', {
				department: submitDepartment,
				name: symptom,
				questionIds: submitConnectQuestionList,
				isActive,
				isMenQuestion: selectGender.includes('남성'),
				isWomenQuestion: selectGender.includes('여성'),
				classifiCation: hospitalKey,
			});
			if (res.status === 200) {
				setIsModalVisible(false);
				listRequest();
			} else {
				setIsCheck(true);
			}
		}
	}

	async function onModifyBtn() {
		if (value.id) {
			let submitConnectQuestionList: string;
			let submitDepartment: string;
			if (connectQuestionList[connectQuestionList.length - 1] !== ';') {
				submitConnectQuestionList = connectQuestionList + ';';
			} else {
				submitConnectQuestionList = connectQuestionList;
			}

			if (department[department.length - 1] !== ';') {
				submitDepartment = department + ';';
			} else {
				submitDepartment = department;
			}
			const enCheck = checkHaveEnWord();
			if (enCheck) {
				const res = await axios.put(`/symptom/${value.id}`, {
					department: submitDepartment,
					name: symptom,
					questionIds: submitConnectQuestionList,
					isActive,
					isMenQuestion: selectGender.includes('남성'),
					isWomenQuestion: selectGender.includes('여성'),
					classifiCation: hospitalKey,
				});

				if (res.status === 200) {
					setIsModalVisible(false);
					listRequest();
				} else if (res.request.status) {
					setIsQuestionidxCheck(true);
					setTimeout(() => {
						setIsQuestionidxCheck(false);
					}, 3000);
				} else {
					setIsCheck(true);
					setTimeout(() => {
						setIsCheck(false);
					}, 3000);
				}
			}
		}
	}

	return (
		<>
			<ErrorAlert toggle={isCheck} label='입력값을 확인해주세요' />
			<ErrorAlert toggle={isQuestionIdxCheck} label='질문 idx를 확인해 주세요' />
			<StyledModal
				visible={isModalVisible}
				maskClosable={true}
				centered={true}
				closable={false}
				width='676px'
				onCancel={() => setIsModalVisible(false)}
				footer={null}>
				<Content>
					<PrimaryInput
						label='증상(@@ 영어)'
						value={department}
						close={isUpdate}
						onChange={onDepartmentInput}
					/>
					<PrimaryInput
						label='증상(@@ 영어)'
						value={symptom}
						onChange={onSymptomtInput}
					/>
					<PrimaryInput
						label='관련 질문 리스트'
						value={connectQuestionList}
						onChange={onConnectQuestionListInput}
					/>
					<PrimaryDropDown
						label='병원 구분(임시)'
						selectItem={hospital}
						items={['아는의사', 'TWC', '아는의사 & TWC']}
						itemClick={handleHospitalDropdown}
						dangerText='비활성'
					/>
					<DropdownContainer>
						<PrimaryDropDown
							label='활성 여부'
							selectItem={selectActive}
							items={['활성', '비활성']}
							itemClick={onActiveDropdownClick}
							dangerText='비활성'
						/>
						<PrimaryDropDown
							label='성별 특수 질문'
							selectItem={selectGender}
							items={['없음', '남성만', '여성만', '남성, 여성 모두']}
							itemClick={onGenderDropdownClick}
							dangerText='없음'
						/>
					</DropdownContainer>
					<BtnContainer>
						<PrimaryBtn label='취소' color='none' onClick={onClick} />
						{value.id ? (
							<PrimaryBtn label='저장하기' onClick={onModifyBtn} />
						) : (
							<PrimaryBtn label='저장하기' onClick={onSaveBtn} />
						)}
					</BtnContainer>
				</Content>
			</StyledModal>
		</>
	);
}

const StyledModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 20px;
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
`;

const DropdownContainer = styled.div`
	display: flex;
	gap: 16px;
`;

const BtnContainer = styled.div`
	display: flex;
	gap: 16px;
	margin: 14px 0 0;
`;
