import { useSelector } from 'react-redux';
import InputNum from './inputNum';
import InputNumBtn from './inputNumBtn';
import InputText from './inputText';
import Plurar from './plurar';
import Singular from './singular';
import Upload from './upload';
import { RootState } from '../../store/reducer/rootReducer';

export default function Index() {
	const questionList = useSelector((state: RootState) => state.testPreview.question);
	const index = useSelector((state: RootState) => state.testPreview.index);

	switch (questionList[index].answerType) {
		case '단수 선택':
			if (questionList[index].inputType === 'button') {
				return <Singular />;
			} else if (questionList[index].inputType === 'number') {
				return <InputNumBtn />;
			}
			return <div>err</div>;
		case '복수 선택':
			return <Plurar />;
		case '주관식':
			if (questionList[index].inputType === 'number') {
				return <InputNum />;
			} else {
				return <InputText />;
			}
		case '업로드':
			return <Upload />;
		default:
			return <div>99</div>;
	}
}
