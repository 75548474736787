import React from "react";
import styled from "styled-components";
import { ReactComponent as HeadIcon } from "assets/icon/preview/headericon.svg";
import { ReactComponent as Back } from "assets/icon/backIcon.svg";

interface Props {
  progress: string;
  noBack: boolean;
  onBackClick: React.MouseEventHandler<SVGSVGElement>;
}

const Header = styled.div<{ progress: string }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 68px;
  padding: 12px 16px;
  svg {
    cursor: pointer;
  }
  .progressBack {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 8;
    width: 100%;
    height: 4px;
    background-color: #f8f8f8;
  }
  .progress {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9;
    width: ${({ progress }) => `${progress}%`};
    height: 4px;
    background-color: #1bbcff;
  }
`;

export function TestPreviewHeader({ progress, noBack, onBackClick }: Props) {
  return (
    <Header progress={progress}>
      {noBack ? (
        <div style={{ width: "38px" }} />
      ) : (
        <Back onClick={onBackClick} />
      )}
      <HeadIcon />
      <div />
      <div className="progress" />
      <div className="progressBack" />
    </Header>
  );
}
