import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useAxios from 'api/axiosCustom';
import { Table, Pagination } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { HeaderBar } from 'components/header';
import { LoadingModal } from 'components/modal';
import { PrimaryInput } from 'components/input';
import { PrimaryDropDown } from 'components/dropDown';
import { PrimaryBtn } from 'components/button';
import styleConstant from 'data/constant/styleConstant';
import menuConstant from 'data/constant/menuConstant';
import { IUser } from 'data/interface/member';

export default function MemberInquiryPage() {
	const navigate = useNavigate();
	const axios = useAxios();
	const [selectMenu, setSelectMenu] = useState<string>('');

	const [searchPhoneNum, setSearchPhoneNum] = useState<string>('');
	const [searchMemberType, setSearchMemberType] = useState<string>('전체 회원');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [columnData, setColumnData] = useState<IUser[]>([]);
	const [totalCount, setTotalCount] = useState<number>(1);
	const [page, setPage] = useState<number>(0);
	const [searchToggle, setSearchToggle] = useState<boolean>(false);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const res = await axios.get('/admin/user');
			setTotalCount(res.data.totalCount);
			setColumnData(res.data.users);
			setIsLoading(false);
		})();
	}, []);

	useEffect(() => {
		if (searchToggle) {
			(async () => {
				setIsLoading(true);
				const res = await axios.get(
					`/admin/user?offset=${page}&userType=${searchMemberType}&search=${searchPhoneNum}`
				);
				setColumnData(res.data.users);
				setIsLoading(false);
			})();
		} else {
			(async () => {
				setIsLoading(true);
				const res = await axios.get(
					`/admin/user?offset=${page}&userType=${selectMenu}`
				);
				setColumnData(res.data.users);
				setIsLoading(false);
			})();
		}
	}, [page]);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const res = await axios.get(`/admin/user?userType=${selectMenu}`);
			setTotalCount(res.data.totalCount);
			setColumnData(res.data.users);
			setIsLoading(false);
		})();
	}, [selectMenu]);

	function test(value: any) {
		if (value.isAdmin) {
			return '관리자';
		} else if (value.isDoctor) {
			return '파트너회원';
		} else {
			return '일반회원';
		}
	}

	const columns = [
		// {
		//   title: "이메일주소",
		//   dataIndex: "email",
		//   key: "email",
		//   render: (text: any, _: any, index: any) => (
		//     <p className={index === 0 || index % 2 === 0 ? "text color" : "text"}>
		//       {text}
		//     </p>
		//   ),
		// },
		{
			title: '휴대폰 번호',
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
			render: (text: any, _: any, index: any) => (
				<p className={index === 0 || index % 2 === 0 ? 'text color' : 'text'}>
					{text}
				</p>
			),
		},
		{
			title: '회원 종류',
			key: 'memberType',
			render: (text: any, value: any, index: any) => (
				<p className={index === 0 || index % 2 === 0 ? 'text color' : 'text'}>
					{test(value)}
				</p>
			),
		},
		{
			key: 'delete',
			render: (_: any, value: any, index: number) => (
				<p
					className={
						index === 0 || index % 2 === 0
							? 'delete text color'
							: 'delete text'
					}
					onClick={() => {
						navigate(`/member/${value.id}`, { state: value });
					}}>
					<RightOutlined />
				</p>
			),
		},
	];

	function onMenuBtn(userType: string) {
		setSelectMenu(userType);
	}

	function onSearchPhoneNumInput(e: any) {
		setSearchPhoneNum(e.target.value);
	}

	function onSearchMemberTypeBtn(label: any) {
		if (label === '전체 회원') {
			setSearchMemberType('');
		} else if (label === '일반회원') {
			setSearchMemberType('common');
		} else {
			setSearchMemberType('partner');
		}
	}

	function searchDropText() {
		if (searchMemberType === '') {
			return '전체 회원';
		} else if (searchMemberType === 'common') {
			return '일반회원';
		} else {
			return '파트너회원';
		}
	}

	function onPageChange(e: any) {
		setPage(10 * (e - 1));
	}

	async function onSearchBtn() {
		setIsLoading(true);
		const res = await axios.get(
			`/admin/user?userType=${searchMemberType}&search=${searchPhoneNum}`
		);
		setTotalCount(res.data.totalCount);
		setColumnData(res.data.users);
		setSearchToggle(true);
		setIsLoading(false);
	}

	return (
		<Cover>
			<LoadingModal isModalVisible={isLoading} />
			<HeaderBar noBack={true} nowPath={menuConstant.MEMBER_INQUIRY} />
			<p className='mainText'>회원 조회</p>
			<MenuContainer>
				<p
					className={selectMenu === '' ? 'select' : ''}
					onClick={() => onMenuBtn('')}>
					전체 회원
				</p>
				<p
					className={selectMenu === 'common' ? 'select' : ''}
					onClick={() => onMenuBtn('common')}>
					일반회원
				</p>
				<p
					className={selectMenu === 'waiting' ? 'select' : ''}
					onClick={() => onMenuBtn('waiting')}>
					파트너회원(대기 중)
				</p>
				<p
					className={selectMenu === 'partner' ? 'select' : ''}
					onClick={() => onMenuBtn('partner')}>
					파트너회원(승인)
				</p>
				<p
					className={selectMenu === 'manager' ? 'select' : ''}
					onClick={() => onMenuBtn('manager')}>
					관리자
				</p>
			</MenuContainer>

			<SearchContainer>
				<p className='searchMainText'>검색하실 정보를 입력해주세요.</p>
				<InputContainer>
					<div className='input'>
						<PrimaryInput
							label='휴대폰 번호'
							type='number'
							value={searchPhoneNum}
							onChange={onSearchPhoneNumInput}
						/>
					</div>
					<div className='input'>
						<PrimaryDropDown
							label='회원 종류'
							items={['전체 회원', '일반회원', '파트너회원']}
							selectItem={searchDropText()}
							itemClick={onSearchMemberTypeBtn}
						/>
					</div>
					<div className='btn'>
						<PrimaryBtn label='검색' onClick={onSearchBtn} />
					</div>
				</InputContainer>
			</SearchContainer>
			<CustomTable
				columns={columns}
				dataSource={columnData}
				pagination={{ total: totalCount, onChange: onPageChange }}
			/>
		</Cover>
	);
}

const Cover = styled.div`
	width: 1024px;
	margin: 0 auto;
	.mainText {
		font-size: 40px;
		font-weight: bold;
		margin-top: 48px;
	}
	.delete {
		cursor: pointer;
	}
	.text {
		/* text-align: center; */
		padding-left: 17px;
		line-height: 56px;
		height: 56px;
		margin: 0;
	}
	.color {
		background-color: #f8f8f8;
	}
`;

const MenuContainer = styled.div`
	display: flex;
	gap: 16px;
	font-size: ${styleConstant.FONT_SIZE.medium};
	margin-bottom: 48px;
	p {
		cursor: pointer;
	}
	.select {
		font-weight: bold;
		padding-bottom: 4px;
		border-bottom: solid 2px #1bbcff;
	}
`;

const SearchContainer = styled.div`
	width: 100%;
	height: 188px;
	box-sizing: border-box;
	padding: 24px 24px 32px;
	margin-bottom: 48px;
	border-radius: 20px;
	box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.08);
	.searchMainText {
		font-size: ${styleConstant.FONT_SIZE.large};
		font-weight: bold;
		margin: 0;
	}
`;

const InputContainer = styled.div`
	display: flex;
	gap: 16px;
	margin-top: 12px;
	align-items: center;
	.input {
		width: 40%;
		margin: 0;
		padding: 0;
	}
	.btn {
		width: 120px;
		margin: 0;
		padding-top: 24px;
	}
`;

const CustomTable = styled(Table)`
	.ant-table-thead {
		th {
			padding: 8px 16px;
			border-top: 1px solid #f0f0f0;
			background-color: #fff;
			&::before {
				display: none;
			}
		}
	}
	.ant-table-tbody {
		td {
			padding: 0;
		}
	}
`;
