import React from 'react';
import styled from 'styled-components';
import styleConstant from 'data/constant/styleConstant';

interface SymptomInfoProps {
	department: string;
	symptom?: string;
	additionalSymptom?: string;
}

export function SymptomInfo({ department, symptom, additionalSymptom }: SymptomInfoProps) {
	const splitDepartment = department?.split(';');

	return (
		<Cover>
			<Header>
				<div>증상</div>
			</Header>
			<InfoContainer>
				<p>어디가 불편하신가요?</p>
				<p className='info'>{splitDepartment?.map((element) => `${element} `)}</p>
				<p>어떤 증상이 있으신가요?</p>
				<p className='info last'>{symptom}</p>
				{/* <p>어떤 증상인지 상세히 알려주세요</p>
        <p className="info last">{additionalSymptom}</p> */}
			</InfoContainer>
		</Cover>
	);
}

const Cover = styled.div`
	margin: 0;
	width: 328px;
`;

const Header = styled.section`
	display: flex;
	justify-content: space-between;
	font-size: ${styleConstant.FONT_SIZE.small};
	font-weight: bold;
	margin-top: 15px;
`;

const InfoContainer = styled.div`
	width: 100%;
	background-color: #f8f8f8;
	padding: 16px;
	margin-top: 8px;
	border-radius: 20px;
	font-size: ${styleConstant.FONT_SIZE.small};
	p {
		margin: 0 0 8px 0;
	}
	.info {
		font-size: ${styleConstant.FONT_SIZE.medium};
		font-weight: bold;
		margin: 0 0 24px 0;
	}
	.last {
		margin: 0;
	}
`;
