import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useAxios from 'api/axiosCustom';
import { useDispatch } from 'react-redux';
import { addToken } from 'store/reducer/loginReducer';
import { HeaderBar } from 'components/header';
import { ErrorAlert } from 'components/alert';
import { LoginBtn, PrimaryBtn } from 'components/button';
import GoogleLogin from 'react-google-login';
import KakaoLogin from 'react-kakao-login';
import styleConstant from 'data/constant/styleConstant';
import loginTypeConstant from 'data/constant/loginTypeConstant';
import routerConstant from 'data/constant/routerConstant';

export default function LoginPage() {
	const navigate = useNavigate();
	const axios = useAxios();
	const dispatch = useDispatch();

	const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);

	function loginFail() {
		setIsAlertVisible(true);
		setTimeout(() => setIsAlertVisible(false), 2000);
	}

	// if (typeof window !== "undefined") {
	//   window.gotoLoginAccess = async (naverLogin: any) => {
	//     await axios
	//       .post("/admin/login/naver", {
	//         naverId: naverLogin.user.id,
	//         loginType: loginTypeConstant.NAVER,
	//       })
	//       .then((res) => {
	//         if (res.status === 200) {
	//           dispatch(
	//             addToken({
	//               accessToken: res.data.accessToken,
	//               refreshToken: res.data.refreshToken,
	//             }),
	//           );
	//           navigate(routerConstant.INTERVIEW_MANAGE);
	//         } else {
	//           loginFail();
	//         }
	//       })
	//       .catch(loginFail);
	//   };
	// }
	// let timeout: any;
	// function naverLogin(type: string) {
	//   timeout = setTimeout(() => {
	//     const login = new window.naver.LoginWithNaverId({
	//       clientId: process.env.REACT_APP_NAVER_LOGIN_KEY,
	//       callbackUrl: "https://admin.knowingdoctor.com/callback/naverLogin",
	//       isPopup: true,
	//       loginButton: { color: "green", type: 1, height: 25 },
	//       callbackHandle: true,
	//     });
	//     if (type === "init") {
	//       login.init();
	//     } else if (type === "logout") {
	//       login.logout();
	//     }
	//   }, 100);
	// }

	// useEffect(() => {
	//   if (typeof window !== "undefined") {
	//     naverLogin("init");
	//   }
	//   return () => {
	//     clearTimeout(timeout);
	//   };
	// }, []);

	// function onLoginNaver() {
	//   if (
	//     document &&
	//     document?.querySelector("#naverIdLogin")?.firstChild &&
	//     typeof window !== "undefined"
	//   ) {
	//     const loginBtn: any = document.getElementById("naverIdLogin")?.firstChild;
	//     loginBtn.click();
	//   }
	// }

	async function successKakaoLogin(data: any) {
		await axios
			.post('/admin/login/kakao', {
				kakaoId: data.profile.id.toString(),
				loginType: loginTypeConstant.KAKAO,
			})
			.then((res) => {
				if (res.status === 200) {
					dispatch(
						addToken({
							accessToken: res.data.accessToken,
							refreshToken: res.data.refreshToken,
						})
					);
					navigate(routerConstant.INTERVIEW_MANAGE);
				} else {
					loginFail();
				}
			})
			.catch(loginFail);
	}

	async function successGoogleLogin(data: any) {
		await axios
			.post('/admin/login/google', {
				googleId: data.googleId,
				loginType: loginTypeConstant.GOOGLE,
			})
			.then((res) => {
				if (res.status === 200) {
					dispatch(
						addToken({
							accessToken: res.data.accessToken,
							refreshToken: res.data.refreshToken,
						})
					);
					navigate(routerConstant.INTERVIEW_MANAGE);
				} else {
					loginFail();
				}
			})
			.catch(loginFail);
	}

	function onPhoneNumLoginBtn() {
		navigate(routerConstant.PHONE_LOGIN);
	}

	return (
		<Cover>
			<HeaderBar loginPage={true} noBack={true} />
			<ErrorAlert label='권한이 없는 사용자입니다.' toggle={isAlertVisible} />
			<p className='subText'>관리자를 위한 서비스입니다.</p>
			<p className='mainText'>로그인을 해주세요.</p>
			<SocialLoginContainer>
				<p className='loginText'>간편하게 로그인, 회원가입하기</p>
				<KakaoLogin
					token={String(process.env.REACT_APP_KAKAO_LOGIN_KEY)}
					onSuccess={successKakaoLogin}
					onFail={console.error}
					onLogout={console.info}
					render={({ onClick }) => (
						<LoginBtn
							label='카카오톡으로 하기'
							platform='kakao'
							onClick={onClick}
						/>
					)}
				/>
				{/* <div
          id="naverIdLogin"
          style={{ position: "absolute", top: "-10000000px" }}
        />
        <LoginBtn
          label="네이버로 하기"
          platform="naver"
          onClick={onLoginNaver}
        /> */}
				<GoogleLogin
					clientId={String(process.env.REACT_APP_GOOGLE_LOGIN_KEY)}
					render={(props) => (
						<LoginBtn
							label='구글로 하기'
							platform='google'
							onClick={props.onClick}
						/>
					)}
					onSuccess={successGoogleLogin}
					onFailure={console.error}
				/>
			</SocialLoginContainer>
			<PrimaryBtn
				label='휴대폰 번호로 로그인하기'
				color='none'
				onClick={onPhoneNumLoginBtn}
			/>
		</Cover>
	);
}

const Cover = styled.div`
	width: ${styleConstant.DESKTOP_BODY_WIDTH};
	margin: ${styleConstant.DESKTOP_BODY_MARGIN};
	text-align: start;
	.mainText {
		margin: 0;
		font-weight: bold;
		font-size: 40px;
		line-height: 48px;
	}
	.subText {
		margin: 64px 0 10px 0;
		font-size: 24px;
		line-height: 32px;
	}
`;

const SocialLoginContainer = styled.div`
	margin: 50px 0 40px 0;
	.loginText {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.2px;
	}
`;
