import React from 'react';
import styled from 'styled-components';
import styleConstant from 'data/constant/styleConstant';

export interface ErrorAlertProps {
	label: string;
	toggle: boolean;
	color?: string;
}

export function ErrorAlert({ label, toggle, color = 'orange' }: ErrorAlertProps) {
	return (
		<Cover toggle={toggle}>
			<AlertContainer color={color}>
				<div className='icon'>!</div>
				<div>{label}</div>
			</AlertContainer>
		</Cover>
	);
}

const Cover = styled.div<{ toggle: true | false }>`
	box-sizing: border-box;
	display: ${({ toggle }) => (toggle ? 'block' : 'none')};
	width: 100%;
	position: relative;
`;

const AlertContainer = styled.div<{ color: string }>`
	width: -moz-calc(100% - 32px);
	width: -webkit-calc(100% - 32px);
	max-width: 1024px;
	height: 56px;
	padding: 18px 16px;
	position: fixed;
	z-index: 99999;
	/* left: 16px; */
	top: 26px;
	display: flex;
	border-radius: 16px;
	background-color: #222222;
	color: #fff;
	font-family: 'Noto Sans KR', sans-serif;
	align-items: center;
	transition: 1s;

	div {
		font-size: ${styleConstant.FONT_SIZE.medium};
	}
	.icon {
		height: 20px;
		width: 20px;
		border-radius: 10px;
		background-color: ${({ color }) => (color === 'orange' ? '#ffbf3f' : '#27b4eb')};
		margin-right: 12px;
		text-align: center;
		line-height: 18px;
	}
`;
