import styleConstant from "data/constant/styleConstant";
import React from "react";
import styled from "styled-components";

interface Props {
  title: string;
  questionIndex: string;
  id: string;
  onClick: (title: string, index: string, id: string) => void;
}

const Card = styled.div`
  width: 328px;
  height: 152px;
  margin: 44px 0 0;
  padding: 24px;
  border: 1px solid #bec6c4;
  border-radius: 16px;
  cursor: pointer;
  p {
    margin: 0;
    line-height: 20px;
    color: #3f4443;
    font-size: ${styleConstant.FONT_SIZE.small};
    font-weight: 500;
  }
  .title {
    margin: 0 0 24px;
    text-align: center;
    line-height: 32px;
    color: #25282a;
    font-size: ${styleConstant.FONT_SIZE.large};
    font-weight: 700;
  }
  .subTitle {
    margin: 0 0 8px;
    line-height: 20px;
    color: #505759;
    font-weight: 400;
  }
`;

export function ConditionCard({ title, id, questionIndex, onClick }: Props) {
  return (
    <Card onClick={() => onClick(title, questionIndex, id)}>
      <p className="title">{title}</p>
      <p className="subTitle">관련 질문 idx.</p>
      <p>{questionIndex}</p>
    </Card>
  );
}
