import React from "react";
import styled from "styled-components";
import styleConstant from "data/constant/styleConstant";

interface ProfileInfoCardProps {
  name: string;
  birth: string;
  gender: string;
  job: string;
  isSelect: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export function ProfileInfoCard({
  name,
  birth,
  gender,
  job,
  isSelect,
  onClick,
}: ProfileInfoCardProps) {
  const genderSwitch = (genderNum: number) => {
    switch (genderNum) {
      case 1:
        return "남자";
      case 2:
        return "여자";
      default:
        return "둘 다 아니에요";
    }
  };
  return (
    <Cover onClick={onClick} isSelect={isSelect}>
      <p className="title">이름</p>
      <p className="content">{name}</p>
      <div className="flexContainer">
        <div>
          <p className="title">생년월일</p>
          <p className="content last">{birth}</p>
        </div>
        <div>
          <p className="title">성별</p>
          <p className="content last">{genderSwitch(Number(gender))}</p>
        </div>
      </div>
      {/* <p className="title">직업</p>
      <p className="content last">{job}</p> */}
    </Cover>
  );
}

const Cover = styled.div<{ isSelect: true | false }>`
  width: 326px;
  margin: 0 0 48px;
  padding: 16px;
  border: ${({ isSelect }) =>
    isSelect ? "1px solid #1bbcff" : "1px solid #f8f8f8"};
  border-radius: 16px;
  background-color: #f8f8f8;
  cursor: pointer;
  .title {
    font-size: ${styleConstant.FONT_SIZE.small};
    line-height: 1.43;
    letter-spacing: -0.2;
    margin: 0 0 8px;
  }
  .content {
    font-size: ${styleConstant.FONT_SIZE.medium};
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: -0.2px;
    margin: 0 0 24px;
  }
  .last {
    margin: 0;
  }
  .flexContainer {
    display: flex;
    gap: 44px;
  }
`;
