import React, { useEffect } from 'react';
import styled from 'styled-components';
import { UserSymptom } from './userSymptom';
import { UserInfo } from './userInfo';
import { SymptomInfo } from './symptomInfo';
import { ProfileQuestion } from './profileQuestion';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducer/rootReducer';

export function SelectSymptom() {
	const counseling = useSelector((state: RootState) => state.chat.selectCounseling);
	const now = new Date();
	const year = now.getFullYear();

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [counseling]);

	return (
		<Cover>
			<UserInfo
				name={counseling.Profile?.name}
				hospitalName={counseling.hospital?.hospitalName}
				birth={`${year - Number(counseling.Profile?.birthDate.slice(0, 4)) + 1}세`}
				gender={counseling.Profile?.gender}
				job={counseling.Profile?.job}
			/>
			<ProfileQuestion questions={counseling.Profile?.Questions} />
			<SymptomInfo
				department={counseling.department}
				symptom={counseling.symptom}
				additionalSymptom={counseling.additionalSymptom}
			/>
			<UserSymptom userSymptom={counseling.Questions} />
		</Cover>
	);
}

const Cover = styled.div`
	display: flex;
	margin: 0;
	flex-wrap: wrap;
	gap: 0 20px;
`;
