import { PrimaryBtn } from 'components/button';
import styleConstant from 'data/constant/styleConstant';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AskCard } from './Askcard';
import { NumberInput } from './NumInput';
import { TextInput } from './TextInput';
interface StateProps {
	mainText: string;
	subText: string;
	answerType: string;
	s3Img: string;
	inputType: string;
}
export function InputText(state: StateProps) {
	const [locale, setLocale] = useState(0);
	const toggleLocale = () => {
		locale === 0 ? setLocale(1) : setLocale(0);
	};
	return (
		<>
			<Cover>
				{/* <p>{state.answerType + "/" + state.inputType}</p> */}
				<p className='mainText'>{state.mainText.split('@@')[locale]}</p>
				<p className='subText'>{state?.subText?.split('@@')[locale]}</p>
				{/* {state.s3Img ? (
          <ImgContainer>
            <img
              src={state.s3Img}
              alt="question image"
              width="450x"
              height="200x"
              // objectFit="contain"
            />
          </ImgContainer>
        ) : null} */}
				<AnswerContainer>
					<TextInput />
				</AnswerContainer>
				<PrimaryBtn onClick={toggleLocale} label={'다른 언어로 변경하기'} />
			</Cover>
		</>
	);
}

const Cover = styled.div`
	width: 375px;
	margin: auto;
	margin-top: 24px;
	padding-bottom: 32px;
	.mainText {
		font-size: ${styleConstant.FONT_SIZE.large};
		font-weight: bold;
		margin: 0 0 24px;
		word-break: keep-all;
	}
`;

const AnswerContainer = styled.div`
	margin: 0 0 24px;
`;

const ImgContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	height: auto;
	margin: 0 0 24px;
	img {
		object-fit: scale-down;
	}
`;
