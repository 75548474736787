import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import useAxios from 'api/axiosCustom';
import { useNavigate } from 'react-router-dom';
import { PrimaryInput } from 'components/input';
import { PrimaryBtn, TestBtn } from 'components/button';
import { TwoBtnModal, LocaleModal } from 'components/modal';

import styleConstant from 'data/constant/styleConstant';
import { LoadingModal } from 'components/modal';
import { Switch, Table } from 'antd';
import { IDepartment } from 'data/interface/interviewManage';
import { TeamAddModal } from './teamAddModal';
import { MemberAddModal } from './memberAddModal';
import { ErrorAlert } from 'components/alert';
import { PrimaryDropDown } from 'components/dropDown';
import { DepartmentAddModal } from './departmentAddModal';
import { symptomList } from 'tmp/symptomList';

export function SelectDepartment() {
	const axios = useAxios();

	const [columnData, setColumnData] = useState<IDepartment[]>([]);
	const [totalCount, setTotalCount] = useState<number>(0);
	const [page, setPage] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [searchDepartment, setSearchDepartment] = useState<string>('');
	const [searchSymptomId, setSearchSymptomId] = useState<string>('');
	const [deleteId, setDeleteId] = useState<string>('');

	const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [isSearch, setIsSearch] = useState<boolean>(false);
	const [departmentEditValue, setDepartmentEditValue] = useState<any>({});

	const listRequest = useCallback(() => {
		(async () => {
			setIsLoading(true);
			if (isSearch) {
				const res = await axios.get(
					`/department/findDepartmentId?search=${searchDepartment}&findDepartmentId=${searchSymptomId}&offset=${page}`
				);
				setColumnData(res.data.findDepartment);
				setTotalCount(res.data.totalCount);
			} else {
				const res = await axios.get(`/department/findDepartmentId?offset=${page}`);
				setColumnData(res.data.findDepartment);
				setTotalCount(res.data.totalCount);
			}
			setIsLoading(false);
		})();
	}, [page]);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const res = await axios.get(`/department/findDepartmentId?offset=${page}`);
			setColumnData(res.data.findDepartment);
			setTotalCount(res.data.totalCount);
			setIsLoading(false);
		})();
	}, []);

	console.log('columnData', columnData);

	useEffect(() => {
		listRequest();
	}, [page]);

	useEffect(() => {
		if (!isModalVisible) {
			setDepartmentEditValue({});
		}
	}, [isModalVisible]);

	function onDepartmentEdit(value: any) {
		setDepartmentEditValue(value);
		setIsModalVisible(true);
	}

	const handleChangeActivation = async (e: boolean, value: any) => {
		if (e) {
			const res = await axios.put(`/department/putDepartment/${value.id}`, {
				name: value.name + '@@' + value.enName,
				symptomId: value.symptomId,
				isActive: 1,
				analyze: value.analyze,
			});

			res.status === 200 && listRequest();
		} else {
			const res = await axios.put(`/department/putDepartment/${value.id}`, {
				name: value.name + '@@' + value.enName,
				symptomId: value.symptomId,
				isActive: 0,
				analyze: value.analyze,
			});
			res.status === 200 && listRequest();
		}
	};

	const handleChangeAiOption = async (e: boolean, value: any) => {
		if (e) {
			const res = await axios.put(`/department/putDepartment/${value.id}`, {
				name: value.name + '@@' + value.enName,
				symptomId: value.symptomId,
				isActive: value.isActive,
				analyze: 1,
			});

			res.status === 200 && listRequest();
		} else {
			const res = await axios.put(`/department/putDepartment/${value.id}`, {
				name: value.name + '@@' + value.enName,
				symptomId: value.symptomId,
				isActive: value.isActive,
				analyze: 0,
			});
			res.status === 200 && listRequest();
		}
	};

	const columns = [
		{
			title: 'idx.',
			dataIndex: 'id',
			key: 'idx',
			render: (text: any, value: any, index: any) => (
				<p
					className={
						index === 0 || index % 2 === 0 ? 'text idx color' : 'text idx'
					}
					onClick={() => onDepartmentEdit(value)}>
					{text}
				</p>
			),
		},
		{
			title: '진료과',
			dataIndex: 'name',
			key: 'name',
			render: (text: any, value: any, index: any) => (
				<p
					className={
						index === 0 || index % 2 === 0
							? 'text symptom color'
							: 'text symptom'
					}
					onClick={() => onDepartmentEdit(value)}>
					{text}
				</p>
			),
		},
		{
			title: '관련 증상 idx.',
			dataIndex: `symptomId`,
			key: 'symptomId',
			render: (text: any, value: any, index: any) => (
				<p
					className={
						index === 0 || index % 2 === 0
							? 'text questionIdx color'
							: 'text questionIdx'
					}
					onClick={() => onDepartmentEdit(value)}>
					{text.replace(/;/g, ', ').replace(/,\s*$/, '')}
				</p>
			),
		},
		{
			title: 'AI 추천',
			dataIndex: `analyze`,
			key: 'analyze',
			render: (text: number, value: any, index: number) => (
				<SwitchBox
					style={{ width: '100px' }}
					className={index === 0 || index % 2 === 0 ? 'color' : ''}>
					<CustomSwitch
						checkedChildren='ON'
						unCheckedChildren='OFF'
						style={{
							margin: '0 0 0 11px',
							backgroundColor: styleConstant.COLOR.CTA.DEFAULT,
						}}
						onChange={() => handleChangeAiOption(text === 0, value)}
						checked={text === 1}
						loading={isLoading}
					/>
				</SwitchBox>
			),
		},
		{
			title: '활성 상태',
			dataIndex: `isActive`,
			key: 'isActive',
			render: (text: number, value: any, index: number) => (
				<SwitchBox
					style={{ width: '100px' }}
					className={index === 0 || index % 2 === 0 ? 'color' : ''}>
					<CustomSwitch
						checkedChildren='ON'
						unCheckedChildren='OFF'
						style={{
							margin: '0 0 0 20px',
							backgroundColor: styleConstant.COLOR.CTA.DEFAULT,
						}}
						onChange={() => handleChangeActivation(text === 0, value)}
						checked={text === 1}
						loading={isLoading}
					/>
				</SwitchBox>
			),
		},
		{
			title: '',
			dataIndex: `isActive`,
			key: 'isActive',
			render: (text: number, value: any, index: number) => (
				<p
					style={{ width: '75px' }}
					className={
						index === 0 || index % 2 === 0
							? 'color delete text'
							: 'delete text'
					}
					onClick={() => {
						setDepartmentEditValue(value);
						setIsDeleteModal(true);
					}}>
					삭제
				</p>
			),
		},
	];

	function onDeleteModalCancelBtn() {
		setIsDeleteModal(false);
	}

	async function onDeleteModalCheckBtn() {
		if (departmentEditValue) {
			await axios.delete(`/department/deleteDepartment/${departmentEditValue.id}`);
			setDepartmentEditValue({});
			setIsDeleteModal(false);
			listRequest();
		}
	}

	function onModalCancelBtn() {
		setIsModalVisible(false);
	}

	function handleInput(e: any, key: string) {
		switch (key) {
			case 'department': {
				setSearchDepartment(e.target.value);
				break;
			}
			case 'symptom': {
				setSearchSymptomId(e.target.value);
				break;
			}
		}
	}

	function onAddTeamBtn() {
		setIsModalVisible(true);
	}

	function onPageChange(e: any) {
		setPage(10 * (e - 1));
	}

	async function onSearchBtn() {
		setIsLoading(true);
		setIsSearch(true);
		setPage(0);

		const res = await axios.get(
			`/department/findDepartmentId?search=${searchDepartment}&searchSymptomId=${searchSymptomId}&offset=${page}`
		);
		setColumnData(res.data.findDepartment);
		setTotalCount(res.data.totalCount);
		setIsLoading(false);
	}

	return (
		<Cover>
			<LoadingModal isModalVisible={isLoading} />
			<TwoBtnModal
				isModalVisible={isDeleteModal}
				mainText='정말로 삭제하시겠습니까?'
				leftBtnText='아니요'
				leftBtnClick={onDeleteModalCancelBtn}
				rightBtnText='삭제하기'
				rightBtnColor='red'
				rightBtnClick={onDeleteModalCheckBtn}
			/>
			<DepartmentAddModal
				isModalVisible={isModalVisible}
				setIsModalVisible={setIsModalVisible}
				value={departmentEditValue}
				onClick={onModalCancelBtn}
				listRequest={listRequest}
			/>
			<SearchContainer>
				<p className='searchMainText'>검색하실 정보를 입력해주세요.</p>
				<InputContainer>
					<div className='input'>
						<PrimaryInput
							label='진료과'
							value={searchDepartment}
							onChange={(e) => {
								handleInput(e, 'department');
							}}
						/>
					</div>
					<div className='input'>
						<PrimaryInput
							label='관련 증상 idx.'
							value={searchSymptomId}
							onChange={(e) => {
								handleInput(e, 'symptom');
							}}
						/>
					</div>
					<div className='btn'>
						<PrimaryBtn label='검색' onClick={onSearchBtn} />
					</div>
				</InputContainer>
			</SearchContainer>
			<div className='btnContainer'>
				<PrimaryBtn label='새 진료과 추가' onClick={onAddTeamBtn} />
			</div>
			<CustomTable
				columns={columns}
				dataSource={columnData}
				pagination={{
					total: totalCount,
					showSizeChanger: false,
					onChange: onPageChange,
				}}
			/>
		</Cover>
	);
}

const Cover = styled.div`
	margin-top: 48px;
	.btnContainer {
		width: 210px;
		margin: 0 auto 32px;
	}
	.delete {
		color: #e44b55;
		cursor: pointer;
	}
	.text {
		/* text-align: center; */
		padding-left: 17px;
		line-height: 56px;
		height: 56px;
		margin: 0;
		cursor: pointer;
		/* white-space: nowrap; */
		word-break: break-all;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
		white-space: nowrap;
	}
	.color {
		background-color: #f8f8f8;
	}
	.idx {
		width: 100px;
	}
	.symptom {
		width: 300px;
	}

	.questionIdx {
		width: 350px;
	}
`;

const SearchContainer = styled.div`
	width: 100%;
	height: 188px;
	box-sizing: border-box;
	padding: 24px 24px 32px;
	margin-bottom: 48px;
	border-radius: 20px;
	box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.08);
	.searchMainText {
		font-size: ${styleConstant.FONT_SIZE.large};
		font-weight: bold;
		margin: 0;
	}
`;

const InputContainer = styled.div`
	display: flex;
	gap: 16px;
	margin-top: 12px;
	align-items: center;
	.input {
		width: 100%;
		margin: 0;
		padding: 0;
	}
	.btn {
		width: 200px;
		margin: 0;
		padding-top: 24px;
	}
`;

// const CostomPrimaryInput = styled(PrimaryInput)`
// 	width: 400px !important;
// 	height: 100px !important;
// `;

const CustomTable = styled(Table)`
	.ant-table-thead {
		th {
			padding: 8px 16px;
			border-top: 1px solid #f0f0f0;
			background-color: #fff;
			&::before {
				display: none;
			}
		}
	}
	.ant-table-tbody {
		td {
			padding: 0;
		}
	}
	/* .ant-table-cell-row-hover {
    background-color: #000 !important;
  } */
`;

const SwitchBox = styled.div`
	display: flex;
	align-items: center;
	height: 55px;
	gap: 40px;
`;

const CustomSwitch = styled(Switch)`
	margin: 2px 0 0;
	transform: scale(1.1);
`;
