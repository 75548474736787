import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NumberInput } from "components/input";
import { PrimaryBtn } from "components/button";
import { ErrorAlert } from "components/alert";
import { useSelector, useDispatch } from "react-redux";
import { answerSubmit, inputUnitReset } from "store/reducer/testPreviewReducer";
import { RootState } from "store/reducer/rootReducer";

export default function InputNum() {
  const questionIndex = useSelector(
    (state: RootState) => state.testPreview.index,
  );
  const question = useSelector(
    (state: RootState) => state.testPreview.question[questionIndex],
  );
  const questionLength = useSelector(
    (state: RootState) => state.testPreview.question.length,
  );
  const preAnswer = useSelector(
    (state: RootState) => state.testPreview.answered[questionIndex],
  );

  const dispatch = useDispatch();

  const [numValue, setNumValue] = useState<number>();
  const [alertToggle, setAlertToggle] = useState<boolean>(false);
  const locale = localStorage.getItem("locale");

  useEffect(() => {
    dispatch(inputUnitReset(questionIndex));
    window.scrollTo(0, 0);
    if (preAnswer === undefined) {
      setNumValue(NaN);
    } else {
      setNumValue(preAnswer.answer);
    }
  }, [questionIndex]);

  function onInputNumNext() {
    if (!numValue) {
      setAlertToggle(true);
      setTimeout(() => setAlertToggle(false), 2000);
    } else {
      dispatch(
        answerSubmit({
          id: question.id,
          answer: numValue,
          enAnswer: numValue,
        }),
      );
    }
  }

  function onInputNumChange({ target: { value } }: any) {
    setNumValue(value);
  }

  return (
    <>
      <ErrorAlert toggle={alertToggle} label="답변을 입력해주세요." />
      <Cover>
        <p className="mainText">
          {locale === "en"
            ? question.EnQuestion.mainQuestion
            : question.mainQuestion}
        </p>
        <p className="subText">
          {locale === "en"
            ? question.EnQuestion.subQuestion
            : question.subQuestion}
        </p>
        {question.imgSrc ? (
          <ImgContainer>
            <img
              src={question.imgSrc}
              alt="question"
              width="450x"
              height="200x"
            />
          </ImgContainer>
        ) : null}
        <AnswerContainer>
          <NumberInput
            value={numValue}
            onChange={onInputNumChange}
            label={
              locale === "en"
                ? question.EnQuestion.inputUnit
                : question.inputUnit
            }
          />
        </AnswerContainer>
        {questionLength - 1 === questionIndex ? (
          <PrimaryBtn
            label={locale === "ko" ? "제출" : "Submit"}
            onClick={onInputNumNext}
          />
        ) : (
          <PrimaryBtn
            label={locale === "ko" ? "다음" : "Continue"}
            onClick={onInputNumNext}
          />
        )}
      </Cover>
    </>
  );
}

const Cover = styled.div`
  font-weight: bold;
  margin-top: 24px;
  padding-bottom: 32px;
  .mainText {
    font-size: 24px;
    line-height: 133%;
    margin: 0 0 0;
    word-break: keep-all;
  }
  .subText {
    font-size: 16px;
    line-height: 150%;
    margin: 8px 0 24px;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  margin: 0 0 24px;
`;

const AnswerContainer = styled.div`
  margin: 0 0 32px;
`;
