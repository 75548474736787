import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import styleConstant from 'data/constant/styleConstant';
import { PrimaryInput, TextInput } from 'components/input';
import { PrimaryDropDown } from 'components/dropDown';
import { PrimaryBtn } from 'components/button';
import { ReactComponent as DeleteIcon } from 'assets/icon/deleteIcon.svg';
import { ReactComponent as PlusIcon } from 'assets/icon/plusIcon.svg';
import { ReactComponent as MinusIcon } from 'assets/icon/minusIcon.svg';
import useAxios from 'api/axiosCustom';
import { useDebounce } from 'hooks/useDebounce';
import { useLocation, useNavigate } from 'react-router-dom';
import routerConstant from 'data/constant/routerConstant';
import { ErrorAlert } from 'components/alert';

interface QAProps {
	question: string;
	idx: string;
	isFilled?: boolean;
	answerType?: any;
	answerList: string[];
	list: string[];
}

interface QuestionProps {
	id: number;
	mainQuestion: string;
	answerType: string;
}

interface HospitalProps {
	hospitalName: string;
	id: number;
}

interface DepartmentProps {
	name: string;
	id: number;
}

interface SymptomProps {
	hospitalName: string;
	id: number;
}

export function ConditionInfo() {
	const [hospitalList, setHospitalList] = useState([]);
	const [hospital, setHospital] = useState<string>('');
	const [departmentList, setDepartmentList] = useState<DepartmentProps[]>([]);
	const [department, setDepartment] = useState<string>('');
	const [symptomList, setSymptomList] = useState([]);
	const [symptom, setSymptom] = useState<string>('');
	const [questionFilter, setQuestionFilter] = useState<QuestionProps[]>([]);
	const [qaList, setQaList] = useState<QAProps[]>([]);

	const [title, setTitle] = useState<string>('');
	const [alertOpen, setIsAlertOpen] = useState(false);

	const [searchResult, setSearchResult] = useState<QuestionProps[]>([]);
	const [focus, setFocus] = useState(0);

	const axios = useAxios();
	const navigate = useNavigate();
	const location = useLocation();

	const isBlank = /^\s+|\s+$/g;

	useEffect(() => {
		if (location.state) {
			(async () => {
				setTitle(location.state.value.title);
				setHospital(location.state.value.hospital);
				setDepartment(location.state.value.department.slice(0, -1));
				setSymptom(location.state.value.symptom);

				const qArr = location.state.value.question.split('$');
				const qIdArr = location.state.value.questionId.split(',');
				const aArr = location.state.value.answer.split('$');

				const qaArr = qArr.map((question: string, i: number) => {
					return {
						question,
						idx: qIdArr[i],
						isFilled: true,
						answerList: aArr[i].split(';'),
						list: [],
					};
				});
				qaArr[0].question !== '' && setQaList([...qaArr]);
			})();
		}
	}, []);

	useEffect(() => {
		qaList.map(async (qa, idx) => {
			const res = await axios.get(
				`/admin/recommend/searchQuestion?search=${qa.question}`
			);

			qa.answerType = res.data[0].answerType;
			setQaList([...qaList]);
		});
	}, [title]);

	const handleAddCondition = () => {
		if (hospital === '' || symptom === '') {
			setIsAlertOpen(true);
			setTimeout(() => {
				setIsAlertOpen(false);
			}, 1000);
		} else {
			qaList.length < 10 &&
				setQaList([
					...qaList,
					{
						question: '',
						idx: '',
						isFilled: false,
						answerList: [''],
						list: [],
					},
				]);
		}
	};

	const handleDeleteCondition = (idx: number) => {
		if (qaList.length > 0) {
			qaList.splice(idx, 1);
			setQaList([...qaList]);
		}
	};

	useEffect(() => {
		(async () => {
			let id;
			departmentList.map((dep, idx, arr) => {
				if (dep.name === department) {
					id = arr[idx].id;
				}
			});

			let res;
			if (department !== 'Any' && id !== undefined) {
				res = await axios.get(`/admin/recommend/showSymptom?department=${id}`);
			} else {
				res = await axios.get(`/admin/recommend/showSymptom?`);
			}
			//`/admin/recommend/showSymptom
			const symArr = res.data.map((sym: any) => sym.name);

			symArr.unshift('Any');
			setSymptomList(symArr);
		})();
	}, [department]);

	useEffect(() => {
		(async () => {
			const res = await axios.get(`/admin/recommend/hospital?hospitalName=`);
			const hosName = res.data.map((hos: any) => hos.hospitalName);
			hosName.unshift('Any');
			setHospitalList(hosName);
		})();
	}, []);

	useEffect(() => {
		(async () => {
			const res = await axios.get(`/admin/recommend/showDepartment?search=`);

			const depArr = [{ id: 0, name: 'Any' }, ...res.data];

			setDepartmentList(depArr);
		})();
	}, []);

	const handleSelectDropbox = async (e: any, key: string) => {
		switch (key) {
			case 'hospital':
				setHospital(e);
				break;
			case 'department':
				setDepartment(e);
				break;
			case 'symptom':
				setSymptom(e);
				if (e !== 'Any') {
					const res = await axios.get(
						`/admin/recommend/showQuestion?search=${e}`
					);

					setQuestionFilter(res.data);
				}
				break;
		}
		setQaList([]);
	};

	const debouncedInputText = useDebounce(qaList[focus]?.question, 200);

	useEffect(() => {
		if (debouncedInputText) {
			handleSearch(debouncedInputText, focus);
		}
	}, [debouncedInputText]);

	const handleSearch = async (input: string, idx: number) => {
		const res = await axios.get(`/admin/recommend/searchQuestion?search=${input}`);

		if (questionFilter.length !== 0) {
			const newArr: QuestionProps[] = [];
			questionFilter.map((question: QuestionProps) =>
				res.data.map(
					(q: QuestionProps) => question.id === q.id && newArr.push(question)
				)
			);
			setSearchResult(newArr);
		} else {
			setSearchResult(res.data);
		}
	};

	const handleInput = async (e: any, idx: number, key?: string) => {
		if (key === 'title') {
			setTitle(e.target.value);
		} else {
			qaList[idx] = {
				question: e.target.value,
				isFilled: false,
				idx: '',
				answerList: [''],
				list: [''],
			};
			setQaList([...qaList]);
			setFocus(idx);
		}
	};
	const handleClickQuestionBox = async () => {
		if (symptom !== 'Any') {
			const res = await axios.get(`/admin/recommend/showQuestion?search=${symptom}`);

			setQuestionFilter(res.data);
		}
	};

	const handleSelectQuestion = async (
		result: string,
		resultIdx: string,
		answerType: string,
		idx: number
	) => {
		// qaList[idx].isFilled = true;
		// qaList[idx].question = result;
		// qaList[idx].idx = resultIdx;
		qaList[idx] = {
			...qaList[idx],
			question: result,
			isFilled: true,
			idx: resultIdx,
			answerType,
		};
		setQaList([...qaList]);
		setSearchResult([]);
		const res = await axios.get(`/admin/recommend/showAnswer?search=${resultIdx}`);
		const arr = res.data.split(';').slice(0, -1);
		// qaList[idx].answer = arr[0];
		// qaList[idx].list = arr;
		qaList[idx] = {
			...qaList[idx],
			answerList: [arr[0]],
			list: arr,
		};
		setQaList([...qaList]);
	};

	const handleClickAnswerBox = async (e: any, i: number) => {
		const ress = await axios.get(`/admin/recommend/showAnswer?search=${qaList[i].idx}`);
		const arr = ress.data.split(';').slice(0, -1);
		const newArr: any[] = [];

		arr.map((ans: any) => {
			!qaList[i].answerList.includes(ans) && newArr.push(ans);
		});

		qaList[i].list = newArr;
		setQaList([...qaList]);
	};

	const handleSelectAnswer = (e: any, idx: number, ansIdx: number) => {
		qaList[idx].answerList[ansIdx] = e;
		setQaList([...qaList]);
	};

	const handleSaveBtn = async () => {
		if (
			title.replace(isBlank, '') === '' ||
			hospital === '' ||
			department === '' ||
			symptom === ''
		) {
			setIsAlertOpen(true);
			setTimeout(() => {
				setIsAlertOpen(false);
			}, 1000);
		} else {
			let questionArr: string[] = [];
			let questionIdArr: string[] = [];
			let answerArr: string[] = [];
			qaList.map((qa) => {
				if (qa.question !== '' && !qa.answerList.includes('')) {
					questionArr.push(qa.question);
					questionIdArr.push(qa.idx);
					answerArr.push(qa.answerList.join(';'));
				}
			});

			const body = {
				hospital,
				title,
				department,
				symptom,
				question: questionArr.join('$'),
				answer: answerArr.join('$'),
				questionId: questionIdArr.join(),
			};

			let res;
			location.state
				? (res = await axios.put(
						`/admin/recommend/ifQuestion/${location.state?.value.id}`,
						body
				  ))
				: (res = await axios.post('/admin/recommend/ifQuestion', body));

			navigate(routerConstant.CONDITION);
		}
	};

	const handleAddAnswer = async (i: number) => {
		const res = await axios.get(`/admin/recommend/showAnswer?search=${qaList[i].idx}`);
		const arr = res.data.split(';').slice(0, -1);
		const newArr: any[] = [];

		arr.map((ans: any) => {
			!qaList[i].answerList.includes(ans) && newArr.push(ans);
		});
		typeof newArr[0] !== 'undefined' && qaList[i].answerList.push(newArr[0]);
		qaList[i].list = newArr;
		setQaList([...qaList]);
	};
	const handleMinusAnswer = (idx: number) => {
		if (qaList[idx].answerList.length > 1) {
			qaList[idx].answerList.pop();
			setQaList([...qaList]);
		}
	};

	return (
		<Cover>
			<ErrorAlert
				label={
					title === ''
						? '제목을 입력해주세요.'
						: title.replace(isBlank, '') === ''
						? '제목에 공백 이외의 문자를 입력해주세요.'
						: hospital === ''
						? '병원을 입력해주세요.'
						: department === ''
						? '진료과목을 입력해주세요.'
						: symptom === ''
						? '증상을 입력해주세요.'
						: ''
				}
				toggle={alertOpen}
			/>
			<BtnContainer>
				<PrimaryBtn
					label={location.state ? '수정' : '저장'}
					onClick={handleSaveBtn}
				/>
			</BtnContainer>

			<InfoContainer>
				<Grid>
					<Box>
						<p>제목</p>
						<PrimaryInput
							value={title}
							onChange={(e) => handleInput(e, 0, 'title')}
						/>
					</Box>
				</Grid>
				<Grid>
					<HalfGrid>
						<Box>
							<p>병원</p>
							<PrimaryDropDown
								selectItem={hospital}
								items={hospitalList}
								dangerText='Any'
								itemClick={(e) => {
									handleSelectDropbox(e, 'hospital');
								}}
							/>
						</Box>
					</HalfGrid>
					<OperatorsContainer>
						<Operators>and</Operators>
					</OperatorsContainer>
					<HalfGrid>
						<Box>
							<p>진료 과목</p>
							<PrimaryDropDown
								selectItem={department}
								items={departmentList.map((dep) => dep.name)}
								dangerText='Any'
								itemClick={(e) => {
									handleSelectDropbox(e, 'department');
								}}
							/>
						</Box>
					</HalfGrid>
					<OperatorsContainer>
						<Operators>and</Operators>
					</OperatorsContainer>
					<HalfGrid>
						<Box>
							<p>증상</p>
							<PrimaryDropDown
								selectItem={symptom}
								items={symptomList}
								dangerText='Any'
								itemClick={(e) => {
									handleSelectDropbox(e, 'symptom');
								}}
							/>
						</Box>
					</HalfGrid>
				</Grid>
				{qaList.map((qa, idx) => {
					return (
						<div key={idx}>
							<Grid>
								<Operators>and</Operators>
							</Grid>
							<Grid>
								<ConditionBox>
									<Grid>
										<DeleteBtn
											onClick={() =>
												handleDeleteCondition(idx)
											}
										/>
										<HalfGrid>
											<Box
												onClick={
													handleClickQuestionBox
												}>
												<p>질문 {idx + 1}</p>
												<PrimaryInput
													value={qa.question}
													onChange={(e) =>
														handleInput(
															e,

															idx
														)
													}
												/>
												{qa.question !== '' &&
													!qa.isFilled &&
													(searchResult.length >
													0 ? (
														<ResultContainer>
															{searchResult.map(
																(
																	result: any
																) => (
																	<Result
																		key={
																			result.id
																		}
																		onClick={() => {
																			handleSelectQuestion(
																				result.mainQuestion,
																				result.id,
																				result.answerType,
																				idx
																			);
																		}}>
																		{result.id +
																			' ❘ ' +
																			result.mainQuestion}
																	</Result>
																)
															)}
														</ResultContainer>
													) : (
														<ResultContainer>
															<Result>
																조회된
																데이터가
																없습니다.
															</Result>
														</ResultContainer>
													))}
											</Box>
										</HalfGrid>

										<HalfGrid>
											<Box
												onClick={(e) =>
													handleClickAnswerBox(
														e,
														idx
													)
												}>
												<p>답변 {idx + 1}</p>
												<PrimaryDropDown
													selectItem={
														qa
															?.answerList[0] ===
														''
															? qa
																	?.list[0]
															: qa
																	?.answerList[0]
													}
													items={qa.list}
													itemClick={(e) => {
														handleSelectAnswer(
															e,
															idx,
															0
														);
													}}
												/>
											</Box>
										</HalfGrid>
									</Grid>
									{qa.answerList.length > 1 &&
										qa.answerList?.map(
											(answer, ansIdx) =>
												ansIdx !== 0 && (
													<Grid>
														<AdditionalAnswer>
															<Box
																onClick={(
																	e
																) =>
																	handleClickAnswerBox(
																		e,
																		idx
																	)
																}>
																<PrimaryDropDown
																	selectItem={
																		answer ===
																		''
																			? qa
																					.list[0]
																			: answer
																	}
																	items={
																		qa.list
																	}
																	itemClick={(
																		e
																	) => {
																		handleSelectAnswer(
																			e,
																			idx,
																			ansIdx
																		);
																	}}
																/>
															</Box>
														</AdditionalAnswer>
													</Grid>
												)
										)}
									{qa.answerType === '복수 선택' && (
										<Grid>
											<CircleBtnBox>
												<PlusBtn
													onClick={() =>
														handleAddAnswer(
															idx
														)
													}
												/>
												<MinusBtn
													onClick={() =>
														handleMinusAnswer(
															idx
														)
													}
												/>
											</CircleBtnBox>
										</Grid>
									)}
								</ConditionBox>
							</Grid>
						</div>
					);
				})}
				{qaList.length < 10 && (
					<Grid>
						<OperatorAddBtn onClick={handleAddCondition}>
							and 조건 추가하기
						</OperatorAddBtn>
					</Grid>
				)}
			</InfoContainer>
		</Cover>
	);
}

const Cover = styled.div`
	margin-bottom: 48px;
	width: 100%;
`;

const BtnContainer = styled.div`
	width: 125px;
	position: absolute;
	top: -96px;
	right: 0;
	display: flex;
	gap: 20px;
`;

const InfoContainer = styled.div`
	display: flex-gird;
	gap: 20px;
	width: 100%;
	background-color: #f8f8f8;
	padding: 16px;
	margin-top: 8px;
	border-radius: 20px;
	font-size: ${styleConstant.FONT_SIZE.small};
`;

const Grid = styled.div`
	width: 100%;
	display: flex;
	margin: 8px 0;
	gap: 16px;
`;

const HalfGrid = styled.div`
	display: flex;
	gap: 16px;
	width: 50%;
	p {
		margin: 0 0 -3px 0;
	}
`;

const AdditionalAnswer = styled.div`
	display: flex;
	width: 50%;
	margin: -45px 0 0 Calc(50% + 8px);
`;

const CircleBtnBox = styled.div`
	display: flex;
	gap: 10px;
	margin: -35px 0 0 Calc(90% + 23px);
`;

const PlusBtn = styled(PlusIcon)`
	padding: 3px;
	background-color: #444;
	border-radius: 15px;
	height: 30px;
	width: 30px;
	filter: invert(1);
	cursor: pointer;
`;
const MinusBtn = styled(MinusIcon)`
	padding: 3px;
	background-color: #444;
	border-radius: 15px;
	height: 30px;
	width: 30px;
	filter: invert(1);
	cursor: pointer;
`;

const Box = styled.div`
	width: 100%;
	gap: 16px;
	/* padding: 16px;
	background-color: #fff;
	border: 1px solid #cfcfcf; */
	border-radius: 16px;
	p {
		font-weight: 500;
		font-size: 14px;
	}
`;

const ConditionBox = styled.div`
	position: relative;
	width: 100%;
	margin: 0 0 10px 0;
	padding: 20px 16px 0;
	gap: 16px;
	background-color: #ecf0f0;
	border: 1px solid #d9e1e2;
	border-radius: 16px;
	display: flex;
	align-items: center;
	flex-direction: column;
	p {
		font-weight: 500;
		font-size: 14px;
	}
`;

const OperatorsContainer = styled.div`
	display: flex;
	align-items: flex-end;
`;
const Operators = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 0 0 16px 0;
	padding: 4px 12px;
	height: 35px;
	width: 60px;
	padding: 4px 12px;
	border: 1px solid #cfcfcf;
	border-radius: 20px;
	background-color: #fff;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
`;

const DeleteBtn = styled(DeleteIcon)`
	position: absolute;
	width: 28px;
	height: 28px;
	top: 25px;
	right: 15px;
	display: flex;
	margin: -10px 0 0 0;
	cursor: pointer;
`;

const OperatorAddBtn = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 0 0 16px 0;
	padding: 4px 12px;
	height: 48px;
	width: 172px;
	padding: 4px 12px;
	border: 1px solid #cfcfcf;
	border-radius: 20px;
	background-color: #fff;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
`;

const ResultContainer = styled.div`
	position: absolute;
	max-height: 500px;
	overflow: auto;
	width: 43%;
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	border: 1px solid #ecf0f0;
	border-radius: 16px;
	padding: 6px 0px;
	z-index: 1000;
	box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
		0 9px 28px 8px rgb(0 0 0 / 5%);
`;
const Result = styled.div`
	height: auto;
	background-color: #ffffff;
	padding: 10px 16px;
	line-height: 24px;
	:hover {
		background: #f4fbfe;
	}
`;
