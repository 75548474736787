// eslint-disable-next-line import/no-anonymous-default-export
export default {
	HOME: '/',
	LOGIN: '/login',
	PHONE_LOGIN: '/login/phonelogin',
	COUNSELING: '/counseling',
	INTERVIEW_MANAGE: '/interview',
	MEMBER: '/member',
	AFFLIATION_MANAGE: '/affiliation',
	RECOMMENDATION: '/recommendation',
	CONDITION: '/condition',
	CONDITION_CREATE: '/condition-create',
	RECOMANATION_CREATE: '/recommendation-create',
};
