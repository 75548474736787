import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useAxios from 'api/axiosCustom';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { ErrorAlert } from 'components/alert';
import { PrimaryInput } from 'components/input';
import { PrimaryBtn } from 'components/button';
import { PrimaryDropDown } from 'components/dropDown';
import styleConstant from 'data/constant/styleConstant';
import { QuestionAddComponent } from './questionAddComponent';
import { IQuestion, ISymptom } from 'data/interface/interviewManage';
import { async } from '@firebase/util';

interface SymptomAddModalProps {
	isModalVisible: boolean;
	setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	value?: ISymptom;
	setValue: React.Dispatch<React.SetStateAction<any>>;
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
	listRequest: () => void;
}

export function SymptomAddModal({
	isModalVisible,
	setIsModalVisible,
	value,
	setValue,
	onClick,
	listRequest,
}: SymptomAddModalProps) {
	const axios = useAxios();
	const [isCheck, setIsCheck] = useState<boolean>(false);
	const [isQuestionIdxCheck, setIsQuestionidxCheck] = useState<boolean>(false);
	const [symptom, setSymptom] = useState<string>('');
	const [selectActive, setSelectActive] = useState<string>(
		value?.isActive === true ? '비활성' : '활성'
	);
	const [selectGender, setSelectGender] = useState<string>('없음');
	const [questionList, setQuestionList] = useState<IQuestion[]>([]);
	const [questionIdList, setQuestionIdList] = useState<any>([]);

	useEffect(() => {
		if (value?.id) {
			setSymptom(`${value?.name}@@${value?.EnSymptom?.name}`);
			value?.isActive ? setSelectActive('활성') : setSelectActive('비활성');
			if (value?.isMenQuestion) {
				if (value?.isWomenQuestion) {
					setSelectGender('남성, 여성 모두');
				} else {
					setSelectGender('남성만');
				}
			} else if (value?.isWomenQuestion) {
				setSelectGender('여성만');
			} else {
				setSelectGender('없음');
			}

			(async () => {
				const selectedQuestionId = value?.questionIds
					?.split(';')
					?.filter((questionId: any) => questionId !== '');
				const res = await axios.get(`/question?limit=9999`);

				const selectedQuestion = selectedQuestionId?.map(
					(questionId: string) =>
						res.data?.questions?.filter(
							(dbQuestion: IQuestion) =>
								dbQuestion.id === Number(questionId)
						)[0]
				);

				setQuestionIdList(selectedQuestionId);
				setQuestionList(selectedQuestion || []);
			})();
		} else {
			setSymptom('');
			setSelectActive('활성');
			setSelectGender('없음');
			setQuestionIdList([]);
			setQuestionList([]);
		}
	}, [value]);

	useEffect(() => {
		if (!isModalVisible) {
			setValue({});
			setSymptom('');
			setSelectActive('활성');
			setSelectGender('없음');
		}
	}, [isModalVisible]);

	function onSymptomtInput(e: any) {
		setSymptom(e.target.value);
	}

	function onActiveDropdownClick(label: string) {
		setSelectActive(label);
	}

	function onGenderDropdownClick(label: string) {
		setSelectGender(label);
	}

	function checkHaveEnWord() {
		let checkReslut: boolean = true;
		const regExpSy = /@@/gi;
		if (!regExpSy.test(symptom)) {
			setIsCheck(true);
			checkReslut = false;
		}
		return checkReslut;
	}

	async function onSaveBtn() {
		let submitConnectQuestionList: string;

		const enCheck = checkHaveEnWord();
		if (enCheck) {
			const res = await axios.post('/symptom', {
				name: symptom,
				questionIds: questionIdList.join(';') + ';',
				isActive: selectActive === '활성' ? 1 : 0,
				isMenQuestion: selectGender.includes('남성'),
				isWomenQuestion: selectGender.includes('여성'),
			});
			if (res.status === 200) {
				setIsModalVisible(false);
				listRequest();
			} else {
				setIsCheck(true);
			}
		}
	}

	async function onModifyBtn() {
		if (value?.id) {
			const enCheck = checkHaveEnWord();
			if (enCheck) {
				const res = await axios.put(`/symptom/${value?.id}`, {
					name: symptom,
					questionIds: questionIdList.join(';') + ';',
					isActive: selectActive === '활성' ? 1 : 0,
					isMenQuestion: selectGender.includes('남성'),
					isWomenQuestion: selectGender.includes('여성'),
				});

				if (res.status === 200) {
					setIsModalVisible(false);
					listRequest();
				} else if (res.request.status) {
					setIsQuestionidxCheck(true);
					setTimeout(() => {
						setIsQuestionidxCheck(false);
					}, 3000);
				} else {
					setIsCheck(true);
					setTimeout(() => {
						setIsCheck(false);
					}, 3000);
				}
			}
		}
	}

	return (
		<>
			<ErrorAlert toggle={isCheck} label='입력값을 확인해주세요' />
			<ErrorAlert toggle={isQuestionIdxCheck} label='질문을 입력해 주세요' />
			<StyledModal
				visible={isModalVisible}
				maskClosable={true}
				centered={true}
				closable={false}
				width='676px'
				onCancel={() => setIsModalVisible(false)}
				footer={null}>
				<Content>
					<PrimaryInput
						label='증상(@@ 영어)'
						value={symptom}
						onChange={onSymptomtInput}
					/>
					{/* <PrimaryInput
						label='관련 질문 리스트'
						value={connectQuestionList}
						onChange={onConnectQuestionListInput}
					/> */}
					<QuestionAddComponent
						value={value}
						questionList={questionList}
						setQuestionList={setQuestionList}
						questionIdList={questionIdList}
						setQuestionIdList={setQuestionIdList}
					/>
					<DropdownContainer>
						<PrimaryDropDown
							label='활성 여부'
							selectItem={selectActive}
							items={['활성', '비활성']}
							itemClick={onActiveDropdownClick}
							dangerText='비활성'
						/>
						<PrimaryDropDown
							label='성별 특수 질문'
							selectItem={selectGender}
							items={['없음', '남성만', '여성만', '남성, 여성 모두']}
							itemClick={onGenderDropdownClick}
							dangerText='없음'
						/>
					</DropdownContainer>
					<BtnContainer>
						<PrimaryBtn label='취소' color='none' onClick={onClick} />
						{value?.id ? (
							<PrimaryBtn label='수정하기' onClick={onModifyBtn} />
						) : (
							<PrimaryBtn label='저장하기' onClick={onSaveBtn} />
						)}
					</BtnContainer>
				</Content>
			</StyledModal>
		</>
	);
}

const StyledModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 20px;
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
`;

const DropdownContainer = styled.div`
	display: flex;
	gap: 16px;
`;

const BtnContainer = styled.div`
	display: flex;
	gap: 16px;
	margin: 14px 0 0;
`;
