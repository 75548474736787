import styled from 'styled-components';
import { HeaderBar } from 'components/header';
import menuConstant from 'data/constant/menuConstant';
import { ConditionInfo } from 'components/detail/conditionInfo';
import { useLocation } from 'react-router-dom';

export default function ConditionCreatePage() {
	const location = useLocation();
	return (
		<Cover>
			<HeaderBar nowPath={menuConstant.RECOMMENDATION_MANAGE} />
			<ContentsContainer>
				<RecommendationContainer>
					<p className='mainText'>
						{location.state ? '조건 수정하기' : '새로운 조건 만들기'}
					</p>
					<ConditionInfo />
				</RecommendationContainer>
			</ContentsContainer>
		</Cover>
	);
}

const Cover = styled.div`
	width: 1024px;
	margin: 0 auto;
	padding: 0;
`;

const ContentsContainer = styled.div`
	display: flex;
	margin-top: 42px;
	gap: 20px;
`;

const RecommendationContainer = styled.div`
	width: 100%;
	position: relative;
	.mainText {
		position: absolute;
		/* width: 350px; */
		top: -96px;
		left: 52px;
		margin: 0;
		font-size: 40px;
		font-weight: bold;
	}
`;
