import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useAxios from "api/axiosCustom";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { ErrorAlert } from "components/alert";
import { PrimaryInput } from "components/input";
import { PrimaryBtn } from "components/button";

interface Props {
  isVisible: boolean;
  title: string;
  value: string;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handlePreviewBtn: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}

const CustomModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 16px;
  }
  .modalTitle {
    margin: 0 0 24px;
    line-height: 40px;
    letter-spacing: -0.175px;
    color: #25282a;
    font-size: 32px;
    font-weight: 700;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export function ConditionModal({
  isVisible,
  title,
  value,
  setIsVisible,
  handlePreviewBtn,
}: Props) {
  const axios = useAxios();
  const navigate = useNavigate();

  const [listValue, setListValue] = useState<string>("");
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);

  useEffect(() => {
    setListValue(value);
  }, [value]);

  const handleModalCloseClick = () => {
    setIsVisible(false);
  };

  const handleListInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setListValue(e.target.value);
  };

  const handleQuestionSaveBtn = async () => {
    const res = await axios.post("/extraquestion", {
      category: title,
      questionIds: listValue,
    });
    if (res.request.status === 400) {
      setIsAlertVisible(true);
      setTimeout(() => {
        setIsAlertVisible(false);
      }, 3000);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <>
      <ErrorAlert toggle={isAlertVisible} label="질문 idx를 확인해 주세요" />
      <CustomModal
        visible={isVisible}
        width="680px"
        centered={true}
        closable={false}
        footer={null}
        onCancel={handleModalCloseClick}
      >
        <p className="modalTitle">{title}</p>
        <PrimaryInput
          label="관련 질문 리스트"
          value={listValue}
          onChange={handleListInputChange}
        />
        <BtnContainer>
          <PrimaryBtn
            label="취소"
            color="none"
            onClick={handleModalCloseClick}
          />
          <PrimaryBtn
            label="미리보기"
            color="none"
            onClick={handlePreviewBtn}
          />
          <PrimaryBtn label="저장하기" onClick={handleQuestionSaveBtn} />
        </BtnContainer>
      </CustomModal>
    </>
  );
}
