import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
	LoginPage,
	NaverLoginCallback,
	PhonNumLoginPage,
	CounselingPage,
	CounselingDetailPage,
	InterviewManagePage,
	MemberInquiryPage,
	MemberDetailPage,
	PreviewPage,
	TestPage,
	ExtraTestPage,
} from 'pages';
import { PrivateRoute } from './privateRoute';
import { LoginRoute } from './loginRoute';
import AffiliationManagePage from 'pages/affiliationManagePage';
import RecommendationManagePage from 'pages/recommendationManagePage';
import RecommendationCreatePage from 'pages/recommendationCreatePage';
import ConditionCreatePage from 'pages/conditionCreatePage';
import ConditionManagePage from 'pages/conditionManagePage';

export function Router() {
	return (
		<Routes>
			<Route
				path='/'
				element={
					<LoginRoute>
						<LoginPage />
					</LoginRoute>
				}
			/>
			<Route
				path='/login'
				element={
					<LoginRoute>
						<LoginPage />
					</LoginRoute>
				}
			/>
			<Route path='/callback/naverLogin' element={<NaverLoginCallback />} />
			<Route
				path='/login/phonelogin'
				element={
					<LoginRoute>
						<PhonNumLoginPage />
					</LoginRoute>
				}
			/>
			<Route
				path='/counseling'
				element={
					<PrivateRoute>
						<CounselingPage />
					</PrivateRoute>
				}
			/>
			<Route
				path='/counseling/:id'
				element={
					<PrivateRoute>
						<CounselingDetailPage />
					</PrivateRoute>
				}
			/>
			<Route
				path='/affiliation'
				element={
					<PrivateRoute>
						<AffiliationManagePage />
					</PrivateRoute>
				}
			/>
			<Route
				path='/interview'
				element={
					<PrivateRoute>
						<InterviewManagePage />
					</PrivateRoute>
				}
			/>
			<Route
				path='/member'
				element={
					<PrivateRoute>
						<MemberInquiryPage />
					</PrivateRoute>
				}
			/>
			<Route
				path='/member/:id'
				element={
					<PrivateRoute>
						<MemberDetailPage />
					</PrivateRoute>
				}
			/>
			<Route
				path='/recommendation'
				element={
					<PrivateRoute>
						<RecommendationManagePage />
					</PrivateRoute>
				}
			/>
			<Route
				path='/recommendation/:id'
				element={
					<PrivateRoute>
						<RecommendationCreatePage />
					</PrivateRoute>
				}
			/>
			<Route
				path='/recommendation-create'
				element={
					<PrivateRoute>
						<RecommendationCreatePage />
					</PrivateRoute>
				}
			/>
			<Route
				path='/recommendation/:id'
				element={
					<PrivateRoute>
						<RecommendationCreatePage />
					</PrivateRoute>
				}
			/>
			<Route
				path='/condition'
				element={
					<PrivateRoute>
						<ConditionManagePage />
					</PrivateRoute>
				}
			/>
			<Route
				path='/condition/:id'
				element={
					<PrivateRoute>
						<ConditionCreatePage />
					</PrivateRoute>
				}
			/>
			<Route
				path='/condition-create'
				element={
					<PrivateRoute>
						<ConditionCreatePage />
					</PrivateRoute>
				}
			/>
			<Route
				path='/preview'
				element={
					<PrivateRoute>
						<PreviewPage />
					</PrivateRoute>
				}
			/>
			<Route
				path='/test'
				element={
					<PrivateRoute>
						<TestPage />
					</PrivateRoute>
				}
			/>
			<Route
				path='/extra_test'
				element={
					<PrivateRoute>
						<ExtraTestPage />
					</PrivateRoute>
				}
			/>
		</Routes>
	);
}
