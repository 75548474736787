import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import useAxios from 'api/axiosCustom';
import { useNavigate } from 'react-router-dom';
import { PrimaryInput } from 'components/input';
import { PrimaryBtn, TestBtn } from 'components/button';
import { TwoBtnModal, LocaleModal } from 'components/modal';
import { SymptomAddModal } from './symptomAddModal';
import styleConstant from 'data/constant/styleConstant';
import { LoadingModal } from 'components/modal';
import { Table } from 'antd';
import { ISymptom } from 'data/interface/interviewManage';

export function SelectSymptom() {
	const axios = useAxios();
	const navigate = useNavigate();

	const [columnData, setColumnData] = useState<ISymptom[]>([]);
	const [totalCount, setTotalCount] = useState<number>(0);
	const [page, setPage] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [searchDepartment, setSearchDepartment] = useState<string>('');
	const [searchSymptom, setSearchSymptom] = useState<string>('');
	const [searchIndex, setSearchIndex] = useState<string>('');
	const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [deleteId, setDeleteId] = useState<string>('');
	const [isSearch, setIsSearch] = useState<boolean>(false);
	const [symptomEditValue, setSymptomEditValue] = useState<any>({});
	const [isLocaleModalVisible, setIsLocaleModalVisible] = useState<boolean>(false);
	const [selectId, setSelectId] = useState<string>('');

	const listRequest = useCallback(() => {
		(async () => {
			setIsLoading(true);
			if (isSearch) {
				const res = await axios.get(
					`/symptom/admin/list?department=${searchDepartment}&name=${searchSymptom}&questionIds=${searchIndex}&offset=${page}`
				);
				setColumnData(res.data.symptoms);
			} else {
				const res = await axios.get(`/symptom/admin/list?offset=${page}`);
				setColumnData(res.data.symptoms);
			}
			setIsLoading(false);
		})();
	}, [page]);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const res = await axios.get('/symptom/admin/list');

			setColumnData(res.data.symptoms);
			setTotalCount(res.data.totalCount);
			setIsLoading(false);
		})();
	}, []);

	useEffect(() => {
		listRequest();
	}, [page]);

	useEffect(() => {
		if (!isModalVisible) {
			setSymptomEditValue({});
		}
	}, [isModalVisible]);

	function onSymptomEdit(value: any) {
		setSymptomEditValue(value);
		setIsModalVisible(true);
	}

	const handleTestBtn = (id: string) => {
		setIsLocaleModalVisible(true);
		setSelectId(id);
	};

	const columns = [
		{
			title: 'idx.',
			dataIndex: 'id',
			key: 'idx',
			render: (text: any, value: any, index: any) => (
				<p
					className={
						index === 0 || index % 2 === 0 ? 'text idx color' : 'text idx'
					}
					onClick={() => onSymptomEdit(value)}>
					{text}
				</p>
			),
		},
		{
			title: '증상',
			dataIndex: 'name',
			key: 'symptom',
			render: (text: any, value: any, index: any) => (
				<p
					className={
						index === 0 || index % 2 === 0
							? 'text symptom color'
							: 'text symptom'
					}
					onClick={() => onSymptomEdit(value)}>
					{text}
				</p>
			),
		},
		{
			title: '진료과',
			dataIndex: 'department',
			key: 'department',
			render: (text: any, value: any, index: any) => (
				<p
					className={
						index === 0 || index % 2 === 0
							? 'text department color'
							: 'text department'
					}
					onClick={() => onSymptomEdit(value)}>
					{text}
				</p>
			),
		},
		{
			title: '관련 질문 idx.',
			dataIndex: 'questionIds',
			key: 'qeustionIds',
			render: (text: any, value: any, index: any) => (
				<p
					className={
						index === 0 || index % 2 === 0
							? 'text questionIdx color'
							: 'text questionIdx'
					}
					onClick={() => onSymptomEdit(value)}>
					{text}
				</p>
			),
		},
		{
			key: 'delete',
			render: (_: string, value: any, index: number) => (
				<Test
					className={index === 0 || index % 2 === 0 ? 'color' : ''}
					style={{ width: '200px' }}>
					<TestBtn label='테스트하기' onClick={() => handleTestBtn(value.id)} />
					<p
						className='delete text'
						onClick={() => {
							setDeleteId(value.id);
							setIsDeleteModal(true);
						}}>
						삭제
					</p>
				</Test>
			),
		},
	];

	function onDeleteModalCancelBtn() {
		setIsDeleteModal(false);
	}

	async function onDeleteModalCheckBtn() {
		if (deleteId) {
			await axios.delete(`/symptom/${deleteId}`);
			setIsDeleteModal(false);
			listRequest();
		}
	}

	function onModalCancelBtn() {
		setIsModalVisible(false);
	}

	function onDepartmentInput(e: any) {
		setSearchDepartment(e.target.value);
	}

	function onSymptomInput(e: any) {
		setSearchSymptom(e.target.value);
	}

	function onIndexInput(e: any) {
		setSearchIndex(e.target.value);
	}

	function onAddSymptomBtn() {
		setIsModalVisible(true);
	}

	function onPageChange(e: any) {
		setPage(10 * (e - 1));
	}

	async function onSearchBtn() {
		setIsLoading(true);
		setIsSearch(true);
		setPage(0);
		const res = await axios.get(
			`/symptom/admin/list?department=${searchDepartment}&name=${searchSymptom}&questionIds=${searchIndex}`
		);
		setTotalCount(res.data.totalCount);
		setColumnData(res.data.symptoms);
		setIsLoading(false);
	}

	const handleKorean = () => {
		localStorage.setItem('locale', 'ko');
		window.open(`/test?id=${selectId}`, 'test', 'width=400, height=800');
		setIsLocaleModalVisible(false);
	};

	const handleEnglishBtn = () => {
		localStorage.setItem('locale', 'en');
		window.open(`/test?id=${selectId}`, 'test', 'width=400, height=800');
		setIsLocaleModalVisible(false);
	};

	return (
		<Cover>
			<LoadingModal isModalVisible={isLoading} />
			<TwoBtnModal
				isModalVisible={isDeleteModal}
				mainText='정말로 삭제하시겠습니까?'
				leftBtnText='아니요'
				leftBtnClick={onDeleteModalCancelBtn}
				rightBtnText='삭제하기'
				rightBtnColor='red'
				rightBtnClick={onDeleteModalCheckBtn}
			/>
			<SymptomAddModal
				isModalVisible={isModalVisible}
				setIsModalVisible={setIsModalVisible}
				value={symptomEditValue}
				onClick={onModalCancelBtn}
				listRequest={listRequest}
			/>
			<LocaleModal
				isModalVisible={isLocaleModalVisible}
				setIsModalVisible={setIsLocaleModalVisible}
				onEnglishBtn={handleEnglishBtn}
				onKoreanBtn={handleKorean}
			/>
			<SearchContainer>
				<p className='searchMainText'>검색하실 정보를 입력해주세요.</p>
				<InputContainer>
					<div className='input'>
						<PrimaryInput
							label='진료과'
							value={searchDepartment}
							onChange={onDepartmentInput}
						/>
					</div>
					<div className='input'>
						<PrimaryInput
							label='증상'
							value={searchSymptom}
							onChange={onSymptomInput}
						/>
					</div>
					<div className='input'>
						<PrimaryInput
							label='질문 idx.'
							value={searchIndex}
							onChange={onIndexInput}
						/>
					</div>
					<div className='btn'>
						<PrimaryBtn label='검색' onClick={onSearchBtn} />
					</div>
				</InputContainer>
			</SearchContainer>
			<div className='btnContainer'>
				<PrimaryBtn label='새 증상 추가' onClick={onAddSymptomBtn} />
			</div>
			<CustomTable
				columns={columns}
				dataSource={columnData}
				pagination={{
					total: totalCount,
					showSizeChanger: false,
					onChange: onPageChange,
				}}
			/>
		</Cover>
	);
}

const Cover = styled.div`
	margin-top: 48px;
	.btnContainer {
		width: 210px;
		margin: 0 auto 32px;
	}
	.delete {
		color: #e44b55;
		cursor: pointer;
	}
	.text {
		/* text-align: center; */
		padding-left: 17px;
		line-height: 56px;
		height: 56px;
		margin: 0;
		cursor: pointer;
		/* white-space: nowrap; */
		word-break: break-all;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
		white-space: nowrap;
	}
	.color {
		background-color: #f8f8f8;
	}
	.idx {
		width: 74px;
	}
	.symptom {
		width: 150px;
	}
	.department {
		width: 150px;
	}
	.questionIdx {
		width: 450px;
	}
`;

const SearchContainer = styled.div`
	width: 100%;
	height: 188px;
	box-sizing: border-box;
	padding: 24px 24px 32px;
	margin-bottom: 48px;
	border-radius: 20px;
	box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.08);
	.searchMainText {
		font-size: ${styleConstant.FONT_SIZE.large};
		font-weight: bold;
		margin: 0;
	}
`;

const InputContainer = styled.div`
	display: flex;
	gap: 16px;
	margin-top: 12px;
	align-items: center;
	.input {
		width: 33%;
		margin: 0;
		padding: 0;
	}
	.btn {
		width: 120px;
		margin: 0;
		padding-top: 24px;
	}
`;

const CustomTable = styled(Table)`
	.ant-table-thead {
		th {
			padding: 8px 16px;
			border-top: 1px solid #f0f0f0;
			background-color: #fff;
			&::before {
				display: none;
			}
		}
	}
	.ant-table-tbody {
		td {
			padding: 0;
		}
	}
	/* .ant-table-cell-row-hover {
    background-color: #000 !important;
  } */
`;

const Test = styled.div`
	display: flex;
	align-items: center;
	gap: 39px;
`;
