import styleConstant from 'data/constant/styleConstant';
import React from 'react';
import styled from 'styled-components';

export function SearchResultBox({ itemName, item, handleAddItem }: any) {
	return (
		<Result onClick={() => handleAddItem(item)}>
			<p
				style={{
					display: 'inline-block',
					width: '40px',
					height: '10px',
					fontWeight: 500,
					color: styleConstant.COLOR.CTA.DEFAULT,
				}}>
				{item.id}
			</p>
			{itemName}
		</Result>
	);
}

const Result = styled.div`
	background-color: #ffffff;
	padding: 10px 16px;
	line-height: 24px;
	border-top: 1px solid ${styleConstant.COLOR.GRAY_1};
	border-bottom: 1px solid ${styleConstant.COLOR.GRAY_1};
	:hover {
		background: #f4fbfe;
	}
`;
