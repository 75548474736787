import React from 'react';
import styled from 'styled-components';
import { Menu, Dropdown } from 'antd';
import styleConstant from 'data/constant/styleConstant';
import useComponentVisible from 'shared/hook/useComponentVisible';
import { ReactComponent as ArrowDown } from 'assets/icon/arrowDown.svg';
import { ReactComponent as ArrowUp } from 'assets/icon/arrowUp.svg';

interface Props {
	label?: string;
	selectItem: string | undefined;
	items: string[];
	dangerText?: string;
	isBorder?: boolean;
	itemClick: (label: string) => void;
}

export function PrimaryDropDown({
	label,
	selectItem,
	items,
	dangerText,
	itemClick,
	isBorder = true,
}: Props) {
	const menuItem = items.map((text: string, idx: number) => {
		return {
			label: (
				<p
					className={dangerText === text ? 'red' : ''}
					onClick={() => itemClick(text)}>
					{text}
				</p>
			),
			danger: dangerText === text,
			key: idx,
		};
	});

	const menu = <CustomMenu items={menuItem} />;

	return (
		<Cover isBorder={isBorder}>
			<p className='title'>{label}</p>
			<CustomDropdown overlay={menu} trigger={['click']}>
				<DropdownBox danger={selectItem === dangerText} isBorder={isBorder}>
					{selectItem} <ArrowDown />
				</DropdownBox>
			</CustomDropdown>
		</Cover>
	);
}

const Cover = styled.div<{ isBorder: boolean }>`
	position: relative;
	width: 100%;
	margin: 16px 0;
	.title {
		font-size: ${styleConstant.FONT_SIZE.moreSmall};
		line-height: 20px;
		letter-spacing: -0.15px;
		color: #3f4443;
		margin: 0 0 4px;
	}

	.ant-dropdown-open {
		padding: 11px 15px;
		border: ${({ isBorder }) => (isBorder ? '2px solid #27b4eb' : 'none')};
	}
	.ant-dropdown-menu {
		height: 500px !important;
		overflow-y: auto !important;
	}
`;

const CustomDropdown = styled(Dropdown)`
	.ant-dropdown-menu-title-content {
		p {
			margin: 0 !important;
		}
	}
`;

const DropdownBox = styled.div<{ danger: boolean; isBorder: boolean }>`
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: ${({ isBorder }) => (isBorder ? '48px' : '30px')};
	border: ${({ isBorder }) => (isBorder ? '1px solid #bec6c4' : 'none')};
	border-radius: 12px;
	margin: 0;
	padding: 12px 16px;
	background-color: ${({ isBorder }) => (isBorder ? '#fff' : 'none')};
	color: ${({ danger }) => (danger ? '#e44b55' : '#25282a')};
	font-size: ${styleConstant.FONT_SIZE.medium};
	font-weight: 400;
	cursor: pointer;
`;

const CustomMenu = styled(Menu)`
	margin: 8px 0 0;
	border-radius: 12px;
	overflow: hidden;
	p {
		margin: 0;
		padding: 6px 0;
		line-height: 24px;
		letter-spacing: -0.2;
		font-size: ${styleConstant.FONT_SIZE.medium};
	}
	.red {
		color: #e44b55;
	}
	.ant-dropdown-menu-item-active {
		background-color: #27b4eb1a;
		p {
			color: #27b4eb;
			font-weight: 700;
		}
	}
	.ant-dropdown-menu-item-danger.ant-dropdown-menu-item-active {
		background-color: #fce7e7 !important;
		p {
			color: #e44b55 !important;
			font-weight: 700;
		}
	}
`;
