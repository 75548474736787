import { combineReducers } from "@reduxjs/toolkit";

import loginReducer from "./loginReducer";
import counselingReducer from "./counselingReducer";
import chatReducer from "./chatReducer";
import testPreviewReducer from "./testPreviewReducer";

const RootReducer = combineReducers({
  login: loginReducer,
  counseling: counselingReducer,
  chat: chatReducer,
  testPreview: testPreviewReducer,
});

export type RootState = ReturnType<typeof RootReducer>;
export default RootReducer;
