import { PrimaryBtn } from 'components/button';
import styleConstant from 'data/constant/styleConstant';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AskCard } from './Askcard';
import { DeleteBtnInput } from './DeleteBtn';
interface StateProps {
	mainText: string;
	subText: string;
	answerList: string;
	activeList: string;
	inputTypeQuestion: string;
	inputType: string;
	answerType: string;
	inputSelect: string;
	nanBtnLabel: string;
	nanSelect: string;
	s3Img: string;
}
export function Singular(state: StateProps) {
	const [locale, setLocale] = useState(0);
	const [checkAnswer, setCheckAnswer] = useState<string>('');
	const [checkEnAnswer, setCheckEnAnswer] = useState<string>('');
	const toggleLocale = () => {
		locale === 0 ? setLocale(1) : setLocale(0);
	};
	let answers: string[];
	let EnAnswers: string[];
	let activeLists: string[];
	let EnActiveLists: string[];
	if (state.answerList) {
		answers = state.answerList.split('@@')[0].split(';').slice(0, -1);
		EnAnswers = state.answerList.split('@@')[1].split(';').slice(0, -1);
	} else {
		answers = [];
		EnAnswers = [];
	}
	if (state.activeList) {
		activeLists = state.activeList.split('@@')[0].split(';').slice(0, -1);
		EnActiveLists = state.activeList.split('@@')[1].split(';').slice(0, -1);
	} else {
		activeLists = [];
		EnActiveLists = [];
	}

	function onQuestionBtn(e: any, idx: number) {
		setCheckAnswer(answers[idx].trim());
		setCheckEnAnswer(EnAnswers[idx].trim());
	}
	answers.map((text, idx) => {
		text.trim();
		console.log(idx, text, checkAnswer === text.replace(/^\s+|\s+$/gm, ''));
	});
	return (
		<>
			<Cover>
				{/* <p>{state.answerType + "/" + state.inputType}</p> */}
				<p className='mainText'>{state.mainText.split('@@')[locale]}</p>
				<p className='subText'>{state?.subText?.split('@@')[locale]}</p>
				{state.s3Img ? (
					<ImgContainer>
						<img
							src={state.s3Img}
							alt='question image'
							width='380px'
							height='200px'
							// objectFit="contain"
						/>
					</ImgContainer>
				) : null}
				<AnswerContainer isInput={false}>
					{state.nanSelect === '있음' ? (
						<AskCard
							item={state.nanBtnLabel.split('@@')[locale].trim()}
							checked={checkAnswer[0] === state.nanBtnLabel.trim()}
						/>
					) : null}
					{locale === 0
						? answers.map((text, index) =>
								text.trim() ? (
									<AskCard
										key={index}
										item={text.replace(/^\s+|\s+$/gm, '')}
										checked={
											checkAnswer ===
											text.replace(/^\s+|\s+$/gm, '')
										}
										clicked={(event) =>
											onQuestionBtn(event, index)
										}
									/>
								) : null
						  )
						: EnAnswers.map((text, index) =>
								text.trim() ? (
									<AskCard
										key={index}
										item={text.replace(/^\s+|\s+$/gm, '')}
										checked={
											checkEnAnswer ===
											text.replace(/^\s+|\s+$/gm, '')
										}
										clicked={(event) =>
											onQuestionBtn(event, index)
										}
									/>
								) : null
						  )}
				</AnswerContainer>
				{state.inputSelect === '있음' ? (
					activeLists.length === 0 ||
					activeLists.includes(checkAnswer) ||
					EnActiveLists.includes(checkEnAnswer) ? (
						<InputContainer>
							<p>{state.inputTypeQuestion.split('@@')[locale]}</p>
							<DeleteBtnInput
								placeHolder={
									state.inputTypeQuestion.split('@@')[locale]
								}
							/>
						</InputContainer>
					) : null
				) : null}
				<PrimaryBtn onClick={toggleLocale} label={'다른 언어로 보기'} />
			</Cover>
		</>
	);
}

const Cover = styled.div`
	width: 375px;
	margin: auto;
	margin-top: 24px;
	padding-bottom: 32px;
	position: relative;
	.mainText {
		font-size: ${styleConstant.FONT_SIZE.large};
		font-weight: bold;
		margin: 0 0 24px;
	}
`;

const ImgContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	height: auto;
	margin: 0 0 24px;
	img {
		object-fit: scale-down;
	}
`;

const AnswerContainer = styled.div<{ isInput: true | false }>`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin: ${({ isInput }) => (isInput ? '0' : '0 0 32px')};
`;

const InputContainer = styled.div`
	margin: 24px 0 32px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	p {
		font-size: ${styleConstant.FONT_SIZE.medium};
		font-weight: bold;
		margin: 0;
	}
`;
