import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import styleConstant from 'data/constant/styleConstant';
import { LongPressDetectEvents, useLongPress } from 'use-long-press';
import { ReactComponent as CancleBtn } from 'assets/icon/deleteIcon.svg';
import { ReactComponent as DeleteBtn } from 'assets/icon/trashcanIcon.svg';
import { ReactComponent as EditBtn } from 'assets/icon/editIcon.svg';

interface MyChatProps {
	msg: any;
	msgDate: string;
	isRead?: boolean;
	onClickDeleteBtn?: any;
	setIsEdit: any;
	isEdit: any;
	setInputValue: any;
}

export function MyChat({
	onClickDeleteBtn,
	setIsEdit,
	isEdit,
	setInputValue,
	msg,
	msgDate,
	isRead = false,
}: MyChatProps) {
	const isFile = msg.message.includes('{"src":"http');

	const findLink = () => {
		const words = msg.message.split(' ');

		return (
			<>
				{words.map((word: string) =>
					word.includes('http') ? (
						<a
							style={{ textDecoration: 'underline' }}
							href={word}
							target='_blank'
							rel='noopener noreferrer'>
							{word}
						</a>
					) : word.includes('www') ? (
						<a
							style={{ textDecoration: 'underline' }}
							href={`https://${word}`}
							target='_blank'
							rel='noopener noreferrer'>
							{word}
						</a>
					) : (
						<span>{word + ' '}</span>
					)
				)}
			</>
		);
	};

	const findFile = () => {
		if (isFile) {
			const file = JSON.parse(msg.message);
			const imgRegex = /image/;

			return (
				<>
					<div className='priviewItem'>
						{imgRegex.test(file?.type) && (
							<img
								src={file?.src}
								alt='uploaded'
								onClick={() => window.open(file?.src)}
							/>
						)}
						<span
							className='fileName'
							onClick={() => window.open(file?.src)}>
							{file?.name}
						</span>
					</div>
				</>
			);
		}
	};

	const [isPressed, setIsPressed] = useState<any>(false);

	// const bind = useLongPress(
	// 	() => {
	// 		if (isEdit === null) {
	// 			setIsPressed(true);
	// 			setIsEdit(msg);
	// 			setInputValue(msg.message);
	// 		}
	// 	},
	// 	{
	// 		onCancel: () => {
	// 			setIsPressed(false);
	// 			setIsEdit(null);
	// 			setInputValue('');
	// 		},
	// 		threshold: 700,
	// 		captureEvent: true,
	// 		cancelOnMovement: false,
	// 		detect: LongPressDetectEvents.BOTH,
	// 	}
	// );

	useEffect(() => {
		isEdit === null && setIsPressed(false);
	}, [isEdit]);

	const onClick = (msgMid: string) => {
		setIsPressed('delete');
		setTimeout(() => {
			onClickDeleteBtn(msgMid);
			setIsPressed(false);
			setIsEdit(null);
		}, 300);
	};

	const onClickEditBtn = () => {
		if (isEdit === null) {
			setIsPressed(true);
			setIsEdit(msg);
			setInputValue(msg.message);
		}
	};

	const onClickCancleBtn = () => {
		setIsPressed(false);
		setIsEdit(null);
		setInputValue('');
	};

	const [isHover, setIsHover] = useState(false);
	const onMouseEnter = () => {
		setIsHover(true);
	};
	const onMouseLeave = () => {
		setIsHover(false);
	};

	return (
		<Cover isRead={isRead} isPressed={isPressed} isHover={isHover}>
			<div
				// {...bind()}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}>
				<div className='btnContainer'>
					<div className='btnBox'>
						<div className='editBtn' onClick={onClickEditBtn}>
							<EditBtn />
						</div>
						<div className='deleteBtn' onClick={() => onClick(msg.mid)}>
							<DeleteBtn />
						</div>
					</div>
				</div>
				<div className='cancleBtn' onClick={onClickCancleBtn}>
					<CancleBtn />
				</div>
				{!isFile && <p className='msg'>{msg.message && findLink()}</p>}
				<div className='file'>{msg.message && findFile()}</div>
				<p className='date'>
					{msgDate} <span>읽지않음</span>
				</p>
			</div>
		</Cover>
	);
}

const Cover = styled.div<{
	isRead: true | false;
	isPressed: true | false | 'delete';
	isHover: true | false;
}>`
	@keyframes vibe {
		0% {
			transform: rotateZ(1.5deg) scale(1.02);
		}
		50% {
			transform: rotateZ(-1.5deg) scale(1.02);
		}
		100% {
			transform: rotateZ(1.5deg) scale(1.02);
		}
	}
	@keyframes delete {
		0% {
			transform: translateX(0px);
		}
		100% {
			transform: translateX(150px);
			opacity: 0;
		}
	}
	@keyframes in {
		0% {
			opacity: 0%;
		}
		100% {
			opacity: 70%;
		}
	}
	@keyframes out {
		100% {
			opacity: 0%;
		}
	}
	width: 100%;
	display: flex;
	justify-content: flex-end;
	div {
		margin: 0 10px 20px 0 !important;
		cursor: pointer;
		position: relative;
		max-width: 80%;
		padding: 16px;
		background-color: #e8f8ff;
		border-radius: 20px 0 20px 20px;
		box-shadow: ${({ isPressed }) =>
			isPressed === true ? '0 4px 16px 0 rgb(0 0 0 / 8%)' : ''};
		animation-name: ${({ isPressed }) =>
			isPressed === true ? 'vibe' : isPressed === 'delete' ? 'delete' : ''};
		animation-duration: 0.3s;
		animation-iteration-count: infinite;
		z-index: ${({ isPressed }) => (isPressed === true ? '101' : '')};
	}
	.btnContainer {
		position: absolute;
		right: -23px;
		top: -10px;
		width: 100px;
		height: 20px;
		background-color: transparent;
		border: none;
		border-radius: 0;
		margin: 0 !important;
		padding: 0;
		box-shadow: none;
		display: ${({ isHover, isPressed }) => (isPressed ? 'none' : isHover ? '' : 'none')};
		animation-duration: 0.3s;
		animation-iteration-count: 1;
		pointer-events: ${({ isHover, isPressed }) =>
			isHover ? (isPressed ? 'none' : '') : 'none'};
		animation-name: ${({ isHover }) => (isHover ? 'in' : 'out')};
		animation-fill-mode: forwards;
		opacity: ${({ isPressed }) => (isPressed ? '0' : '1')};
	}
	.btnBox {
		margin: 0 !important;
		padding: 0;
		width: 100px;
		height: 20px;
		background-color: transparent;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		border-radius: 0;
		box-shadow: none;
	}
	.editBtn {
		height: 23px;
		width: 23px;
		border-radius: 20px;
		background-color: #27b4eb;
		margin: 0 3px !important;
		padding: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
	}
	.deleteBtn {
		height: 23px;
		width: 23px;
		border-radius: 20px;
		background-color: #e44b55;
		margin: 0 3px !important;
		padding: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
	}
	.cancleBtn {
		height: 30px;
		width: 30px;
		position: absolute;
		top: -5px;
		right: -27px;
		padding: 0;
		border-radius: none;
		background-color: transparent;
		box-shadow: none;
		display: ${({ isPressed }) => (isPressed ? 'block' : 'none')};
	}
	.msg {
		font-size: ${styleConstant.FONT_SIZE.small};
		margin-bottom: 8px;
		white-space: pre-wrap;
		word-break: break-all;
	}
	.date {
		font-size: ${styleConstant.FONT_SIZE.moreSmall};
		color: #969696;
		margin: 0;
		span {
			display: ${({ isRead }) => (isRead ? '' : 'none')};
			margin: 0 0 0 8px;
			font-size: ${styleConstant.FONT_SIZE.moreSmall};
			font-weight: bold;
			color: #1bbcff;
		}
	}

	.file {
		margin: 0 !important;
		padding: 0 !important;
		display: flex;
	}

	.priviewItem {
		display: flex;
		flex-direction: column;
		justify-content: right;
		align-items: right;
		width: 100%;
		margin: 0;
		padding: 0;

		img {
			height: auto;
			width: 200px;
			padding: 10px;
			margin: 0 0 5px;
			background-color: ${styleConstant.COLOR.GRAY_2};
			border-radius: 10px;
			display: flex;
		}
		.fileName {
			width: min-content;
			white-space: nowrap;
			font-size: 16px;
			margin: 0px;
			padding: 4px 13px;
			border-radius: 15px;
			background-color: ${styleConstant.COLOR.GRAY_2};
			font-weight: 500;
			display: flex;
			justify-content: right;
			align-items: right;
		}
	}
`;
