import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import useAxios from 'api/axiosCustom';
import { HeaderBar } from 'components/header';
import { PreDiagnosisCard } from 'components/card';
import { SelectSymptom, SelectChat } from 'components/detail';
import styleConstant from 'data/constant/styleConstant';
import menuConstant from 'data/constant/menuConstant';
import { useDispatch, useSelector } from 'react-redux';
import {
	addAllCounselingList,
	addAllChatList,
	selectCard,
	selectChat,
	selectChatRoom,
	selectDirectChat,
} from 'store/reducer/chatReducer';
import { RootState } from 'store/reducer/rootReducer';
import moment from 'moment';

export default function CounselingDetailPage() {
	const location = useLocation();
	const axios = useAxios();
	const dispatch = useDispatch();

	const [selectMenu, setSelectMenu] = useState<string>('증상보기');
	const [selectCounselingId, setSelectCounselingId] = useState<string>(location.state.id);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const counselingList = useSelector((state: RootState) => state.chat.allCounselingList);
	const chatList = useSelector((state: RootState) => state.chat.allChatList);
	const selectChatInfo = useSelector((state: RootState) => state.chat.selectChat);
	const [selectChatRoomName, setSelectChatRoomName] = useState<string>('직접 상담하기');
	const [isDirectChat, setIsDirectChat] = useState<boolean>(false);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const userCounselingId: any = { userCounselingId: location.state.id };
			const counselingRes = await axios.get(
				`/counseling/admin?profileId=${location.state.profileId}`
			);
			dispatch(addAllCounselingList(counselingRes.data.reverse()));
			const chatRes = await axios.get(
				`/counseling/admin/chat?profileId=${location.state.profileId}`,
				userCounselingId
			);
			const nowChatList = counselingRes.data?.filter(
				(element: any) => element.id === location.state.id
			);

			// dispatch(addAllChatList(chatRes.data));
			dispatch(addAllChatList(nowChatList[0].Chats));
			setIsLoading(false);
		})();
	}, []);

	useEffect(() => {
		dispatch(selectCard(location.state.id));
	}, [counselingList]);

	useEffect(() => {
		let adminChatId: any = 0;
		chatList.map((chat) => {
			if (chat.realtimeDoctorId === chat.realtimeManagerId) {
				adminChatId = chat.id;
			}
		});

		if (adminChatId !== 0) {
			dispatch(selectChatRoom(adminChatId));
			setSelectChatRoomName(adminChatId);
			setIsDirectChat(true);
		} else {
			setIsDirectChat(false);
			setSelectChatRoomName('직접 상담하기');
			dispatch(selectDirectChat());
		}
		// dispatch(selectChat(location.state.id));
	}, [chatList]);

	useEffect(() => {
		if (selectChatInfo?.id) {
			counselingList.map((element: any) =>
				// selectCounselingId === element.id
				selectChatInfo?.id === element.id
					? element.Chats.map((chat: any) => {
							if (chat.realtimeDoctorId === chat.realtimeManagerId) {
								setIsDirectChat(true);

								setSelectChatRoomName(chat.id);
								dispatch(selectChatRoom(chat.id));
							}
					  })
					: null
			);
		}
	}, [selectChatInfo]);

	function onMenuBtn(e: any) {
		setSelectMenu(e.target.innerText);
	}

	function onCounselingCard(chat: any) {
		setSelectCounselingId(chat.id);
		dispatch(addAllChatList(chat.Chats));
		dispatch(selectCard(chat.id));
		dispatch(selectChat(chat.id));
	}

	function onChatRooms(chat: any) {
		setSelectChatRoomName(chat.id);
		dispatch(selectChatRoom(chat.id));
	}

	function onDirectBtn(e: any) {
		setSelectChatRoomName('직접 상담하기');
		dispatch(selectDirectChat());
	}

	return (
		<Cover>
			<HeaderBar nowPath={menuConstant.COUNSELING} />
			<ContentsContainer>
				<SymptomContainer>
					<p className='mainText'>{counselingList[0].Profile?.name}</p>
					<MenuContainer>
						<p
							className={selectMenu === '증상보기' ? 'select' : ''}
							onClick={onMenuBtn}>
							증상보기
						</p>
						<p
							className={selectMenu === '상담하기' ? 'select' : ''}
							onClick={onMenuBtn}>
							상담하기
						</p>
					</MenuContainer>
					{selectMenu === '증상보기' ? (
						<SelectSymptom />
					) : (
						<>
							<ChatRoomsContainer>
								{counselingList.map((element: any) =>
									selectCounselingId === element.id
										? element.Chats.map((chat: any) =>
												chat.DoctorId ===
												chat.ManagerId ? (
													<div
														onClick={() =>
															onChatRooms(
																chat
															)
														}
														className={
															selectChatRoomName ===
															chat.id
																? 'selectRoom'
																: ''
														}>
														직접 상담하기
													</div>
												) : (
													<div
														onClick={() =>
															onChatRooms(
																chat
															)
														}
														className={
															selectChatRoomName ===
															chat.id
																? 'selectRoom'
																: ''
														}>
														{
															chat
																.Doctor
																.name
														}
													</div>
												)
										  )
										: null
								)}
								{isDirectChat ? null : (
									<div
										onClick={onDirectBtn}
										className={
											selectChatRoomName ===
											'직접 상담하기'
												? 'selectRoom'
												: ''
										}>
										직접 상담하기
									</div>
								)}
							</ChatRoomsContainer>
							<SelectChat chat={selectChatInfo} />
						</>
					)}
				</SymptomContainer>
				<CounselingContainer>
					<p className='listText'>
						{counselingList[0].Profile?.name} 님의 문진
					</p>
					{counselingList?.map((element: any) => (
						<PreDiagnosisCard
							isSelect={selectCounselingId === element.id}
							onClick={() => onCounselingCard(element)}
							name={element.Profile?.name}
							hospitalName={element.hospital?.hospitalName}
							gender={element.Profile?.gender}
							birth={`${element.Profile?.birthDate.slice(0, 4)}년생`}
							date={moment(element.createdAt).format('MM월 DD일 HH:mm')}
							state={element.status}
							department={element.department}
							symptom={element.symptom}
							mainText={element.additionalSymptom}
						/>
					))}
				</CounselingContainer>
			</ContentsContainer>
		</Cover>
	);
}

const Cover = styled.div`
	width: 1024px;
	margin: 0 auto;
	padding: 0;
`;

const ContentsContainer = styled.div`
	display: flex;
	margin-top: 42px;
	gap: 20px;
`;

const SymptomContainer = styled.div`
	position: relative;
	.mainText {
		position: absolute;
		top: -96px;
		left: 52px;
		margin: 0;
		font-size: 40px;
		font-weight: bold;
	}
`;

const MenuContainer = styled.div`
	display: flex;
	gap: 16px;
	margin-bottom: 48px;
	p {
		font-size: ${styleConstant.FONT_SIZE.medium};
		font-weight: normal;
		margin: 0;
		cursor: pointer;
	}
	.select {
		margin: 0;
		padding-bottom: 6px;
		border-bottom: solid 2px #1bbcff;
		font-weight: bold;
	}
`;

const CounselingContainer = styled.div`
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 16px;
	position: relative;
	.listText {
		font-size: ${styleConstant.FONT_SIZE.medium};
		letter-spacing: -0.2px;
		line-height: 1.5;
		margin: 0;
		font-weight: bold;
	}
`;

const ChatRoomsContainer = styled.div`
	display: flex;
	gap: 8px;
	margin: 0 0 24px;
	width: 670px;
	overflow: auto;
	&:hover {
		&::-webkit-scrollbar {
			display: block;
		}
	}
	&::-webkit-scrollbar {
		height: 5px;
		display: none;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #cfcfcf;
		border-radius: 10px;
	}
	&::-webkit-scrollbar-track {
		background-color: #fff;
	}
	div {
		border-radius: 16px;
		border: solid 1px #cfcfcf;
		padding: 4px 16px;
		cursor: pointer;
		height: 32px;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.selectRoom {
		border: solid 1px #1bbcff;
	}
`;
