import { PrimaryBtn } from 'components/button';
import styleConstant from 'data/constant/styleConstant';
import React, { useState } from 'react';
import styled from 'styled-components';
import { NumberInput } from './NumInput';
interface StateProps {
	mainText: string;
	subText: string;
	inputUnit: string;
	answerType: string;
	inputType: string;
	s3Img: string;
}
export function InputNum(state: StateProps) {
	const [locale, setLocale] = useState(0);
	const toggleLocale = () => {
		locale === 0 ? setLocale(1) : setLocale(0);
	};
	return (
		<>
			<Cover>
				{/* <p>{state.answerType + "/" + state.inputType}</p> */}
				<p className='mainText'>{state.mainText.split('@@')[locale]}</p>
				<p className='subText'>{state?.subText?.split('@@')[locale]}</p>
				{state.s3Img ? (
					<ImgContainer>
						<img
							src={state.s3Img}
							alt='question image'
							width='380px'
							height='200x'
							// objectFit="contain"
						/>
					</ImgContainer>
				) : null}
				<AnswerContainer isInput={false}>
					<NumberInput label={state.inputUnit.split('@@')[locale]} />
				</AnswerContainer>
				<PrimaryBtn onClick={toggleLocale} label={'다른 언어로 보기'} />
			</Cover>
		</>
	);
}

const Cover = styled.div`
	width: 375px;
	margin: auto;
	margin-top: 24px;
	padding-bottom: 32px;
	position: relative;
	.mainText {
		font-size: ${styleConstant.FONT_SIZE.large};
		font-weight: bold;
		margin: 0 0 24px;
	}
`;

const ImgContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	height: auto;
	margin: 0 0 24px;
	img {
		object-fit: scale-down;
	}
`;

const AnswerContainer = styled.div<{ isInput: true | false }>`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin: ${({ isInput }) => (isInput ? '0' : '0 0 32px')};
`;

const InputContainer = styled.div`
	/* margin: 24px 0 32px; */
	display: flex;
	flex-direction: column;
	gap: 16px;
	p {
		font-size: ${styleConstant.FONT_SIZE.medium};
		font-weight: bold;
		margin: 0;
	}
`;
