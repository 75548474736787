import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import useAxios from 'api/axiosCustom';
import { useNavigate } from 'react-router-dom';
import { PrimaryInput } from 'components/input';
import { PrimaryBtn, TestBtn } from 'components/button';
import { TwoBtnModal, LocaleModal } from 'components/modal';

import styleConstant from 'data/constant/styleConstant';
import { LoadingModal } from 'components/modal';
import { Table } from 'antd';
import { ITeam } from 'data/interface/interviewManage';
import { TeamAddModal } from './teamAddModal';
import { MemberAddModal } from './memberAddModal';
import { ErrorAlert } from 'components/alert';

export function SelectTeam() {
	const axios = useAxios();

	const [columnData, setColumnData] = useState<ITeam[]>([]);
	const [totalCount, setTotalCount] = useState<number>(0);
	const [page, setPage] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [searchTeam, setSearchTeam] = useState<string>('');
	const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [deleteId, setDeleteId] = useState<string>('');
	const [isSearch, setIsSearch] = useState<boolean>(false);
	const [teamEditValue, setTeamEditValue] = useState<any>({});
	const [isMemberAddModalVisible, setIsMemberAddModalVisible] = useState<boolean>(false);
	const [url, setUrl] = useState<string>('');
	const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);

	const listRequest = useCallback(() => {
		(async () => {
			setIsLoading(true);
			if (isSearch) {
				const res = await axios.get(`/hospital/inquireAdmin?search=${searchTeam}`);

				setColumnData(res.data);
			} else {
				const res = await axios.get(`hospital/inquireAdmin?search=`);
				setColumnData(res.data);
			}
			setIsLoading(false);
		})();
	}, [page]);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const res = await axios.get('hospital/inquireAdmin?search=');
			setColumnData(res.data);
			setTotalCount(res.data.length);
			setIsLoading(false);
		})();
	}, []);

	useEffect(() => {
		listRequest();
	}, [page]);

	useEffect(() => {
		if (!isModalVisible) {
			setTeamEditValue({});
		}
	}, [isModalVisible]);

	function onSymptomEdit(value: any) {
		setTeamEditValue(value);
		setIsModalVisible(true);
	}

	const handleAddMemberBtn = (value: any) => {
		setIsMemberAddModalVisible(true);
		setTeamEditValue(value);
	};

	const handleCopyLink = (pathName: string) => {
		setUrl(`https://app.knowingdoctor.com/${pathName}`);

		const t = document.createElement('textarea');
		document.body.appendChild(t);
		t.value = `https://app.knowingdoctor.com/${pathName}`;
		t.select();
		document.execCommand('copy');
		document.body.removeChild(t);

		setIsAlertOpen(true);
		setTimeout(() => {
			setIsAlertOpen(false);
		}, 2000);
	};

	const columns = [
		{
			title: 'idx.',
			dataIndex: 'id',
			key: 'idx',
			render: (text: any, value: any, index: any) => (
				<p
					className={
						index === 0 || index % 2 === 0 ? 'text idx color' : 'text idx'
					}
					onClick={() => onSymptomEdit(value)}>
					{text}
				</p>
			),
		},
		{
			title: '팀명',
			dataIndex: 'hospitalName',
			key: 'hospitalName',
			render: (text: any, value: any, index: any) => (
				<p
					className={
						index === 0 || index % 2 === 0
							? 'text symptom color'
							: 'text symptom'
					}
					onClick={() => onSymptomEdit(value)}>
					{text}
				</p>
			),
		},
		{
			title: '경로',
			dataIndex: 'pathName',
			key: 'pathName',
			render: (text: any, value: any, index: any) => (
				<p
					className={
						index === 0 || index % 2 === 0
							? 'text symptom color'
							: 'text symptom'
					}
					onClick={() => onSymptomEdit(value)}>
					{text}
				</p>
			),
		},
		{
			title: '진료과',
			dataIndex: 'HospitalWithDepartments',
			key: 'departmentId',
			render: (text: any, value: any, index: any) => (
				<p
					className={
						index === 0 || index % 2 === 0
							? 'text questionIdx color'
							: 'text questionIdx'
					}
					onClick={() => onSymptomEdit(value)}>
					{value.HospitalWithDepartments?.map(
						(dep: any) => dep?.departmentId
					).join(', ')}
				</p>
			),
		},

		{
			key: 'delete',
			render: (_: string, value: any, index: number) => (
				<Test
					className={index === 0 || index % 2 === 0 ? 'color' : ''}
					style={{ width: '300px' }}>
					<TestBtn
						label='링크 복사'
						onClick={() => handleCopyLink(value?.pathName)}
					/>
					<TestBtn
						label='팀원 추가'
						onClick={() => handleAddMemberBtn(value)}
					/>
					<p
						className='delete text'
						onClick={() => {
							setDeleteId(value.id);
							setIsDeleteModal(true);
						}}>
						삭제
					</p>
				</Test>
			),
		},
	];

	function onDeleteModalCancelBtn() {
		setIsDeleteModal(false);
	}

	async function onDeleteModalCheckBtn() {
		if (deleteId) {
			await axios.delete(`/admin/affiliation/team/${deleteId}`);
			setIsDeleteModal(false);
			listRequest();
		}
	}

	function onModalCancelBtn() {
		setIsModalVisible(false);
	}

	function onTeamInput(e: any) {
		setSearchTeam(e.target.value);
	}

	function onAddTeamBtn() {
		setIsModalVisible(true);
	}

	function onPageChange(e: any) {
		setPage(10 * (e - 1));
	}

	async function onSearchBtn() {
		setIsLoading(true);
		setIsSearch(true);
		setPage(0);

		if (searchTeam !== '') {
			const res = await axios.get(`/hospital/inquireAdmin?search=${searchTeam}`);
			setTotalCount(res.data?.length);
			setColumnData(res.data);
		} else {
			// const res = await axios.get(`/hospital/inquireAdmin?search=`);
			// setTotalCount(res.data?.length);
			// setColumnData(res.data);
			window.location.reload();
		}
		setIsLoading(false);
	}

	return (
		<Cover>
			<ErrorAlert
				label={`${url} 이 복사되었습니다.`}
				toggle={isAlertOpen}
				color='blue'
			/>
			<LoadingModal isModalVisible={isLoading} />
			<TwoBtnModal
				isModalVisible={isDeleteModal}
				mainText='정말로 삭제하시겠습니까?'
				leftBtnText='아니요'
				leftBtnClick={onDeleteModalCancelBtn}
				rightBtnText='삭제하기'
				rightBtnColor='red'
				rightBtnClick={onDeleteModalCheckBtn}
			/>
			<TeamAddModal
				isModalVisible={isModalVisible}
				setIsModalVisible={setIsModalVisible}
				value={teamEditValue}
				onClick={onModalCancelBtn}
				listRequest={listRequest}
			/>
			<MemberAddModal
				isModalVisible={isMemberAddModalVisible}
				setIsModalVisible={setIsMemberAddModalVisible}
				onClick={() => setIsMemberAddModalVisible(false)}
				value={teamEditValue}
				listRequest={listRequest}
			/>
			<SearchContainer>
				<p className='searchMainText'>검색하실 정보를 입력해주세요.</p>
				<InputContainer>
					<div className='input'>
						<PrimaryInput
							label='팀명'
							value={searchTeam}
							onChange={onTeamInput}
						/>
					</div>

					<div className='btn'>
						<PrimaryBtn label='검색' onClick={onSearchBtn} />
					</div>
				</InputContainer>
			</SearchContainer>
			<div className='btnContainer'>
				<PrimaryBtn label='팀 추가' onClick={onAddTeamBtn} />
			</div>
			<CustomTable
				columns={columns}
				dataSource={columnData}
				pagination={{
					total: totalCount,
					showSizeChanger: false,
					onChange: onPageChange,
				}}
			/>
		</Cover>
	);
}

const Cover = styled.div`
	margin-top: 48px;
	.btnContainer {
		width: 210px;
		margin: 0 auto 32px;
	}
	.delete {
		color: #e44b55;
		cursor: pointer;
	}
	.text {
		/* text-align: center; */
		padding-left: 17px;
		line-height: 56px;
		height: 56px;
		margin: 0;
		cursor: pointer;
		/* white-space: nowrap; */
		word-break: break-all;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
		white-space: nowrap;
	}
	.color {
		background-color: #f8f8f8;
	}
	.idx {
		width: 74px;
	}
	.symptom {
		width: 150px;
	}
	.questionIdx {
		width: 350px;
	}
`;

const SearchContainer = styled.div`
	width: 100%;
	height: 188px;
	box-sizing: border-box;
	padding: 24px 24px 32px;
	margin-bottom: 48px;
	border-radius: 20px;
	box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.08);
	.searchMainText {
		font-size: ${styleConstant.FONT_SIZE.large};
		font-weight: bold;
		margin: 0;
	}
`;

const InputContainer = styled.div`
	display: flex;
	gap: 16px;
	margin-top: 12px;
	align-items: center;
	.input {
		width: 100%;
		margin: 0;
		padding: 0;
	}
	.btn {
		width: 120px;
		margin: 0;
		padding-top: 24px;
	}
`;

// const CostomPrimaryInput = styled(PrimaryInput)`
// 	width: 400px !important;
// 	height: 100px !important;
// `;

const CustomTable = styled(Table)`
	.ant-table-thead {
		th {
			padding: 8px 16px;
			border-top: 1px solid #f0f0f0;
			background-color: #fff;
			&::before {
				display: none;
			}
		}
	}
	.ant-table-tbody {
		td {
			padding: 0;
		}
	}
	/* .ant-table-cell-row-hover {
    background-color: #000 !important;
  } */
`;

const Test = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
`;
