import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import styleConstant from "data/constant/styleConstant";

interface DeleteBtnInputProps {
  type?: string;
  label?: string;
  icon?: ReactElement<any, any>;
  ref?: any;
  placeHolder?: string;
}

export function DeleteBtnInput({
  type = "text",
  label = "",
  icon,
  ref,
  placeHolder,
}: DeleteBtnInputProps) {
  const [handleFocus, setHandleFocus] = useState<boolean>(false);

  function onInputFocus() {
    setHandleFocus(!handleFocus);
  }

  return (
    <Cover>
      {label !== "" ? <p>{label}</p> : null}
      <InputContainer focus={handleFocus}>
        <Primary
          type={type}
          ref={ref}
          onFocus={onInputFocus}
          onBlur={onInputFocus}
          placeholder={placeHolder}
        />
      </InputContainer>
    </Cover>
  );
}

const Cover = styled.div`
  width: 100%;
  p {
    text-align: start;
    font-size: ${styleConstant.FONT_SIZE.moreSmall};
    color: #969696;
    margin: 0 0 4px 0;
  }
  margin: 0;
`;

const InputContainer = styled.div<{ focus: true | false }>`
  width: 100%;
  height: 48px;
  padding: 12px 16px;
  border: ${({ focus }) => (focus ? "solid 2px #1bbcff" : "solid 1px #cfcfcf")};
  border-radius: 16px;
  margin: 0;
  background-color: #fff;
  display: flex;

  span {
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 13px;
    margin: auto 0;
    cursor: pointer;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-overflow-scrolling: touch) {
      padding: 10px 16px;
    }
  }
  :hover {
    border: 1px solid #27b4eb;
  }
  :focus-within {
    border: solid 1px #1bbcff;
    box-shadow: 0 0 0 1px #1bbcff;
  }
`;

const Primary = styled.input`
  width: 100%;
  border: none;
  font-size: ${styleConstant.FONT_SIZE.medium};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      padding: 0;
      position: relative;
      top: -3px;
      height: 24px;
    }
    @supports (-webkit-overflow-scrolling: touch) {
      padding: 0;
      position: relative;
      top: -1px;
    }
  }
`;
