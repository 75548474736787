import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import useAxios from 'api/axiosCustom';
import { HeaderBar } from 'components/header';
import { PreDiagnosisCard } from 'components/card';
import { SelectSymptom, SelectChat } from 'components/detail';
import styleConstant from 'data/constant/styleConstant';
import menuConstant from 'data/constant/menuConstant';
import { useDispatch, useSelector } from 'react-redux';
import {
	addAllCounselingList,
	addAllChatList,
	selectCard,
	selectChat,
	selectChatRoom,
	selectDirectChat,
} from 'store/reducer/chatReducer';
import { RootState } from 'store/reducer/rootReducer';
import moment from 'moment';
import { RecommendationInfo } from 'components/detail/recommendationInfo';

export default function RecommendationCreatePage() {
	const location = useLocation();
	return (
		<Cover>
			<HeaderBar nowPath={menuConstant.RECOMMENDATION_MANAGE} />
			<ContentsContainer>
				<RecommendationContainer>
					<p className='mainText'>
						{location.state ? '추천 수정하기' : '새로운 추천 만들기'}
					</p>
					<RecommendationInfo />
				</RecommendationContainer>
			</ContentsContainer>
		</Cover>
	);
}

const Cover = styled.div`
	width: 1024px;
	margin: 0 auto;
	padding: 0;
`;

const ContentsContainer = styled.div`
	display: flex;
	margin-top: 42px;
	gap: 20px;
`;

const RecommendationContainer = styled.div`
	width: 100%;
	position: relative;
	.mainText {
		position: absolute;
		/* width: 350px; */
		top: -96px;
		left: 52px;
		margin: 0;
		font-size: 40px;
		font-weight: bold;
	}
`;
