import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import styleConstant from 'data/constant/styleConstant';

interface PrimaryInputProps {
	type?: string;
	label?: string;
	icon?: ReactElement<any, any>;
	value: any;
	placeHolder?: string;
	close?: boolean;
	isCheck?: boolean;
	innerLabel?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function PrimaryInput({
	type = 'text',
	label = '',
	icon,
	value,
	placeHolder,
	close = false,
	isCheck = true,
	innerLabel,
	onChange,
}: PrimaryInputProps) {
	const [handleFocus, setHandleFocus] = useState<boolean>(false);

	function onInputFocus(e: any) {
		setHandleFocus(!handleFocus);
	}

	return (
		<Cover>
			{label !== '' ? <p>{label}</p> : null}
			<InputContainer focus={handleFocus} close={close} check={isCheck}>
				<span>{icon}</span>
				<Primary
					type={type}
					value={value}
					onChange={onChange}
					onFocus={onInputFocus}
					onBlur={onInputFocus}
					placeholder={placeHolder}
					close={close}
				/>
				{innerLabel ? <p className='innerText'>{innerLabel}</p> : null}
			</InputContainer>
		</Cover>
	);
}

const Cover = styled.div`
	width: 100%;
	p {
		text-align: start;
		font-size: ${styleConstant.FONT_SIZE.moreSmall};
		line-height: 20px;
		font-weight: 500;
		letter-spacing: -0.15px;
		color: #3f4443;
		margin: 0 0 4px;
	}
	margin: 16px 0;
`;

const InputContainer = styled.div<{
	focus: true | false;
	close: true | false;
	check: true | false;
}>`
	box-sizing: border-box;
	width: 100%;
	height: 48px;
	padding: 6px 16px;
	border: ${({ focus, check }) =>
		focus ? 'solid 1px #1bbcff' : check ? 'solid 1px #cfcfcf' : 'solid 1px #ffbf3f'};
	border-radius: 12px;
	margin: 0;
	background-color: ${({ close }) => (close ? '#cfcfcf' : '#fff')};
	display: flex;
	line-height: 30px;
	.innerText {
		line-height: 34px;
		font-size: ${styleConstant.FONT_SIZE.small};
	}
`;

const Primary = styled.input<{ close: true | false }>`
	/* display: ${({ close }) => (close ? 'none' : '')}; */
	pointer-events: ${({ close }) => (close ? 'none' : '')};
	width: 100%;
	/* margin-left: 10px; */
	border: none;
	font-size: ${styleConstant.FONT_SIZE.medium};
	background-color: ${({ close }) => (close ? '#cfcfcf' : '')};
	opacity: ${({ close }) => (close ? '0.5' : '')};
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&:focus {
		outline: none;
	}
	@media not all and (min-resolution: 0.001dpcm) {
		@supports (-webkit-appearance: none) {
			padding: 0;
		}
		@supports (-webkit-overflow-scrolling: touch) {
			padding: 0;
		}
	}
`;
