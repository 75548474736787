import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useAxios from 'api/axiosCustom';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { ErrorAlert } from 'components/alert';
import { PrimaryInput, SearchInput } from 'components/input';
import { PrimaryBtn } from 'components/button';
import { PrimaryDropDown } from 'components/dropDown';
import styleConstant from 'data/constant/styleConstant';
import { ReactComponent as SearchIcon } from 'assets/icon/interview/searchIcon.svg';
import { useDebounce } from 'hooks/useDebounce';
import { IDoctor } from 'data/interface/member';

interface MemberAddModalProps {
	isModalVisible: boolean;
	setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	value?: any;
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
	listRequest: () => void;
}

export function MemberAddModal({
	isModalVisible,
	setIsModalVisible,
	value = {},
	onClick,
	listRequest,
}: MemberAddModalProps) {
	const axios = useAxios();
	const [isCheck, setIsCheck] = useState<boolean>(false);
	const [isTeamIdxCheck, setIsTeamidxCheck] = useState<boolean>(false);
	const [inputText, setInputText] = useState<string>('');
	const [searchData, setSearchData] = useState<any>([]);
	const [memberList, setMemberList] = useState<any[]>([]);
	const [memberIdList, setMemberIdList] = useState<any>([]);
	const [deleteMemberIdList, setDeleteMemberIdList] = useState<any>([]);

	useEffect(() => {
		(async () => {
			console.log('value', value);
			const partnerIdArr = value?.HospitalWithPartners?.map(
				(partner: any) => partner.id
			);

			setMemberList(value?.HospitalWithPartners);
			setMemberIdList(partnerIdArr);
		})();
	}, [isModalVisible]);

	async function onSaveBtn() {
		if (value) {
			console.log('	aa', {
				teamId: value.id.toString(),
				doctorId: memberIdList,
				deleteMemberIdList,
			});

			if (deleteMemberIdList) {
				const dres = await axios.delete(`/hospital/delete`, {
					data: { teamId: value.id.toString(), doctorId: deleteMemberIdList },
				});
			}

			const res = await axios.post(`/hospital/resistSeparatelyDoctor`, {
				teamId: value.id.toString(),
				doctorId: memberIdList,
			});

			if (res.status === 200) {
				setIsModalVisible(false);
				listRequest();
				//window.location.reload();
			} else if (res.request.status !== 200) {
				setIsTeamidxCheck(true);
				setTimeout(() => {
					setIsTeamidxCheck(false);
				}, 3000);
			} else {
				setIsCheck(true);
				setTimeout(() => {
					setIsCheck(false);
				}, 3000);
			}
		}
	}

	const debouncedInputText = useDebounce(inputText, 200);
	useEffect(() => {
		if (debouncedInputText) {
			doctorSearch(debouncedInputText);
		}
	}, [debouncedInputText]);

	function onMemberInfoChange(e: any) {
		setInputText(e.target.value);
	}

	const doctorSearch = async (debouncedInputText: any) => {
		const res = await axios.get(
			`/admin/affiliation/addMember?search=${debouncedInputText}`
		);

		console.log('res.data', res.data);
		const memberIdList = memberList.map((member) => member.id);
		const filteredPartnerList = res.data[0].filter(
			(partner: any) => !memberIdList.includes(partner.id)
		);
		setSearchData(filteredPartnerList);
	};

	const onCancle = (e: any) => {
		onClick(e);
		setInputText('');
	};

	const handleAddMember = (partner: any) => {
		memberIdList.indexOf(partner.id) === -1 &&
			memberList.push(partner) &&
			memberIdList.push(partner.id);
		setInputText('');
	};

	const handleDeleteMember = (partner: any) => {
		const newMemberList = memberList.filter((member: any) => member.id !== partner.id);
		setMemberList(newMemberList);
		const newMemberIdList = memberIdList.filter((memberId: any) => memberId !== partner.id);
		setMemberIdList(newMemberIdList);
		setDeleteMemberIdList((prev: number[]) => [...prev, partner.id]);
	};

	return (
		<>
			<ErrorAlert toggle={isCheck} label='입력값을 확인해주세요' />
			<ErrorAlert toggle={isTeamIdxCheck} label='파트너를 추가해주세요' />
			<StyledModal
				visible={isModalVisible}
				maskClosable={true}
				centered={true}
				closable={false}
				width='676px'
				onCancel={() => setIsModalVisible(false)}
				footer={null}>
				<Content>
					<SearchContainer>
						<PrimaryInput
							icon={<SearchIcon />}
							value={inputText}
							onChange={onMemberInfoChange}
							placeHolder=' 원하는 팀원의 이름 혹은 전화번호를 입력해주세요.'
						/>
						{inputText !== '' &&
							(searchData.length > 0 ? (
								<ResultContainer>
									{searchData.map((partner: any) => (
										<Result
											onClick={() =>
												handleAddMember(partner)
											}>
											{partner.name}(
											{partner.phoneNumber})
										</Result>
									))}
								</ResultContainer>
							) : (
								<ResultContainer>
									<Result>조회된 데이터가 없습니다.</Result>
								</ResultContainer>
							))}
					</SearchContainer>
					<MemberTitle>팀원 리스트</MemberTitle>
					<MemberContainer>
						{memberList?.length === 0 ? (
							<Member color='grey'>소속된 구성원이 없습니다.</Member>
						) : (
							memberList?.map((partner: any) => {
								return (
									<Member color='black'>
										{partner.name}({partner.phoneNumber})
										<DeleteBtn
											className='delete text'
											onClick={() => {
												handleDeleteMember(partner);
											}}>
											삭제
										</DeleteBtn>
									</Member>
								);
							})
						)}
					</MemberContainer>
					<BtnContainer>
						<PrimaryBtn label='취소' color='none' onClick={onCancle} />

						<PrimaryBtn label='저장하기' onClick={onSaveBtn} />
					</BtnContainer>
				</Content>
			</StyledModal>
		</>
	);
}

const StyledModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 20px;
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
`;

const BtnContainer = styled.div`
	display: flex;
	gap: 16px;
	margin: 14px 0 0;
`;

const SearchContainer = styled.div`
	position: relative;
`;

const MemberTitle = styled.div`
	height: 20px;
	color: #919d9d;
	font-size: 12px;
	line-height: 20px;
	margin-bottom: 10px;
`;
const ResultContainer = styled.div`
	position: absolute;
	width: 100%;
	max-height: 350px;
	overflow: auto;
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	border: 1px solid #ecf0f0;
	border-radius: 16px;
	padding: 6px 0px;
	z-index: 1000;
	box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
		0 9px 28px 8px rgb(0 0 0 / 5%);
`;
const Result = styled.div`
	background-color: #ffffff;
	padding: 10px 16px;
	line-height: 24px;
	:hover {
		background: #f4fbfe;
	}
`;

const MemberContainer = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid #bec6c4;
	border-radius: 16px;
	padding: 6px 0px;
	z-index: 800;
`;
const Member = styled.div<{ color?: string }>`
	position: relative;
	display: flex;
	height: 44px;
	padding: 10px 16px;
	line-height: 24px;
	z-index: 700;
	color: ${({ color }) => (color === 'grey' ? '#bec6c4' : 'default')};
`;

const DeleteBtn = styled.div`
	display: flex;
	position: absolute;
	right: 20px;
	color: #e44b55;
	cursor: pointer;
	:hover {
		color: #bec6c4;
	}
`;
