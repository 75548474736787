import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import useAxios from 'api/axiosCustom';
import { HeaderBar } from 'components/header';
import { LoadingModal, TwoBtnModal } from 'components/modal';
import { PreDiagnosisCard, ProfileInfoCard } from 'components/card';
import { PrimaryBtn } from 'components/button';
import { SelectSymptom, SelectChat } from 'components/detail';
import styleConstant from 'data/constant/styleConstant';
import menuConstant from 'data/constant/menuConstant';
import useObserver from 'shared/hook/useObserver';
import moment from 'moment';

export default function DetailPage() {
	const navigate = useNavigate();
	const location = useLocation();
	const axios = useAxios();

	const [userInfo, setUserInfo] = useState<any>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isApproveModal, setIsApproveModal] = useState<boolean>(false);
	const [isRejectModal, setIsRejectModal] = useState<boolean>(false);
	const [selectProfile, setSelectProfile] = useState<any>({});
	const [profileInterview, setProfileInterview] = useState<any>([]);
	const [page, setPage] = useState<number>(-10);
	const [isLimit, setIsLimit] = useState<boolean>(true);

	const { setRef } = useObserver(
		async (entry: any, observer) => {
			observer.observe(entry.target);
			setPage((prevState) => (prevState += 10));
		},
		{ threshold: 0.8 }
	);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const res = await axios.get(`/admin/user/${location.state.id}`);

			setUserInfo(res.data);
			setSelectProfile(res.data.Profiles[0]);
			setIsLoading(false);
		})();
	}, [location.state]);

	useEffect(() => {
		if (selectProfile?.id !== undefined) {
			(async () => {
				setIsLoading(true);
				const res = await axios.get(
					`/counseling/admin?profileId=${selectProfile.id}`
				);
				setProfileInterview(res.data);
				if (res.data.length < 10) {
					setIsLimit(false);
				}
				setIsLoading(false);
			})();
		}
	}, [selectProfile]);

	useEffect(() => {
		if (page > 9 && isLimit) {
			(async () => {
				setIsLoading(true);
				const res = await axios.get(
					`/counseling/admin?profileId=${selectProfile.id}&offset=${page}`
				);
				setProfileInterview((prev: any) => [...prev, res.data]);
				if (res.data.length < 10) {
					setIsLimit(false);
				}
				setIsLoading(false);
			})();
		}
	}, [page]);

	function onPartnerApproveBtn() {
		setIsApproveModal(true);
	}

	function onApproveModalCancelBtn() {
		setIsApproveModal(false);
	}

	async function onApproveModalconfirmBtn() {
		await axios.post(`/admin/doctor/${userInfo.Doctor.id}`);
		navigate(0);
	}

	function onPartnerRejectBtn() {
		setIsRejectModal(true);
	}

	function onRejectModalCancelBtn() {
		setIsRejectModal(false);
	}

	async function onRejectModalconfirmBtn() {
		await axios.delete(`/admin/doctor/${userInfo.Doctor.id}`);
		navigate(0);
	}

	function onProfileCard(profile: any) {
		setSelectProfile(profile);
		setPage(0);
	}

	function onPreDiagnosisStatus(isCounseling: boolean, isEnd: boolean) {
		if (isEnd) {
			return 'end';
		} else if (isCounseling) {
			return 'progress';
		} else {
			return 'waiting';
		}
	}

	return (
		<Cover>
			<LoadingModal isModalVisible={isLoading} />
			<TwoBtnModal
				isModalVisible={isApproveModal}
				mainText='정말로 승인 하시겠어요?'
				leftBtnText='아니요'
				leftBtnClick={onApproveModalCancelBtn}
				rightBtnText='승인 하기'
				rightBtnClick={onApproveModalconfirmBtn}
			/>
			<TwoBtnModal
				isModalVisible={isRejectModal}
				mainText='정말로 승인 취소하시겠어요?'
				leftBtnText='아니요'
				leftBtnClick={onRejectModalCancelBtn}
				rightBtnText='승인 취소하기'
				rightBtnClick={onRejectModalconfirmBtn}
			/>
			<HeaderBar nowPath={menuConstant.MEMBER_INQUIRY} />
			<ContentsContainer>
				<SymptomContainer>
					<IdInfoContainer isPartner={userInfo.Doctor}>
						<div>
							<p className='idInfo'>아이디 정보</p>
							<div className='container'>
								<p className='title'>이메일</p>
								<p className='content'>{location.state.email}</p>
								<p className='title'>휴대폰 번호</p>
								<p className='content last'>
									{location.state.phoneNumber}
								</p>
							</div>
						</div>
						<div className='partner'>
							<p className='idInfo'>파트너 정보</p>
							<div className='container'>
								<p className='name'>이름</p>
								<p className='content'>{userInfo.Doctor?.name}</p>
								<p className='title'>진료과</p>
								<p className='content'>
									{userInfo.Doctor?.department}
								</p>
								<p className='title'>소속</p>
								<p className='content'>
									{userInfo.Doctor?.belongTo}
								</p>
								<p className='title'>의사 면허번호</p>
								<p className='content'>
									{userInfo.Doctor?.licenseNumber}
								</p>
								<p className='title'>상태</p>
								<p className='content'>
									{userInfo.Doctor?.status === 1
										? '대기중'
										: '승인됨'}
								</p>
								{userInfo.Doctor?.status === 1 ? (
									<PrimaryBtn
										label='승인'
										onClick={onPartnerApproveBtn}
									/>
								) : (
									<PrimaryBtn
										label='승인 취소'
										color='red'
										onClick={onPartnerRejectBtn}
									/>
								)}
							</div>
						</div>
					</IdInfoContainer>
					<ProfileInfoContainer>
						<p className='profileInfo'>프로필 정보</p>
						{userInfo.Profiles?.length === 0 ? (
							<div className='noneProfile'>
								생성한 프로필이 없습니다.
							</div>
						) : (
							userInfo.Profiles?.map((element: any) => (
								<ProfileInfoCard
									name={element?.name}
									birth={element?.birthDate}
									gender={element?.gender}
									job={element?.job}
									isSelect={selectProfile?.id === element?.id}
									onClick={() => onProfileCard(element)}
								/>
							))
						)}
					</ProfileInfoContainer>
				</SymptomContainer>
				<CounselingContainer>
					<p className='listText'>{selectProfile?.name} 님의 문진</p>
					{profileInterview.length === 0 ? (
						<p>진행한 문진이 없습니다.</p>
					) : (
						<InterviewContainer>
							{profileInterview.map((element: any, idx: number) => (
								<PreDiagnosisCard
									key={idx}
									name={element.Profile.name}
									hospitalName={element.hospital?.hospitalName}
									gender={element.Profile.gender}
									birth={`${element.Profile.birthDate.slice(
										0,
										4
									)}년생`}
									date={moment(element.createdAt).format(
										'MM월 DD일 HH:mm'
									)}
									state={onPreDiagnosisStatus(
										element.isCounseling,
										element.isEnd
									)}
									department={element.department}
									symptom={element.symptom}
									mainText={element.additionalSymptom}
								/>
							))}
							<div ref={setRef} style={{ height: '10px' }} />
						</InterviewContainer>
					)}
				</CounselingContainer>
			</ContentsContainer>
		</Cover>
	);
}

const Cover = styled.div`
	width: 1024px;
	margin: 0 auto;
	padding-bottom: 34px;
`;

const ContentsContainer = styled.div`
	display: flex;
	gap: 20px;
	margin-top: 42px;
`;

const SymptomContainer = styled.div`
	position: relative;
	gap: 20px;
	display: flex;
	.mainText {
		position: absolute;
		top: -96px;
		left: 52px;
		margin: 0;
		font-size: 40px;
		font-weight: bold;
	}
`;

const IdInfoContainer = styled.div<{ isPartner: true | false }>`
	.idInfo {
		font-size: ${styleConstant.FONT_SIZE.small};
		font-weight: bold;
		line-height: 1.43;
		letter-spacing: -0.2px;
		margin-bottom: 8px;
	}
	.container {
		width: 328px;
		margin: 0 0 48px;
		padding: 16px;
		border-radius: 16px;
		background-color: #f8f8f8;
		.title {
			font-size: ${styleConstant.FONT_SIZE.small};
			line-height: 1.43;
			letter-spacing: -0.2;
			margin: 0 0 8px;
		}
		.content {
			font-size: ${styleConstant.FONT_SIZE.medium};
			font-weight: bold;
			line-height: 1.5;
			letter-spacing: -0.2px;
			margin: 0 0 24px;
		}
		.last {
			margin: 0;
		}
	}
	.partner {
		display: ${({ isPartner }) => (isPartner ? '' : 'none')};
	}
`;

const ProfileInfoContainer = styled.div`
	.profileInfo {
		font-size: ${styleConstant.FONT_SIZE.small};
		font-weight: bold;
		line-height: 1.43;
		letter-spacing: -0.2px;
		margin-bottom: 8px;
	}
	.noneProfile {
		width: 328px;
		margin: 12px 0 0;
	}
`;

const CounselingContainer = styled.div`
	position: relative;
	.listText {
		font-size: ${styleConstant.FONT_SIZE.medium};
		font-weight: bold;
	}
`;

const InterviewContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;
