import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { answerSubmit } from 'store/reducer/testPreviewReducer';
import { RootState } from 'store/reducer/rootReducer';
import { PrimaryBtn } from 'components/button';
import { TwoBtnModal } from 'components/modal';
import { ReactComponent as PictureIcon } from 'assets/icon/interview/pictureIcon.svg';
import RemoveIcon from 'assets/icon/interview/removeIcon.svg';

export default function Upload() {
	const questionIndex = useSelector((state: RootState) => state.testPreview.index);
	const question = useSelector((state: RootState) => state.testPreview.question[questionIndex]);
	const questionLength = useSelector((state: RootState) => state.testPreview.question.length);

	const dispatch = useDispatch();

	const [isRemoveModalVisible, setIsRemoveModalVisible] = useState<boolean>(false);
	const [isSubmitModalVisible, setIsSubmitModalVisible] = useState<boolean>(false);
	const [imgValue, setImgValue] = useState<any>([]);
	const [removeIdx, setRemoveIdx] = useState<number>(0);
	const imgInput = useRef<any>(null);
	const locale = localStorage.getItem('locale');

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [questionIndex]);

	function onImgInputBtnClick() {
		imgInput.current.click();
	}

	function onImgChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (e.target.files && e.target.files[0]) {
			setImgValue([...imgValue, e.target.files[0]]);
		}
	}

	function onRemoveModalLeftBtnClick() {
		setIsRemoveModalVisible(false);
	}

	function onRemoveModalRightBtnClick() {
		const list = [...imgValue];
		list.splice(removeIdx, 1);
		setImgValue(list);
		setIsRemoveModalVisible(false);
	}

	function onSubmitModalLeftBtnClick() {
		setIsSubmitModalVisible(false);
	}

	function onSubmitModalRightBtnClick() {
		dispatch(
			answerSubmit({
				id: question.id,
				answer: '',
				enAnswer: '',
			})
		);
		setIsSubmitModalVisible(false);
	}

	function onPictureRemoveBtn(idx: number) {
		setRemoveIdx(idx);
		setIsRemoveModalVisible(true);
	}

	function onNextBtn() {
		let answer: string = '';
		if (imgValue.length === 0) {
			setIsSubmitModalVisible(true);
		} else {
			dispatch(
				answerSubmit({
					id: question.id,
					answer: answer,
					enAnswer: answer,
				})
			);
		}
	}

	return (
		<Cover>
			<TwoBtnModal
				isModalVisible={isRemoveModalVisible}
				mainText='사진을 정말 지우시겠어요?'
				leftBtnText='아니요'
				leftBtnClick={onRemoveModalLeftBtnClick}
				rightBtnText='삭제하기'
				rightBtnClick={onRemoveModalRightBtnClick}
				rightBtnColor='red'
			/>
			<TwoBtnModal
				isModalVisible={isSubmitModalVisible}
				mainText='사진을 올리지 않고 진행하시겠어요?'
				leftBtnText='아니요'
				leftBtnClick={onSubmitModalLeftBtnClick}
				rightBtnText='진행하기'
				rightBtnClick={onSubmitModalRightBtnClick}
			/>
			<p className='mainText'>
				{locale === 'en' ? question.EnQuestion.mainQuestion : question.mainQuestion}
			</p>
			<p className='subText'>
				{locale === 'en' ? question.EnQuestion.subQuestion : question.subQuestion}
			</p>
			<InputImgContainer onClick={onImgInputBtnClick}>
				<input
					ref={imgInput}
					type='file'
					accept='image/*;capture=camera'
					onChange={onImgChange}
				/>
				<PictureIcon />
				<p>사진 올리기</p>
			</InputImgContainer>
			<SelectContainer className={imgValue.length ? '' : 'noSelect'}>
				<p className='text'>첨부된 사진</p>
				{imgValue.length
					? imgValue.map((element: any, idx: number) => (
							<p key={idx}>
								<span className='imgName'>{element?.name}</span>
								<span onClick={() => onPictureRemoveBtn(idx)}>
									<RemoveIcon />
								</span>
							</p>
					  ))
					: null}
			</SelectContainer>
			<BtnContainer>
				{questionLength - 1 === questionIndex ? (
					<PrimaryBtn
						label={locale === 'ko' ? '제출' : 'Submit'}
						onClick={onNextBtn}
					/>
				) : (
					<PrimaryBtn
						label={locale === 'ko' ? '다음' : 'Continue'}
						onClick={onNextBtn}
					/>
				)}
			</BtnContainer>
		</Cover>
	);
}

const Cover = styled.div`
	margin-top: 24px;
	padding-bottom: 32px;
	.mainText {
		font-size: 24px;
		font-weight: bold;
		line-height: 133%;
		margin: 0 0 24px;
		word-break: keep-all;
	}
	.noSelect {
		display: none;
	}
`;

const InputImgContainer = styled.div`
	width: 100%;
	height: 128px;
	padding: 16px;
	border: solid 1px #cfcfcf;
	border-radius: 20px;
	text-align: center;
	cursor: pointer;
	input {
		display: none;
	}
	p {
		font-size: 16px;
		font-weight: bold;
		line-height: 150%;
		margin: 0px;
	}
`;

const SelectContainer = styled.div`
	width: 100%;
	background-color: #f8f8f8;
	border-radius: 16px;
	padding: 16px;
	margin-top: 24px;
	.text {
		font-size: 14px;
		line-height: 150%;
		font-weight: normal;
		margin: 0;
	}
	p {
		width: 100%;
		margin: 8px 0 0;
		display: flex;
		justify-content: space-between;
		.imgName {
			width: 85%;
			font-size: 16px;
			font-weight: bold;
			line-height: 150%;
			letter-spacing: -0.2px;
			color: #222222;
			cursor: auto;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		span {
			cursor: pointer;
		}
	}
`;

const BtnContainer = styled.div`
	margin-top: 32px;
`;
