import React from "react";
import styled from "styled-components";
import styleConstant from "data/constant/styleConstant";

export interface HalfAskCardProps {
  item: string;
}

export function HalfAskCard({ item }: HalfAskCardProps) {
  return (
    <Cover>
      <ItemContainer>{item}</ItemContainer>
      <UnCheckContainer />
    </Cover>
  );
}

const Cover = styled.div`
  width: 46.5%;
  height: 56px;
  padding: 16px;
  border: solid 1px #cfcfcf;
  border-radius: 16px;
  font-family: "Noto Sans KR", sans-serif;
  display: flex;
  align-items: center;
  :hover {
    border: 1px solid #27b4eb;
  }
  :active {
    border: 1px solid #0083b1;
  }
`;

const ItemContainer = styled.div`
  width: 90%;
  font-size: ${styleConstant.FONT_SIZE.medium};
  font-weight: bold;
`;

const UnCheckContainer = styled.div`
  min-width: 24px;
  min-height: 24px;
  margin: 0 0 0 10px;
  border: solid 2px #cfcfcf;
  border-radius: 12px;
`;
