import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useAxios from 'api/axiosCustom';
import { Modal } from 'antd';
import { ErrorAlert } from 'components/alert';
import { PrimaryInput } from 'components/input';
import { PrimaryBtn } from 'components/button';
import { DepartmentAddComponent } from './departmentAddComponent';
import { IDepartment } from 'data/interface/interviewManage';

interface TeamAddModalProps {
	isModalVisible: boolean;
	setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	value?: any;
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
	listRequest: () => void;
}

export function TeamAddModal({
	isModalVisible,
	setIsModalVisible,
	value = {},
	onClick,
	listRequest,
}: TeamAddModalProps) {
	const axios = useAxios();
	const [isCheck, setIsCheck] = useState<boolean>(false);
	const [isTeamIdxCheck, setIsTeamidxCheck] = useState<boolean>(false);
	const [team, setTeam] = useState<string>(value.hospitalName);
	const [pathName, setPathName] = useState<string>(value.pathName);
	const [selectActive, setSelectActive] = useState<string>('활성');
	const [isUpdate, setIsUpdate] = useState<boolean>(false);
	const [isActive, setIsActive] = useState<boolean>(true);

	const [departmentList, setDepartmentList] = useState<IDepartment[]>([]);
	const [departmentIdList, setDepartmentIdList] = useState<any>([]);
	const [deleteDepartmentIdList, setDeleteDepartmentIdList] = useState<any>([]);

	useEffect(() => {
		if (value.id) {
			setTeam(value.hospitalName);
			setPathName(value.pathName);
			const listifuDepartmentList = value.HospitalWithDepartments?.map(
				(dep: any) => dep.departmentId
			);
			setDepartmentIdList(listifuDepartmentList);
			setIsUpdate(true);
			setIsActive(value.isActive);
			value.isActive ? setSelectActive('활성') : setSelectActive('비활성');

			(async () => {
				const selectedDepartmentId = value?.HospitalWithDepartments?.map(
					(dep: any) => dep.departmentId
				)?.filter((questionId: any) => questionId !== '');

				const searchDepartmentArr: any[] = [];

				const res = await axios.get(`/department/findDepartmentId?limit=9999`);
				res.data?.findDepartment?.map((department: IDepartment) => {
					searchDepartmentArr.push(department);
				});

				const newDepartment = selectedDepartmentId?.map((departmentId: string) => {
					return searchDepartmentArr.filter(
						(department) => department?.id === Number(departmentId)
					)[0];
				});

				setDepartmentIdList(selectedDepartmentId);
				setDepartmentList(newDepartment || []);
			})();
		}
	}, [value]);

	useEffect(() => {
		if (!isModalVisible) {
			setTeam('');
			setPathName('');
			setDepartmentList([]);
			setDepartmentIdList([]);
			setDeleteDepartmentIdList([]);
			setIsUpdate(false);
			setIsActive(true);
		}
	}, [isModalVisible]);

	const onInputChange = (e: any, key: string) => {
		switch (key) {
			case 'team':
				setTeam(e.target.value);
				break;
			case 'pathName':
				setPathName(e.target.value);
				break;
			case 'departmentList':
				setDepartmentList(e.target.value);
				break;
		}
	};

	async function onSaveBtn() {
		const ndepartmentIdList = departmentIdList.map((dep: string) => Number(dep));
		console.log({
			hospitalName: team,
			pathName,
			departmentId: ndepartmentIdList,
		});
		const res = await axios.post('/hospital/resistWithDepartment', {
			hospitalName: team,
			pathName,
			departmentList: ndepartmentIdList,
			//isActive: selectActive,
		});
		if (res.status === 200) {
			setIsModalVisible(false);
			listRequest();
		} else {
			setIsCheck(true);
		}
	}

	async function onModifyBtn() {
		const ndepartmentIdList = departmentIdList.map((depId: string) => Number(depId));

		if (value.id) {
			console.log('{	teamId: value.id, 	departmentId: departmentIdList}', {
				hospitalName: team,
				pathName,
				teamId: value.id.toString(),
				departmentId: ndepartmentIdList,
			});

			// if (deleteDepartmentIdList) {
			// 	const res = await axios.delete(`/hospital/delete`, {
			// 		data: { teamId: value.id, departmentId: deleteDepartmentIdList },
			// 	});
			// }

			if (value.hospitalName !== team || value.pathName !== pathName) {
				const res = await axios.put(`/hospital/updateHospitalNameWithPathName`, {
					teamId: value.id.toString(),
					hospitalName: team,
					pathName,
				});
			}

			const res = await axios.post(`/hospital/resistSeparatelyDoctor`, {
				teamId: value.id.toString(),
				departmentId: ndepartmentIdList,
			});

			if (res.status === 200) {
				setIsModalVisible(false);
				listRequest();
			} else {
				setIsCheck(true);
			}
			if (res.status === 200) {
				setIsModalVisible(false);
				listRequest();
			} else if (res.request.status) {
				setIsTeamidxCheck(true);
				setTimeout(() => {
					setIsTeamidxCheck(false);
				}, 3000);
			} else {
				setIsCheck(true);
				setTimeout(() => {
					setIsCheck(false);
				}, 3000);
			}
		}
	}
	const onCancelBtn = (e: any) => {
		setTeam('');
		setPathName('');
		setDepartmentList([]);
		setDeleteDepartmentIdList([]);
		onClick(e);
	};

	return (
		<>
			<StyledModal
				visible={isModalVisible}
				maskClosable={true}
				centered={true}
				closable={false}
				width='676px'
				onCancel={() => setIsModalVisible(false)}
				footer={null}>
				<Content>
					<PrimaryInput
						label='팀명'
						value={team}
						onChange={(e) => {
							onInputChange(e, 'team');
						}}
						placeHolder={value.hospitalName}
					/>
					<PrimaryInput
						label='경로'
						value={pathName}
						onChange={(e) => {
							onInputChange(e, 'pathName');
						}}
						placeHolder={value.pathName}
					/>
					{/* <PrimaryInput
						label='관련 진료과 리스트'
						value={departmentList}
						onChange={(e) => {
							onInputChange(e, 'departmentList');
						}}
						placeHolder={value.departmentList}
					/> */}

					<DepartmentAddComponent
						value={value}
						departmentList={departmentList}
						setDepartmentList={setDepartmentList}
						departmentIdList={departmentIdList}
						setDepartmentIdList={setDepartmentIdList}
						deleteDepartmentIdList={deleteDepartmentIdList}
						setDeleteDepartmentIdList={setDeleteDepartmentIdList}
					/>

					<BtnContainer>
						<PrimaryBtn label='취소' color='none' onClick={onCancelBtn} />
						{value.id ? (
							<PrimaryBtn label='수정하기' onClick={onModifyBtn} />
						) : (
							<PrimaryBtn label='저장하기' onClick={onSaveBtn} />
						)}
					</BtnContainer>
				</Content>
			</StyledModal>
		</>
	);
}

const StyledModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 20px;
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
`;

const BtnContainer = styled.div`
	display: flex;
	gap: 16px;
	margin: 14px 0 0;
`;
