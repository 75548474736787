import React from "react";
import styled from "styled-components";
import { Modal } from "antd";
import { PrimaryBtn } from "components/button";
import styleConstant from "data/constant/styleConstant";

interface OneBtnModalProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onKoreanBtn: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onEnglishBtn: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}

export function LocaleModal({
  isModalVisible,
  setIsModalVisible,
  onKoreanBtn,
  onEnglishBtn,
}: OneBtnModalProps) {
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <StyledModal
      visible={isModalVisible}
      maskClosable={true}
      centered={true}
      closable={false}
      onCancel={handleCancel}
      footer={null}
    >
      <Container>
        <p className="mainText">문진 테스트하기</p>
        <PrimaryBtn label="한글(Korean)" color="none" onClick={onKoreanBtn} />
        <PrimaryBtn label="영문(English)" color="none" onClick={onEnglishBtn} />
      </Container>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 416px;
    height: 216px;
    border-radius: 16px;
    padding: 24px;
  }
  .ant-modal-body {
    padding: 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .mainText {
    margin: 0 0 8px;
    font-size: ${styleConstant.FONT_SIZE.large};
    font-weight: bold;
  }
`;
