import React, { useState } from 'react';
import styled from 'styled-components';
import { HeaderBar } from 'components/header';

import styleConstant from 'data/constant/styleConstant';
import menuConstant from 'data/constant/menuConstant';
import { SelectTeam } from 'components/affiliationManage';
import { SelectDepartment } from 'components/affiliationManage/selectDepartment';
import { SelectSymptom } from 'components/affiliationManage/selectSymptom';

export default function AffiliationManagePage() {
	const [selectMenu, setSelectMenu] = useState<string>('팀');
	const selectMenuComponent: { [key: string]: JSX.Element } = {
		팀: <SelectTeam />,
		진료과: <SelectDepartment />,
		증상: <SelectSymptom />,
	};

	function onMenuBtn(menu: string) {
		setSelectMenu(menu);
	}

	return (
		<Cover>
			<HeaderBar noBack={true} nowPath={menuConstant.AFFILIATION_MANAGE} />
			<p className='mainText'>소속 관리</p>
			<MenuContainer>
				<p
					className={selectMenu === '팀' ? 'select' : ''}
					onClick={() => onMenuBtn('팀')}>
					팀
				</p>
				<p
					className={selectMenu === '진료과' ? 'select' : ''}
					onClick={() => onMenuBtn('진료과')}>
					진료과
				</p>
				<p
					className={selectMenu === '증상' ? 'select' : ''}
					onClick={() => onMenuBtn('증상')}>
					증상
				</p>
			</MenuContainer>
			{selectMenuComponent[selectMenu]}
		</Cover>
	);
}

const Cover = styled.div`
	width: 1024px;
	margin: 0 auto;
	.mainText {
		font-size: 40px;
		font-weight: bold;
		margin-top: 48px;
	}
`;

const MenuContainer = styled.div`
	display: flex;
	gap: 16px;
	font-size: ${styleConstant.FONT_SIZE.medium};
	p {
		cursor: pointer;
	}
	.select {
		font-weight: bold;
		padding-bottom: 4px;
		border-bottom: solid 2px #1bbcff;
	}
`;
