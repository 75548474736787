import React from "react";
import styled from "styled-components";
import styleConstant from "data/constant/styleConstant";

interface BtnInputProps {
  label: string;
  value: string;
  placeholder?: string;
  inputRef?: React.MutableRefObject<any>;
  type?: "text" | "file";
  btnLabel: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function BtnInput({
  label,
  value,
  placeholder,
  inputRef,
  type = "text",
  btnLabel,
  onChange,
  onClick,
}: BtnInputProps) {
  return (
    <Cover>
      {label !== "" ? <p className="label">{label}</p> : null}
      <InputContainer>
        <Input
          //   value={value}
          ref={inputRef}
          accept="image/*"
          placeholder={placeholder}
          onChange={onChange}
          type={type}
        />
        <div className="value">{value}</div>
        <button onClick={onClick}>{btnLabel}</button>
      </InputContainer>
    </Cover>
  );
}

const Cover = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin: 16px 0;
  .label {
    text-align: start;
    font-size: ${styleConstant.FONT_SIZE.moreSmall};
    color: #969696;
    margin: 0 0 4px 0;
  }
`;

const InputContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  /* border: solid 1px #cfcfcf; */
  border-radius: 15px;
  background-color: #fff;
  display: flex;
  /* justify-content: space-between; */
  gap: 16px;
  align-items: center;
  button {
    width: 120px;
    height: 48px;

    border-radius: 12px;
    border: 1px solid #bec6c4;
    background-color: #fff;
    color: #3f4443;
    font-size: ${styleConstant.FONT_SIZE.medium};
    font-weight: 500;
    cursor: pointer;
  }
  .value {
    box-sizing: border-box;
    border: 1px solid #bec6c4;
    border-radius: 12px;
    width: 496px;
    height: 48px;
    padding: 10px 12px 10px 24px;
    font-size: ${styleConstant.FONT_SIZE.medium};
    line-height: 18px;
    overflow: auto;
  }
`;

const Input = styled.input`
  /* box-sizing: border-box;
  border: none;
  border-radius: 20px;
  width: 75%;
  height: 40px;
  padding: 10px 12px;
  font-size: ${styleConstant.FONT_SIZE.medium};
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  resize: none;

  &:focus {
    outline: none;
  } */
  display: none;
`;
