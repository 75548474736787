import { createSlice } from "@reduxjs/toolkit";
import tokenConstant from "data/constant/tokenConstant";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    accessToken: "",
    refreshToken: "",
  },
  reducers: {
    addToken: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      localStorage.setItem(
        tokenConstant.accessToken,
        action.payload.accessToken,
      );
      localStorage.setItem(
        tokenConstant.refreshToken,
        action.payload.refreshToken,
      );
    },
    logout: (state) => {
      localStorage.removeItem(tokenConstant.accessToken);
      localStorage.removeItem(tokenConstant.refreshToken);
    },
  },
});

export const { addToken, logout } = loginSlice.actions;
export default loginSlice.reducer;
