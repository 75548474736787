import styled from 'styled-components';
import styleConstant from 'data/constant/styleConstant';

interface ItemBoxProps {
	id: number;
	title: string;
	provided: any;
	snapshot: any;
	handleDeleteItem: any;
	item: any;
}

export function ItemBox({ id, title, provided, snapshot, handleDeleteItem, item }: ItemBoxProps) {
	return (
		<Question
			isDragging={snapshot.isDragging}
			key={id}
			{...provided.draggableProps}
			ref={provided.innerRef}
			{...provided.dragHandleProps}>
			<p
				style={{
					display: 'inline',
					width: '40px',
					fontWeight: 500,
					color: styleConstant.COLOR.CTA.DEFAULT,
				}}>
				{id}
			</p>
			{title}
			<DeleteBtn
				className='delete text'
				onClick={() => {
					handleDeleteItem(item);
				}}>
				삭제
			</DeleteBtn>
		</Question>
	);
}

const Question = styled.div<{ color?: string; isDragging: true | false }>`
	position: relative;
	display: flex;
	height: 44px;
	padding: 10px 16px;
	line-height: 24px;
	z-index: 700;
	box-shadow: ${({ isDragging }) => (isDragging ? '0 7px 7px rgba(0, 0, 0, 0.1)' : '')};
	background-color: ${({ isDragging }) =>
		isDragging ? styleConstant.COLOR.CTA.SECONDARY_HOVER : ''};
	border-radius: ${({ isDragging }) => (isDragging ? '10px' : '')};
	border: 1px solid ${styleConstant.COLOR.GRAY_1};
	color: ${({ color }) => (color === 'grey' ? '#bec6c4' : 'default')};
	:hover {
		background-color: ${styleConstant.COLOR.CTA.SECONDARY_HOVER};
	}
`;

const DeleteBtn = styled.div`
	display: flex;
	position: absolute;
	right: 20px;
	color: #e44b55;
	cursor: pointer;
	:hover {
		color: #bec6c4;
	}
`;
