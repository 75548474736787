import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function NaverLogin() {
  // useEffect(() => {
  //   let add: any;
  //   const timeout = setTimeout(() => {
  //     if (typeof window !== "undefined") {
  //       const naverLogin = new window.naver.LoginWithNaverId({
  //         clientId: process.env.REACT_APP_NAVER_LOGIN_KEY,
  //         callbackUrl: "https://admin.knowingdoctor.com/callback/naverLogin",
  //         isPopup: false,
  //         callbackHandle: false,
  //       });
  //       naverLogin.init();
  //       add = window.addEventListener("load", function () {
  //         naverLogin.getLoginStatus(async function (status: any) {
  //           if (status) {
  //             window.opener.gotoLoginAccess(naverLogin);
  //             window.close();
  //           }
  //         });
  //       });
  //     }
  //   }, 100);

  //   return () => {
  //     window.removeEventListener("load", add);
  //     clearTimeout(timeout);
  //   };
  // }, []);
  return <div />;
}
