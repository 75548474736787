import React from "react";
import { AllCounseling } from "./all";
import { WaitingCounseling } from "./waiting";
import { ProgressCounseling } from "./progress";
import { EndCounseling } from "./end";
import { HealthyCounseling } from "./healthy";

interface IndexProps {
  type: string;
}

export default function Index({ type }: IndexProps) {
  switch (type) {
    case "전체":
      return <AllCounseling />;
    case "상담 대기":
      return <WaitingCounseling />;
    case "건강 상담":
      return <HealthyCounseling />;
    case "상담 중":
      return <ProgressCounseling />;
    case "상담 종료":
      return <EndCounseling />;
    default:
      return <div />;
  }
}
