import React from 'react';
import styled from 'styled-components';

interface TextInputProps {
	value: string;
	height?: string;
	onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export function TextInput({ value, onChange, height = '286px' }: TextInputProps) {
	return <Text value={value} onChange={onChange} height={height} />;
}

const Text = styled.textarea<{ height: string }>`
	width: 100%;
	height: ${({ height }) => height};
	margin: 16px 0;
	font-family: 'Noto Sans KR', sans-serif;
	font-size: 16px;
	line-height: 150%;
	border: solid 1px #cfcfcf;
	border-radius: 16px;
	padding: 16px 12px;
	resize: none;
	&:focus {
		outline: none;
		border: solid 1px #1bbcff;
		box-shadow: 0 0 0 1px #1bbcff;
	}
	// :hover {
	// 	border: 1px solid #27b4eb;
	// }
`;
