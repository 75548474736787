import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { answerSubmit } from 'store/reducer/testPreviewReducer';
import { RootState } from 'store/reducer/rootReducer';
import { AskCard } from 'components/card';
import { DeleteBtnInput } from 'components/input';
import { PrimaryBtn } from 'components/button';
import { ErrorAlert } from 'components/alert';

export default function Singular() {
	const questionIndex = useSelector((state: RootState) => state.testPreview.index);
	const question = useSelector((state: RootState) => state.testPreview.question[questionIndex]);
	const questionLength = useSelector((state: RootState) => state.testPreview.question.length);
	const preAnswer = useSelector(
		(state: RootState) => state.testPreview.answered[questionIndex]
	);

	const dispatch = useDispatch();

	const [checkAnswer, setCheckAnswer] = useState<string>('');
	const [checkEnAnswer, setCheckEnAnswer] = useState<string>('');
	const [alertToggle, setAlertToggle] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>('');
	const locale = localStorage.getItem('locale');

	let answers: string[];
	let EnAnswers: string[];
	if (question.answerList) {
		answers = question.answerList.split(';').slice(0, -1);
		EnAnswers = question.EnQuestion.answerList.split(';').slice(0, -1);
	} else {
		answers = [];
		EnAnswers = [];
	}

	useEffect(() => {
		window.scrollTo(0, 0);
		if (preAnswer !== undefined) {
			const splitPreAnswer = preAnswer.answer.split(';');
			const splitPreEnAnswer = preAnswer.enAnswer.split(';');
			setCheckAnswer(splitPreAnswer[0]);
			setCheckEnAnswer(splitPreEnAnswer[0]);
			setInputValue(splitPreAnswer[1]);
		}
	}, [questionIndex]);

	function onQuestionBtn(e: any, idx: number) {
		setCheckAnswer(answers[idx].trim());
		setCheckEnAnswer(EnAnswers[idx].trim());
	}

	function onInputChange({ target: { value } }: any) {
		setInputValue(value);
	}

	function onInputCancel() {
		setInputValue('');
	}

	function onNextBtn() {
		if (checkAnswer === '' || (checkAnswer === '예' && !inputValue)) {
			setAlertToggle(true);
			setTimeout(() => setAlertToggle(false), 2000);
		} else {
			if (question.isInput) {
				dispatch(
					answerSubmit({
						id: question.id,
						answer: `${checkAnswer};${inputValue}`,
						enAnswer: `${checkEnAnswer};${inputValue}`,
					})
				);
			} else {
				dispatch(
					answerSubmit({
						id: question.id,
						answer: checkAnswer,
						enAnswer: checkEnAnswer,
					})
				);
			}
			setCheckAnswer('');
			setCheckEnAnswer('');
			setInputValue('');
		}
	}

	return (
		<>
			<ErrorAlert toggle={alertToggle} label='답변을 선택해주세요.' />
			<Cover>
				<p className='mainText'>
					{locale === 'en'
						? question.EnQuestion.mainQuestion
						: question.mainQuestion}
				</p>
				<p className='subText'>
					{locale === 'en'
						? question.EnQuestion.subQuestion
						: question.subQuestion}
				</p>
				{question.imgSrc ? (
					<ImgContainer>
						<img
							src={question.imgSrc}
							alt='question'
							width='450x'
							height='200x'
						/>
					</ImgContainer>
				) : null}
				<AnswerContainer isInput={question.isInput}>
					{locale === 'en'
						? EnAnswers.map((text, index) =>
								text.trim() ? (
									<AskCard
										key={index}
										item={text.replace(/^\s+|\s+$/gm, '')}
										checked={
											checkEnAnswer ===
											text.replace(/^\s+|\s+$/gm, '')
										}
										clicked={(event) =>
											onQuestionBtn(event, index)
										}
									/>
								) : null
						  )
						: answers.map((text, index) =>
								text.trim() ? (
									<AskCard
										key={index}
										item={text.replace(/^\s+|\s+$/gm, '')}
										checked={
											checkAnswer ===
											text.replace(/^\s+|\s+$/gm, '')
										}
										clicked={(event) =>
											onQuestionBtn(event, index)
										}
									/>
								) : null
						  )}
				</AnswerContainer>
				{question.isInput ? (
					<InputContainer>
						<p>
							{locale === 'en'
								? question.EnQuestion.inputQuestion
								: question.inputQuestion}
						</p>
						<DeleteBtnInput
							value={inputValue}
							onChange={onInputChange}
							onCancel={onInputCancel}
						/>
					</InputContainer>
				) : null}
				{questionLength - 1 === questionIndex ? (
					<PrimaryBtn
						label={locale === 'ko' ? '제출' : 'Submit'}
						onClick={onNextBtn}
					/>
				) : (
					<PrimaryBtn
						label={locale === 'ko' ? '다음' : 'Continue'}
						onClick={onNextBtn}
					/>
				)}
			</Cover>
		</>
	);
}

const Cover = styled.div`
	margin-top: 24px;
	padding-bottom: 32px;
	position: relative;
	.mainText {
		font-size: 24px;
		font-weight: bold;
		line-height: 133%;
		margin: 0 0 24px;
	}
`;

const ImgContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	height: auto;
	margin: 0 0 24px;
`;

const AnswerContainer = styled.div<{ isInput: true | false }>`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin: ${({ isInput }) => (isInput ? '0' : '0 0 32px')};
`;

const InputContainer = styled.div`
	margin: 24px 0 32px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	p {
		font-size: 16px;
		font-weight: bold;
		line-height: 150%;
		margin: 0;
	}
`;
