import { InputNum, Singular } from 'components/preview';
import { InputNumBtn } from 'components/preview/inputNumBtn';
import { InputText } from 'components/preview/inputText';
import { PreviewHeader } from 'components/preview/previewHeader';
import { Upload } from 'components/preview/Upload';
import React, { useEffect, useState } from 'react';

export default function PreviewPage() {
	const [state, setState] = useState<any>({
		all: true,
		bigCategory: true,
		smallCategory: true,
		mainText: true,
		subText: true,
		answerList: true,
		inputTypeQuestion: true,
		inputUnit: true,
		nonBtnLabel: true,
		answerType: true,
	});
	const s = localStorage.getItem('state');
	useEffect(() => {
		if (s !== null) {
			setState(JSON.parse(s));
		}
	}, []);
	switch (state?.answerType) {
		case '주관식':
			if (state?.inputType === 'number') {
				return (
					<>
						<InputNum {...state} />
					</>
				);
			} else {
				return <InputText {...state} />;
			}
		case '업로드':
			return (
				<>
					<PreviewHeader />
					<Upload {...state} />
				</>
			);
		default: //단수 선택 && 복수 선택(미리보기에서는 중요치 않기 때문)
			if (state?.inputType === 'button') {
				return (
					<>
						<PreviewHeader />
						<Singular {...state} />
					</>
				);
			} else if (state?.inputType === 'number') {
				return <InputNumBtn {...state} />;
			}
			return <div>ErrorAlert</div>;
	}
}
