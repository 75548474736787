import React from 'react';
import styled from 'styled-components';
import styleConstant from 'data/constant/styleConstant';
import { ReactComponent as CheckCircle } from 'assets/icon/checkCircle.svg';

export interface AskCardProps {
	item: string;
	checked: boolean;
	clicked?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export function AskCard({ item, checked, clicked }: AskCardProps) {
	return (
		<Cover onClick={clicked}>
			<ItemContainer>{item}</ItemContainer>
			{checked ? <CheckCircle /> : <UnCheckContainer />}
		</Cover>
	);
}

const Cover = styled.div`
	width: 100%;
	height: 56px;
	padding: 16px;
	border: solid 1px #cfcfcf;
	border-radius: 16px;
	font-family: 'Noto Sans KR', sans-serif;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	:hover {
		border: 1px solid #27b4eb;
	}
	:active {
		border: 1px solid #0083b1;
	}
`;

const ItemContainer = styled.div`
	width: 90%;
	font-size: ${styleConstant.FONT_SIZE.medium};
	font-weight: bold;
	letter-spacing: -0.2px;
`;

const UnCheckContainer = styled.div`
	min-width: 24px;
	min-height: 24px;
	border: solid 2px #cfcfcf;
	border-radius: 12px;
`;

const CheckContainer = styled.div`
	min-width: 24px;
	min-height: 24px;
	border: solid 2px #cfcfcf;
	border-radius: 12px;
`;
