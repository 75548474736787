import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useAxios from 'api/axiosCustom';
import { useDispatch } from 'react-redux';
import { addToken } from 'store/reducer/loginReducer';
import { HeaderBar } from 'components/header';
import { ErrorAlert } from 'components/alert';
import { SignupInput } from 'components/input';
import { PrimaryBtn } from 'components/button';
import { ReactComponent as PhoneIcon } from 'assets/icon/login/phoneIcon.svg';
import { ReactComponent as PasswordIcon } from 'assets/icon/login/passwordIcon.svg';
import styleConstant from 'data/constant/styleConstant';
import loginTypeConstant from 'data/constant/loginTypeConstant';
import routerConstant from 'data/constant/routerConstant';

export default function PhoneNumLoginPage() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const axios = useAxios();

	const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
	const [phoneNumberValue, setPhoneNumberValue] = useState<number>(NaN);
	const [passwordValue, setPasswordValue] = useState<string>('');
	const [isLoginInfoCheck, setIsLoginInfoCheck] = useState<boolean>(true);

	function loginFail() {
		setIsAlertVisible(true);
		setTimeout(() => setIsAlertVisible(false), 2000);
	}

	function onPhoneNumInput(e: any) {
		setPhoneNumberValue(e.target.value);
	}

	function onPasswordInput(e: any) {
		setPasswordValue(e.target.value);
	}

	function onInputEnterKey(e: any) {
		if (e.keyCode === 13) {
			onLoginBtn();
		}
	}

	async function onLoginBtn() {
		await axios
			.post('/admin/login/phone', {
				password: passwordValue,
				phoneNumber: phoneNumberValue,
				loginType: loginTypeConstant.PHONE_NUMBER,
			})
			.then((res) => {
				if (res.status === 200) {
					dispatch(
						addToken({
							accessToken: res.data.accessToken,
							refreshToken: res.data.refreshToken,
						})
					);
					navigate(routerConstant.AFFLIATION_MANAGE);
				} else {
					loginFail();
				}
			})
			.catch(loginFail);
	}

	return (
		<Cover>
			<HeaderBar loginPage={true} />
			<ErrorAlert label='권한이 없는 사용자입니다.' toggle={isAlertVisible} />
			<p className='mainText'>로그인 정보를 입력해주세요</p>
			<SignupInput
				label='휴대폰 번호'
				icon={<PhoneIcon />}
				value={phoneNumberValue}
				type='number'
				onChange={onPhoneNumInput}
				onKeyDown={onInputEnterKey}
				check={isLoginInfoCheck}
				checkText='아이디와 비밀번호를 확인해주세요.'
			/>
			<SignupInput
				label='비밀번호'
				icon={<PasswordIcon />}
				value={passwordValue}
				type='password'
				onChange={onPasswordInput}
				onKeyDown={onInputEnterKey}
				check={isLoginInfoCheck}
				checkText='아이디와 비밀번호를 확인해주세요.'
			/>
			<PrimaryBtn label='로그인하기' color='blue' onClick={onLoginBtn} />
		</Cover>
	);
}

const Cover = styled.div`
	width: ${styleConstant.DESKTOP_BODY_WIDTH};
	margin: ${styleConstant.DESKTOP_BODY_MARGIN};
	text-align: start;
	.mainText {
		font-size: 32px;
		line-height: 40px;
		font-weight: bold;
		margin-top: 24px;
		margin-bottom: 50px;
	}
	.searchPasswordText {
		font-size: ${styleConstant.FONT_SIZE.small};
		display: flex;
		margin-bottom: 40px;
		cursor: pointer;
		span {
			width: 18px;
			height: 18px;
			border-radius: 9px;
			background-color: #cfcfcf;
			color: #fff;
			text-align: center;
			line-height: 18px;
			font-weight: bold;
			margin-right: 5px;
		}
	}
`;
