import React from "react";
import styled from "styled-components";
import styleConstant from "data/constant/styleConstant";

interface ChatBtnProps {
  label: string;
  check: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function ChatBtn({ label, check, onClick }: ChatBtnProps) {
  return (
    <ChatSelectBtn onClick={onClick} check={check}>
      {label}
    </ChatSelectBtn>
  );
}

const ChatSelectBtn = styled.button<{ check: true | false }>`
  height: 32px;
  background-color: #fff;
  border: ${({ check }) => (check ? "solid 1px #1bbcff" : "solid 1px #cfcfcf")};
  border-radius: 20px;
  padding: 0 16px;

  font-size: ${styleConstant.FONT_SIZE.small};
`;
