import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { answerSubmit } from 'store/reducer/testPreviewReducer';
import { RootState } from 'store/reducer/rootReducer';
import { AskCard } from 'components/card';
import { DeleteBtnInput } from 'components/input';
import { PrimaryBtn } from 'components/button';
import { ErrorAlert } from 'components/alert';

export default function Plurar() {
	const questionIndex = useSelector((state: RootState) => state.testPreview.index);
	const question = useSelector((state: RootState) => state.testPreview.question[questionIndex]);
	const questionLength = useSelector((state: RootState) => state.testPreview.question.length);
	const preAnswer = useSelector(
		(state: RootState) => state.testPreview.answered[questionIndex]
	);

	const dispatch = useDispatch();

	const [checkAnswer, setCheckAnswer] = useState<string[]>([]);
	const [checkEnAnswer, setCheckEnAnswer] = useState<string[]>([]);
	const [alertToggle, setAlertToggle] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>('');
	const locale = localStorage.getItem('locale');

	const answers: string[] = question.answerList
		.split(';')
		.map((element: string) => element.trim())
		.slice(0, -1);
	const EnAnswers: string[] = question.EnQuestion?.answerList
		.split(';')
		.map((element: string) => element.trim())
		.slice(0, -1);

	useEffect(() => {
		window.scrollTo(0, 0);
		if (preAnswer !== undefined) {
			const splitAnswer = preAnswer.answer.split(';');
			const splitEnAnswer = preAnswer.enAnswer.split(';');
			if (answers.includes(splitAnswer[splitAnswer.length - 1])) {
				setCheckAnswer(splitAnswer);
				setCheckEnAnswer(splitEnAnswer);
			} else {
				if (splitAnswer.length > 1) {
					setCheckAnswer(splitAnswer.slice(0, -1));
					setCheckEnAnswer(splitEnAnswer.slice(0, -1));
					setInputValue(splitAnswer[splitAnswer.length - 1]);
				} else {
					setCheckAnswer(splitAnswer);
					setCheckEnAnswer(splitEnAnswer);
				}
			}
		} else {
			setCheckAnswer([]);
			setCheckEnAnswer([]);
		}
	}, [questionIndex]);

	function onNoneAnswerBtn() {
		setCheckEnAnswer([question.EnQuestion.noneAnswerText.trim()]);
		setCheckAnswer([question.noneAnswerText.trim()]);
		setInputValue(() => '');
	}

	function onQuestionBtn(e: any, idx: number) {
		if (
			checkAnswer?.includes(e.currentTarget.innerText.trim()) ||
			checkEnAnswer?.includes(e.currentTarget.innerText.trim())
		) {
			const answerIdx = checkAnswer.findIndex(
				(answer) => answer === answers[idx].trim()
			);
			setCheckAnswer(checkAnswer?.filter((_, idx) => idx !== answerIdx));
			setCheckEnAnswer(checkEnAnswer?.filter((_, idx) => idx !== answerIdx));
		} else {
			if (checkAnswer[0] === question.noneAnswerText || checkAnswer.length === 0) {
				setCheckAnswer([answers[idx].trim()]);
				setCheckEnAnswer([EnAnswers[idx].trim()]);
			} else {
				setCheckAnswer([...checkAnswer, answers[idx].trim()]);
				setCheckEnAnswer([...checkEnAnswer, EnAnswers[idx].trim()]);
			}
		}
	}

	function onInputChange({ target: { value } }: any) {
		setInputValue(value);
	}

	function onInputCancel() {
		setInputValue('');
	}

	function onNextBtn() {
		if (checkAnswer.length === 0) {
			setAlertToggle(true);
			setTimeout(() => setAlertToggle(false), 2000);
		} else {
			let answer: string = '';
			let enAnswer: string = '';
			checkAnswer.map((element: string, idx: number) => {
				if (idx === checkAnswer.length - 1) {
					answer = answer + element;
				} else {
					answer = `${answer}${element};`;
				}
			});
			checkEnAnswer.map((element: string, idx: number) => {
				if (idx === checkEnAnswer.length - 1) {
					enAnswer = enAnswer + element;
				} else {
					enAnswer = `${enAnswer}${element};`;
				}
			});

			if (question.isInput) {
				dispatch(
					answerSubmit({
						id: question.id,
						answer: `${answer};${inputValue}`,
						enAnswer: `${enAnswer};${inputValue}`,
					})
				);
			} else {
				dispatch(
					answerSubmit({
						id: question.id,
						answer,
						enAnswer,
					})
				);
			}
			setCheckAnswer([]);
			setCheckEnAnswer([]);
			setInputValue('');
		}
	}

	return (
		<>
			<ErrorAlert toggle={alertToggle} label='답변을 선택해주세요.' />
			<Cover>
				<p className='mainText'>
					{locale === 'en'
						? question.EnQuestion.mainQuestion
						: question.mainQuestion}
				</p>
				<p className='subText'>
					{locale === 'en'
						? question.EnQuestion.subQuestion
						: question.subQuestion}
				</p>
				{question.imgSrc ? (
					<ImgContainer>
						<img
							src={question.imgSrc}
							alt='question'
							width='450x'
							height='200x'
						/>
					</ImgContainer>
				) : null}
				<AnswerContainer>
					{question.isNoneAnswer ? (
						<AskCard
							item={
								locale === 'en'
									? question.EnQuestion.noneAnswerText.trim()
									: question.noneAnswerText.trim()
							}
							checked={
								checkAnswer[0] === question.noneAnswerText.trim()
							}
							clicked={onNoneAnswerBtn}
						/>
					) : null}
					{locale === 'en'
						? EnAnswers.map((text: string, index: number) => (
								<AskCard
									key={index}
									item={text}
									checked={checkEnAnswer?.includes(text)}
									clicked={(event) =>
										onQuestionBtn(event, index)
									}
								/>
						  ))
						: answers.map((text: string, index: number) => (
								<AskCard
									key={index}
									item={text}
									checked={checkAnswer?.includes(text)}
									clicked={(event: any) =>
										onQuestionBtn(event, index)
									}
								/>
						  ))}
				</AnswerContainer>
				{question.isInput ? (
					<InputContainer>
						<p>
							{locale === 'en'
								? question.EnQuestion.inputQuestion
								: question.inputQuestion}
						</p>
						<DeleteBtnInput
							value={inputValue}
							onChange={onInputChange}
							onCancel={onInputCancel}
						/>
					</InputContainer>
				) : null}
				{questionLength - 1 === questionIndex ? (
					<PrimaryBtn
						label={locale === 'ko' ? '제출' : 'Submit'}
						onClick={onNextBtn}
					/>
				) : (
					<PrimaryBtn
						label={locale === 'ko' ? '다음' : 'Continue'}
						onClick={onNextBtn}
					/>
				)}
			</Cover>
		</>
	);
}

const Cover = styled.div`
	margin-top: 24px;
	padding-bottom: 32px;
	font-weight: bold;
	.mainText {
		font-size: 24px;
		line-height: 133%;
		margin: 0 0 0;
	}
	.subText {
		font-size: 16px;
		line-height: 150%normal;
		margin: 8px 0 24px;
	}
`;

const ImgContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	height: auto;
	margin: 0 0 24px;
`;

const AnswerContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin: 0 0 32px;
`;

const InputContainer = styled.div`
	margin: 24px 0 32px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	p {
		font-size: 16px;
		font-weight: bold;
		line-height: 150%;
		margin: 0;
	}
`;
