import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import styleConstant from "data/constant/styleConstant";

interface SignupInputProps {
  type?: string;
  label?: string;
  icon?: ReactElement<any, any>;
  value: any;
  placeHolder?: string;
  check: boolean;
  checkText: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

export function SignupInput({
  type = "text",
  label = "",
  icon,
  value,
  placeHolder,
  check,
  checkText,
  onChange,
  onKeyDown,
}: SignupInputProps) {
  const [handleFocus, setHandleFocus] = useState<boolean>(false);

  function onInputFocus() {
    setHandleFocus(!handleFocus);
  }

  return (
    <Cover>
      {label !== "" ? <p className="label">{label}</p> : null}
      <InputContainer focus={handleFocus} check={check}>
        {icon}
        <Primary
          type={type}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={onInputFocus}
          onBlur={onInputFocus}
          placeholder={placeHolder}
        />
      </InputContainer>
      <CheckText check={check}>
        <span>!</span>
        {checkText}
      </CheckText>
    </Cover>
  );
}

const Cover = styled.div`
  width: 100%;
  .label {
    font-size: ${styleConstant.FONT_SIZE.moreSmall}!important;
    color: #969696 !important;
    margin: 0 0 4px 0 !important;
  }
  margin: 16px 0;
`;

const InputContainer = styled.div<{ focus: true | false; check: true | false }>`
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  padding: 8px 16px 12px 16px;
  border: ${({ focus, check }) =>
    focus
      ? "solid 1px #1bbcff"
      : check
      ? "solid 1px #cfcfcf"
      : "solid 1px #ffbf3f"};

  border-radius: 20px;
  margin: 0;
  background-color: #fff;
  display: flex;
  line-height: 30px;
`;

const Primary = styled.input`
  width: 100%;
  margin-top: 4px;
  margin-left: 10px;
  border: none;
  font-size: ${styleConstant.FONT_SIZE.medium};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      padding: 0;
      position: relative;
      top: -5px;
      height: 100%;
    }
    @supports (-webkit-overflow-scrolling: touch) {
      padding: 0;
    }
  }
`;

const CheckText = styled.p<{ check: true | false }>`
  display: ${({ check }) => (check ? "none" : "flex")};
  font-weight: 500 !important;
  font-size: ${styleConstant.FONT_SIZE.moreSmall} !important;
  color: #222222 !important;
  margin-top: 12px !important;
  span {
    width: 16px;
    height: 16px;
    color: #fff;
    text-align: center;
    padding-left: 1px;
    background-color: #ffbf3f;
    border-radius: 8px;
    line-height: 17px;
    margin-right: 8px;
  }
`;
