import styleConstant from "data/constant/styleConstant";
import React from "react";
import styled from "styled-components";

export function TextInput() {
  return <Text />;
}

const Text = styled.textarea`
  width: 100%;
  height: 286px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: ${styleConstant.FONT_SIZE.medium};
  border: solid 1px #cfcfcf;
  border-radius: 16px;
  padding: 16px 12px;
  resize: none;
  &:focus {
    outline: none;
    border: solid 1px #1bbcff;
    box-shadow: 0 0 0 1px #1bbcff;
  }
  :hover {
    border: 1px solid #27b4eb;
  }
`;
