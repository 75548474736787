import { createSlice } from "@reduxjs/toolkit";
import { ICounseling } from "data/interface/counseling";

type stateType = {
  counselingArr: ICounseling[];
  waitingArr: ICounseling[];
  healthyArr: ICounseling[];
  progressArr: ICounseling[];
  endArr: ICounseling[];
  searchArr: ICounseling[];
};

const initialState: stateType = {
  counselingArr: [],
  waitingArr: [],
  healthyArr: [],
  progressArr: [],
  endArr: [],
  searchArr: [],
};

// let classificationArr: ICounseling[] = [];
// action.payload?.map((counseling: ICounseling) => {
//   if (counseling.Chats.length === 0) {
//     classificationArr.push({ ...counseling, state: "waiting" });
//   } else {
//     counseling.Chats[0].isEnd
//       ? classificationArr.push({ ...counseling, state: "end" })
//       : classificationArr.push({ ...counseling, state: "progress" });
//   }
// });

const counselingSlice = createSlice({
  name: "counseling",
  initialState,
  reducers: {
    addAllCounseling: (state, action) => {
      let classifyArr: ICounseling[] = [];
      action.payload?.map((counseling: ICounseling) => {
        if (counseling.Chats.length === 0) {
          classifyArr.push({ ...counseling, status: "waiting" });
        } else {
          counseling.Chats[0].isEnd
            ? classifyArr.push({ ...counseling, status: "end" })
            : classifyArr.push({ ...counseling, status: "progress" });
        }
      });
      state.counselingArr = [...state.counselingArr, ...classifyArr];
    },
    addWaitingCounseling: (state, action) => {
      let statusArr: ICounseling[] = [];
      action.payload?.map((counseling: ICounseling) =>
        statusArr.push({ ...counseling, status: "waiting" }),
      );
      state.waitingArr = [...state.waitingArr, ...statusArr];
    },
    addHealthyCounseling: (state, action) => {
      let statusArr: ICounseling[] = [];
      action.payload?.map((counseling: ICounseling) => {
        if (counseling.isHealthy) {
          if (counseling.Chats.length === 0) {
            statusArr.push({ ...counseling, status: "waiting" });
          } else {
            counseling.Chats[0].isEnd
              ? statusArr.push({ ...counseling, status: "end" })
              : statusArr.push({ ...counseling, status: "progress" });
          }
        }
      });
      state.healthyArr = [...state.healthyArr, ...statusArr];
    },
    addProgressCounseling: (state, action) => {
      let statusArr: ICounseling[] = [];
      action.payload?.map((counseling: ICounseling) =>
        statusArr.push({ ...counseling, status: "progress" }),
      );
      state.progressArr = [...state.progressArr, ...statusArr];
    },
    addEndCounseling: (state, action) => {
      let statusArr: ICounseling[] = [];
      action.payload?.map((counseling: ICounseling) =>
        statusArr.push({ ...counseling, status: "end" }),
      );
      state.endArr = [...state.endArr, ...statusArr];
    },
    addSearchCounseling: (state, action) => {
      let classifyArr: ICounseling[] = [];
      action.payload?.map((counseling: ICounseling) => {
        if (counseling.Chats.length === 0) {
          classifyArr.push({ ...counseling, status: "waiting" });
        } else {
          counseling.Chats[0].isEnd
            ? classifyArr.push({ ...counseling, status: "end" })
            : classifyArr.push({ ...counseling, status: "progress" });
        }
      });
      state.searchArr = classifyArr;
    },
    allReset: (state) => {
      state.counselingArr = [];
      state.waitingArr = [];
      state.healthyArr = [];
      state.progressArr = [];
      state.endArr = [];
      state.searchArr = [];
    },
  },
});

export const {
  addAllCounseling,
  addWaitingCounseling,
  addHealthyCounseling,
  addProgressCounseling,
  addEndCounseling,
  addSearchCounseling,
  allReset,
} = counselingSlice.actions;
export default counselingSlice.reducer;
