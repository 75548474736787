import React from "react";
import styled from "styled-components";
import kakao from "assets/img/kakao.png";
import naver from "assets/img/naver.png";
import google from "assets/img/google.png";
import styleConstant from "data/constant/styleConstant";

export interface LoginBtnProps {
  label: string;
  platform: "kakao" | "naver" | "google";
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function LoginBtn({ label, platform, onClick }: LoginBtnProps) {
  let imgPath;
  if (platform === "kakao") {
    imgPath = kakao;
  } else if (platform === "naver") {
    imgPath = naver;
  } else if (platform === "google") {
    imgPath = google;
  }
  return (
    <Primary type="button" onClick={onClick} platform={platform}>
      <span>
        <img src={imgPath} alt="platform logo" width={32} />
      </span>
      {label}
    </Primary>
  );
}

const Primary = styled.button<{ platform: "kakao" | "naver" | "google" }>`
  width: 100%;
  height: 56px;
  margin: 16px 0;
  padding: 12px 16px;
  text-align: center;
  background-color: ${({ platform }) =>
    platform === "kakao" ? "#fae100" : "#fff"};
  border: ${({ platform }) =>
    platform === "kakao" ? "solid 1px #fae100" : "solid 1px #cfcfcf"};
  border-radius: 15px;
  font-size: ${styleConstant.FONT_SIZE.medium};
  font-weight: bold;
  letter-spacing: -0.2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  span {
    margin-right: 18px;
    line-height: 16px;
  }
  div {
    height: 34px;
  }
`;
