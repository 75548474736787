import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Table } from 'antd';
import useAxios from 'api/axiosCustom';
import { useNavigate } from 'react-router-dom';
import { LoadingModal } from 'components/modal';
import { PrimaryInput } from 'components/input';
import { PrimaryDropDown } from 'components/dropDown';
import { PrimaryBtn } from 'components/button';
import { TwoBtnModal } from 'components/modal';
import styleConstant from 'data/constant/styleConstant';
import { IQuestion } from 'data/interface/interviewManage';
import routerConstant from 'data/constant/routerConstant';

export function SelectItem() {
	const axios = useAxios();
	const navigate = useNavigate();

	const [columnData, setColumnData] = useState<IQuestion[]>([]);
	const [totalCount, setTotalCount] = useState<number>(0);
	const [page, setPage] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [title, setTitle] = useState<string>('');
	const [category, setCategory] = useState<string>('');
	const [isActive, setIsActive] = useState<string>('모두');
	const [isQuestionAddModalVisible, setIsQuestionAddModalVisible] = useState<boolean>(false);
	const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
	const [deleteId, setDeleteId] = useState<string>('');
	const [isSearch, setIsSearch] = useState<boolean>(false);
	const [questionEditValue, setQuestionEditValue] = useState<any>({});

	const listRequest = useCallback(() => {
		(async () => {
			setIsLoading(true);
			if (isSearch) {
				let res;
				if (isActive === '모두') {
					res = await axios.get(
						`/admin/recommend/item?title=${title}&category=${category}&offset=${page}`
					);
				} else {
					res = await axios.get(
						`/admin/recommend/item?title=${title}&category=${category}&isActive=${
							isActive === '활성' ? '1' : '0'
						}&offset=${page}`
					);
				}
				setColumnData(res.data.getRecommended);
			} else {
				const res = await axios.get(`/admin/recommend/item?offset=${page}`);

				setColumnData(res.data.getRecommended);
			}
			setIsLoading(false);
		})();
	}, [page]);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const res = await axios.get(`/admin/recommend/item?offset=${page}`);
			setColumnData(res.data.getRecommended);
			setTotalCount(res.data.totalCount);
			setIsLoading(false);
		})();
	}, []);

	useEffect(() => {
		listRequest();
	}, [page]);

	useEffect(() => {
		if (!isQuestionAddModalVisible) {
			setQuestionEditValue({});
		}
	}, [isQuestionAddModalVisible]);

	function onItemEditBtn(value: any) {
		navigate(`${value.id}`, {
			state: {
				value,
			},
		});
	}

	const columns = [
		{
			title: 'idx.',
			dataIndex: 'id',
			key: 'id',
			render: (text: any, value: any, index: any) => (
				<p
					className={index === 0 || index % 2 === 0 ? 'text color' : 'text'}
					onClick={() => onItemEditBtn(value)}>
					{text}
				</p>
			),
		},
		{
			title: '제목',
			dataIndex: 'title',
			key: 'title',
			render: (text: any, value: any, index: any) => (
				<p
					className={index === 0 || index % 2 === 0 ? 'text color' : 'text'}
					onClick={() => onItemEditBtn(value)}>
					{text}
				</p>
			),
		},
		{
			title: '카테고리',
			dataIndex: 'category',
			key: 'category',
			render: (text: any, value: any, index: any) => (
				<p
					className={index === 0 || index % 2 === 0 ? 'text color' : 'text'}
					onClick={() => onItemEditBtn(value)}>
					{text}
				</p>
			),
		},
		{
			title: '상태',
			dataIndex: 'isActive',
			key: 'isActive',
			render: (text: any, value: any, index: any) => (
				<p
					className={index === 0 || index % 2 === 0 ? 'text color' : 'text'}
					onClick={() => onItemEditBtn(value)}>
					{text === 0 ? '비활성' : '활성'}
				</p>
			),
		},
		{
			key: 'delete',
			render: (_: any, value: any, index: number) => (
				<p
					className={
						index === 0 || index % 2 === 0
							? 'delete text color'
							: 'delete text'
					}
					onClick={() => {
						setDeleteId(value.id);
						setIsDeleteModal(true);
					}}>
					삭제
				</p>
			),
		},
	];

	function onTitleInput(e: any) {
		setTitle(e.target.value);
	}

	function onCategoryInput(e: any) {
		setCategory(e.target.value);
	}

	function handleDropdown(e: any) {
		setIsActive(e);
	}

	function onQuestionAddModalCancelBtn() {
		setIsQuestionAddModalVisible(false);
	}

	function onDeleteModalCancelBtn() {
		setIsDeleteModal(false);
	}

	async function onDeleteModalCheckBtn() {
		if (deleteId) {
			await axios.delete(`/admin/recommend/item/${deleteId}`);
			setIsDeleteModal(false);
			listRequest();
		}
	}

	function onPageChange(e: any) {
		setPage(10 * (e - 1));
	}

	async function onSearchBtn() {
		setIsLoading(true);
		setPage(0);
		setIsSearch(true);
		let res;
		if (isActive === '모두') {
			res = await axios.get(
				`/admin/recommend/item?title=${title}&category=${category}&offset=${page}`
			);
		} else {
			res = await axios.get(
				`/admin/recommend/item?title=${title}&category=${category}&isActive=${
					isActive === '활성' ? '1' : '0'
				}&offset=${page}`
			);
		}
		setTotalCount(res.data.totalCount);
		setColumnData(res.data.getRecommended);
		setIsLoading(false);
	}

	return (
		<Cover>
			<LoadingModal isModalVisible={isLoading} />
			<TwoBtnModal
				isModalVisible={isDeleteModal}
				mainText='정말로 삭제하시겠습니까?'
				leftBtnText='아니요'
				leftBtnClick={onDeleteModalCancelBtn}
				rightBtnText='삭제하기'
				rightBtnColor='red'
				rightBtnClick={onDeleteModalCheckBtn}
			/>
			<SearchContainer>
				<p className='searchMainText'>검색하실 정보를 입력해주세요.</p>
				<InputContainer>
					<div className='input'>
						<PrimaryInput
							label='제목'
							value={title}
							onChange={onTitleInput}
						/>
					</div>
					<div className='input'>
						<PrimaryInput
							label='카테고리'
							value={category}
							onChange={onCategoryInput}
						/>
					</div>
					<div className='input'>
						<PrimaryDropDown
							label='상태'
							selectItem={isActive}
							items={['모두', '활성', '비활성']}
							itemClick={handleDropdown}
						/>
					</div>
					<div className='btn'>
						<PrimaryBtn label='검색' onClick={onSearchBtn} />
					</div>
				</InputContainer>
			</SearchContainer>
			<div className='btnContainer'>
				<PrimaryBtn
					label='새로운 추천 만들기'
					onClick={() => navigate(routerConstant.RECOMANATION_CREATE)}
				/>
			</div>
			<CustomTable
				columns={columns}
				dataSource={columnData}
				pagination={{
					total: totalCount,
					showSizeChanger: false,
					onChange: onPageChange,
				}}
			/>
		</Cover>
	);
}

const Cover = styled.div`
	margin-top: 48px;
	.btnContainer {
		width: 210px;
		margin: 0 auto 32px;
	}
	.delete {
		color: #e44b55;
		cursor: pointer;
		font-weight: normal !important;
	}
	.text {
		/* text-align: center; */
		padding-left: 17px;
		line-height: 56px;
		height: 56px;
		margin: 0;
		font-weight: bold;
		cursor: pointer;
	}
	.color {
		background-color: #f8f8f8;
	}
`;

const SearchContainer = styled.div`
	width: 100%;
	height: 188px;
	box-sizing: border-box;
	padding: 24px 24px 32px;
	margin-bottom: 48px;
	border-radius: 20px;
	box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.08);
	.searchMainText {
		font-size: ${styleConstant.FONT_SIZE.large};
		font-weight: bold;
		margin: 0;
	}
`;

const InputContainer = styled.div`
	display: flex;
	gap: 16px;
	margin-top: 12px;
	align-items: center;
	.input {
		width: 30%;
		margin: 0;
		padding: 0;
	}
	.btn {
		width: 120px;
		margin: 0;
		padding-top: 24px;
	}
`;

const CustomTable = styled(Table)`
	.ant-table-thead {
		th {
			padding: 8px 16px;
			border-top: 1px solid #f0f0f0;
			background-color: #fff;
			&::before {
				display: none;
			}
		}
	}
	.ant-table-tbody {
		td {
			padding: 0;
		}
	}
`;
