import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useAxios from 'api/axiosCustom';
import { PrimaryInput } from 'components/input';
import styleConstant from 'data/constant/styleConstant';
import { ReactComponent as SearchIcon } from 'assets/icon/interview/searchIcon.svg';
import { useDebounce } from 'hooks/useDebounce';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ISymptom } from 'data/interface/interviewManage';
import { ItemBox } from './itemBox';
import { SearchResultBox } from './searchResultBox';

interface SymptomAddComponentProps {
	value?: ISymptom;
	symptomList: ISymptom[];
	setSymptomList: any;
	symptomIdList: string[];
	setSymptomIdList: any;
}

export function SymptomAddComponent({
	value,
	symptomList,
	setSymptomList,
	symptomIdList,
	setSymptomIdList,
}: SymptomAddComponentProps) {
	const axios = useAxios();
	const [inputText, setInputText] = useState<string>('');
	const [searchData, setSearchData] = useState<any>([]);

	useEffect(() => {
		setInputText('');
	}, [value]);

	const debouncedInputText = useDebounce(inputText, 200);
	useEffect(() => {
		if (debouncedInputText) {
			symptomSearch(debouncedInputText);
		}
	}, [debouncedInputText]);

	function onMemberInfoChange(e: any) {
		setInputText(e.target.value);
	}

	const symptomSearch = async (debouncedInputText: any) => {
		const res = await axios.get(
			`/department/findSymptomById?limit=9999&search=${debouncedInputText}`
		);

		const newSymptomIdList = symptomList?.map((symptom) => symptom?.id);
		const filteredSymptomList = res.data.symptoms.filter(
			(symptom: any) => !newSymptomIdList?.includes(symptom.id)
		);
		setSearchData(filteredSymptomList);
	};

	const handleAddSymptom = (symptom: any) => {
		symptomIdList?.indexOf(symptom?.id) === -1 &&
			symptomList?.push(symptom) &&
			symptomIdList?.push(symptom?.id.toString());
		setSearchData([]);
		setInputText('');
	};

	const handleDeleteSymptom = (deletedSymptom: any) => {
		const newSymptomList = symptomList.filter(
			(symptom: any) => symptom?.id !== deletedSymptom?.id
		);
		setSymptomList(newSymptomList);
		const newSymptomIdList = newSymptomList.map((symptom) => symptom.id.toString());
		setSymptomIdList(newSymptomIdList);
	};

	const handleEnd = (result: any) => {
		if (!result.destination) return;
		const newSymptomList = symptomList;
		const [reorderedSymptom] = symptomList.splice(result.source.index, 1);
		newSymptomList.splice(result.destination.index, 0, reorderedSymptom);
		setSymptomList(newSymptomList);
		setSymptomIdList(newSymptomList.map((symptom: ISymptom) => symptom.id.toString()));
	};

	return (
		<SymptomComponent>
			<SearchContainer>
				<PrimaryInput
					label='증상 검색'
					icon={<SearchIcon />}
					value={inputText}
					onChange={onMemberInfoChange}
					placeHolder=' 원하는 증상을 입력해주세요.'
				/>
				{inputText !== '' &&
					(searchData.length > 0 ? (
						<ResultContainer>
							{searchData.map((symptom: any) => (
								<SearchResultBox
									item={symptom}
									itemName={symptom.name}
									handleAddItem={handleAddSymptom}
								/>
							))}
						</ResultContainer>
					) : (
						<ResultContainer>
							<Result>조회된 데이터가 없습니다.</Result>
						</ResultContainer>
					))}
			</SearchContainer>
			<SymptomTitle>증상 리스트</SymptomTitle>
			<DragDropContext onDragEnd={handleEnd}>
				<Droppable droppableId='droppable'>
					{(provided, snapshot) => {
						return (
							<SymptomContainer
								isDragging={snapshot.isDraggingOver}
								{...provided.droppableProps}
								ref={provided.innerRef}>
								{symptomList?.length ? (
									symptomList?.map((symptom: any, index) => (
										<Draggable
											key={symptom?.id}
											draggableId={symptom?.id?.toString()}
											index={index}>
											{(provided, snapshot) => (
												<ItemBox
													key={symptom?.id}
													id={symptom?.id}
													title={symptom?.name}
													provided={provided}
													snapshot={snapshot}
													handleDeleteItem={
														handleDeleteSymptom
													}
													item={
														symptom
													}></ItemBox>
											)}
										</Draggable>
									))
								) : (
									<NoSymptom>증상을 포함해주세요.</NoSymptom>
								)}
							</SymptomContainer>
						);
					}}
				</Droppable>
			</DragDropContext>
		</SymptomComponent>
	);
}

const SymptomComponent = styled.div`
	border: 1px solid ${styleConstant.COLOR.GRAY_3};
	border-radius: 16px;
	padding: 10px 10px 20px;
	box-shadow: 0 8px 15px rgba(0, 0, 0, 0.08);
`;
const SearchContainer = styled.div`
	position: relative;
`;

const SymptomTitle = styled.div`
	height: 20px;
	color: #919d9d;
	font-size: 12px;
	line-height: 20px;
	margin-bottom: 10px;
`;
const ResultContainer = styled.div`
	position: absolute;
	width: 100%;
	max-height: 350px;
	overflow: auto;
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	border: 1px solid #ecf0f0;
	border-radius: 16px;
	padding: 10px 0px;
	z-index: 1000;
	box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
		0 9px 28px 8px rgb(0 0 0 / 5%);
`;
const Result = styled.div`
	background-color: #ffffff;
	padding: 10px 16px;
	line-height: 24px;
	border-top: 1px solid ${styleConstant.COLOR.GRAY_1};
	border-bottom: 1px solid ${styleConstant.COLOR.GRAY_1};
	:hover {
		background: #f4fbfe;
	}
`;

const SymptomContainer = styled.div<{ isDragging: true | false }>`
	display: flex;
	flex-direction: column;
	border: 1px solid #bec6c4;
	border-radius: 16px;
	padding: ${({ isDragging }) => (isDragging ? '15px 0 59px' : '15px 0')};
	z-index: 800;
`;

const NoSymptom = styled.div`
	color: ${styleConstant.COLOR.GRAY_4};
	margin: 0 20px;
`;
