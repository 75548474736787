import React from "react";
import styled from "styled-components";
import { CategorySymptom } from "./categorySymptom";
import styleConstant from "data/constant/styleConstant";

interface Props {
  questions: any[];
}

const Cover = styled.div`
  width: 328px;
  .profileHeader {
    display: flex;
    justify-content: flex-start;
    margin: 0 0 8px 0;
    font-size: ${styleConstant.FONT_SIZE.small};
    line-height: 150%;
    letter-spacing: -0.2px;
    font-weight: bold;
  }
`;

const InfoContainer = styled.div`
  width: 328px;
  padding: 16px;
  margin: 0;
  background-color: #f8f8f8;
  border-radius: 16px;
`;

export function ProfileQuestion({ questions }: Props) {
  return (
    <Cover>
      <p className="profileHeader">사용자 질문</p>
      <InfoContainer>
        {questions?.map((question, idx) => (
          <CategorySymptom
            key={idx}
            question={question.mainQuestion}
            answer={question.ProfileQuestion.answer}
            last={idx === questions.length - 1}
          />
        ))}
      </InfoContainer>
    </Cover>
  );
}
