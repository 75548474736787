import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { allReset } from 'store/reducer/counselingReducer';
import { HeaderBar } from 'components/header';
import { PreDiagnosisCard } from 'components/card';
import CounselingType from 'components/counseling';
import styleConstant from 'data/constant/styleConstant';
import menuConstant from 'data/constant/menuConstant';

export default function CounselingPage() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [menuState, setMenuState] = useState<string>('전체');

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);
	// useEffect(() => {
	// 	window.setTimeout('window.location.reload()', 60000);
	// });
	function onMenuBtn(e: any) {
		if (menuState !== e.target.innerText.trim()) {
			dispatch(allReset());
			setMenuState(e.target.innerText.trim());
		}
	}

	return (
		<Cover>
			<HeaderBar noBack={true} nowPath={menuConstant.COUNSELING} />
			<p className='mainText'>문진 상담</p>
			<MenuContainer>
				<span className={menuState === '전체' ? 'select' : ''} onClick={onMenuBtn}>
					전체
				</span>
				<span
					className={menuState === '상담 대기' ? 'select' : ''}
					onClick={onMenuBtn}>
					상담 대기
				</span>
				<span
					className={menuState === '건강 상담' ? 'select' : ''}
					onClick={onMenuBtn}>
					건강 상담
				</span>
				<span
					className={menuState === '상담 중' ? 'select' : ''}
					onClick={onMenuBtn}>
					상담 중
				</span>
				<span
					className={menuState === '상담 종료' ? 'select' : ''}
					onClick={onMenuBtn}>
					상담 종료
				</span>
			</MenuContainer>
			<CounselingType type={menuState} />
		</Cover>
	);
}

const Cover = styled.div`
	width: 1024px;
	margin: 0 auto;
	padding-bottom: 32px;
	text-align: start;
	.mainText {
		font-size: 40px;
		font-weight: bold;
		margin-top: 48px;
	}
`;

const MenuContainer = styled.div`
	margin-bottom: 68px;
	span {
		margin-right: 16px;
		font-size: ${styleConstant.FONT_SIZE.medium};
		cursor: pointer;
	}
	.select {
		padding-bottom: 4px;
		font-weight: bold;
		border-bottom: solid 2px #1bbcff;
	}
`;
