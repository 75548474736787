import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import { TwoBtnModal } from 'components/modal';
import logo from 'assets/img/logo-horizontal.png';
import { ReactComponent as BackIcon } from 'assets/icon/backIcon.svg';

import styleConstant from 'data/constant/styleConstant';
import menuConstant from 'data/constant/menuConstant';
import routerConstant from 'data/constant/routerConstant';

interface IHeaderBar {
	noBack?: boolean;
	loginPage?: boolean;
	nowPath?: string;
}

export function HeaderBar({ noBack = false, loginPage = false, nowPath }: IHeaderBar) {
	const navigate = useNavigate();
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

	function onRouterBtn(path: string) {
		navigate(path);
	}

	function onBackBtn() {
		navigate(-1);
	}

	function onLogoutBtn() {
		setIsModalVisible(true);
	}

	function onModalCancel() {
		setIsModalVisible(false);
	}

	function onLogoutCheckBtn() {
		localStorage.clear();
		navigate(routerConstant.LOGIN);
	}

	return (
		<Cover noBack={noBack}>
			<TwoBtnModal
				isModalVisible={isModalVisible}
				mainText='로그아웃 하시겠어요?'
				leftBtnClick={onModalCancel}
				leftBtnText='아니요'
				rightBtnClick={onLogoutCheckBtn}
				rightBtnText='로그아웃'
			/>
			<Header loginPage={loginPage}>
				<Link to='/'>
					<img src={logo} alt='header bar logo' />
				</Link>
				<MenuContainer loginPage={loginPage}>
					<p
						className={
							nowPath === menuConstant.AFFILIATION_MANAGE
								? 'select'
								: ''
						}
						onClick={() => onRouterBtn('/affiliation')}>
						소속 관리
					</p>
					<p
						className={
							nowPath === menuConstant.INTERVIEW_MANAGE ? 'select' : ''
						}
						onClick={() => onRouterBtn('/interview')}>
						문진 관리
					</p>
					<p
						className={nowPath === menuConstant.COUNSELING ? 'select' : ''}
						onClick={() => onRouterBtn('/counseling')}>
						문진 상담
					</p>
					<p
						className={
							nowPath === menuConstant.MEMBER_INQUIRY ? 'select' : ''
						}
						onClick={() => onRouterBtn('/member')}>
						회원 조회
					</p>
					<p
						className={
							nowPath === menuConstant.RECOMMENDATION_MANAGE
								? 'select'
								: ''
						}
						onClick={() => onRouterBtn('/recommendation')}>
						추천 관리
					</p>
					<p onClick={onLogoutBtn}>로그아웃</p>
				</MenuContainer>
			</Header>
			<BackIcon className='back' onClick={onBackBtn} />
		</Cover>
	);
}

const Cover = styled.div<{ noBack: true | false }>`
	margin-bottom: 0;
	background-color: #fff;
	svg {
		cursor: pointer;
	}
	.back {
		display: ${({ noBack }) => (noBack ? 'none' : '')};
		margin: 70px 0 2px 0;
	}
`;

const Header = styled.div<{ loginPage: true | false }>`
	width: 100%;
	height: 68px;
	display: flex;
	justify-content: ${({ loginPage }) => (loginPage ? 'center' : 'space-between')};
	align-items: center;
	img {
		width: 115px;
		height: 40px;
	}
`;

const MenuContainer = styled.div<{ loginPage: true | false }>`
	display: ${({ loginPage }) => (loginPage ? 'none' : 'flex')};
	font-size: ${styleConstant.FONT_SIZE.medium};
	font-weight: 500;
	padding-top: 34px;
	p {
		margin-left: 32px;
		cursor: pointer;
	}
	.select {
		font-weight: bold;
		color: #1bbcff;
	}
`;
