import React from "react";
import styled from "styled-components";
import { Modal } from "antd";
import { PrimaryBtn } from "components/button";
import Logo from "assets/img/logo.png";
import styleConstant from "data/constant/styleConstant";

interface PersonalBrandingModalProps {
  isModalVisible: boolean;
  name?: string;
  part?: string;
  department?: string;
  profileImg?: string;
  career?: string;
  introduce?: string;
  btnText: string;
  onBtnClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function PersonalBrandingModal({
  isModalVisible,
  name,
  part,
  department,
  profileImg,
  career,
  introduce,
  btnText,
  onBtnClick,
}: PersonalBrandingModalProps) {
  return (
    <StyledModal
      visible={isModalVisible}
      maskClosable={false}
      centered={true}
      closable={false}
      footer={null}
    >
      <Header>
        <ImgContainer>
          <img src={Logo} alt="default logo" />
        </ImgContainer>
        <TextContainer>
          <p className="name">{name} 교수</p>
          <p className="part">{part}</p>
        </TextContainer>
      </Header>
      <InfoContainer>
        <p className="title">진료과목</p>
        <p className="info">{department}</p>
        <p className="title">소개</p>
        <p className="info">{introduce}</p>
        <p className="title">경력</p>
        <p className="info">{career}</p>
      </InfoContainer>
      <PrimaryBtn label={btnText} onClick={onBtnClick} />
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
    width: 400px;
  }
`;

const Header = styled.div`
  display: flex;
`;

const ImgContainer = styled.div`
  width: 80px;
  height: 80px;
  border: solid 1px #e6e6e6;
  border-radius: 40px;
  margin-right: 16px;
  text-align: center;
  line-height: 70px;
  img {
    width: 60px;
    height: 50px;
  }
`;

const TextContainer = styled.div`
  .name {
    font-size: 20px;
    font-weight: bold;
    margin: 8px 0 7px 0;
  }
  .part {
    font-size: ${styleConstant.FONT_SIZE.small};
    margin: 0;
  }
`;

const InfoContainer = styled.div`
  margin: 24px 0;
  .title {
    font-size: ${styleConstant.FONT_SIZE.moreSmall};
    color: #969696;
    margin: 0 0 8px 0;
  }
  .info {
    font-size: ${styleConstant.FONT_SIZE.small};
    margin: 0 0 48px 0;
  }
`;
