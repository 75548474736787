import React from "react";
import styled from "styled-components";
import { ReactComponent as CheckCircle } from "assets/icon/interview/checkCircle.svg";

export interface HalfAskCardProps {
  item: string;
  checked: boolean;
  clicked?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export function HalfAskCard({ item, checked, clicked }: HalfAskCardProps) {
  return (
    <Cover checked={checked} onClick={clicked}>
      <ItemContainer>{item}</ItemContainer>
      {checked ? <CheckContainer /> : <UnCheckContainer />}
    </Cover>
  );
}

const Cover = styled.div<{ checked: true | false }>`
  width: 46.5%;
  height: 56px;
  padding: 16px;
  border: ${({ checked }) =>
    checked ? "solid 1px #1bbcff" : "solid 1px #cfcfcf"};
  border-radius: 16px;
  font-family: "Noto Sans KR", sans-serif;
  display: flex;
  align-items: center;
  :hover {
    border: 1px solid #27b4eb;
  }
  :active {
    border: 1px solid #0083b1;
  }
`;

const ItemContainer = styled.div`
  width: 90%;
  font-size: 16px;
  line-height: 150%;
  font-weight: bold;
`;

const CheckContainer = styled(CheckCircle)`
  margin: 0 0 0 10px;
  min-width: 24px;
  min-height: 24px;
`;

const UnCheckContainer = styled.div`
  min-width: 24px;
  min-height: 24px;
  margin: 0 0 0 10px;
  border: solid 2px #cfcfcf;
  border-radius: 12px;
`;
