import React from "react";
import styled from "styled-components";
import { Modal } from "antd";
import { PrimaryBtn } from "components/button";
import styleConstant from "data/constant/styleConstant";

interface OneBtnModalProps {
  isModalVisible: boolean;
  mainText: string;
  topText?: string;
  bottomText?: string;
  btnText: string;
  onBtnClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function OneBtnModal({
  isModalVisible,
  mainText,
  topText,
  bottomText,
  btnText,
  onBtnClick,
}: OneBtnModalProps) {
  return (
    <StyledModal
      visible={isModalVisible}
      maskClosable={false}
      centered={true}
      closable={false}
      footer={null}
    >
      <TextContainer>
        <p className="subText">{topText}</p>
        <p className="mainText">{mainText}</p>
        <p className="subText">{bottomText}</p>
      </TextContainer>
      <PrimaryBtn label={btnText} onClick={onBtnClick} />
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;

const TextContainer = styled.div`
  .mainText {
    font-size: ${styleConstant.FONT_SIZE.large};
    font-weight: bold;
  }
  .subText {
    font-size: ${styleConstant.FONT_SIZE.medium};
  }
`;
