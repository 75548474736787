import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SearchInput } from 'components/input';
import { PrimaryBtn } from 'components/button';
import styleConstant from 'data/constant/styleConstant';
import { useDebounce } from 'hooks/useDebounce';

interface SearchInputContainerProps {
	name: string;
	setName: React.Dispatch<React.SetStateAction<string>>;
	birth: string;
	setBirth: React.Dispatch<React.SetStateAction<string>>;
}

export function SearchInputContainer({
	name,
	setName,
	birth,
	setBirth,
}: SearchInputContainerProps) {
	const [inputName, setInputName] = useState<string>('');
	const [inputBirth, setInputBirth] = useState<string>('');

	useEffect(() => {
		if (birth?.length === 8) {
			setBirth(birth.replace(/-/g, '').replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'));
		}
	}, [birth]);

	function onNameInput(e: any) {
		setInputName(e.target.value);
	}

	function onBirthInput(e: any) {
		const regex = /^[0-9\b -]{0,10}$/;
		if (regex.test(e.target.value)) {
			setInputBirth(e.target.value);
		}
	}

	function onNameInputCancel() {
		setName('');
		setInputName('');
	}

	function onBirthInputCancel() {
		setBirth('');
		setInputBirth('');
	}

	const debounceName = useDebounce(inputName, 200);
	const debounceBirth = useDebounce(inputBirth, 200);

	useEffect(() => {
		if (debounceName !== '' || debounceBirth !== '') {
			setName(debounceName);
			setBirth(debounceBirth);
		} else {
			setName('');
			setBirth('');
		}
	}, [debounceName, debounceBirth]);

	return (
		<SearchContainer>
			<p className='searchMainText'>검색하실 사용자의 정보를 입력해주세요.</p>
			<InputContainer>
				<div className='input'>
					<SearchInput
						label='이름'
						placeHolder='이름을 입력해주세요'
						value={inputName}
						onChange={onNameInput}
						onCancel={onNameInputCancel}
					/>
				</div>
				<div className='input'>
					<SearchInput
						label='생년월일'
						placeHolder='생년월일을 입력해주세요.'
						value={inputBirth}
						onChange={onBirthInput}
						onCancel={onBirthInputCancel}
					/>
				</div>
				{/* <div className='btn'>
					<PrimaryBtn label='검색' onClick={onClick} />
				</div> */}
			</InputContainer>
		</SearchContainer>
	);
}

const SearchContainer = styled.div`
	width: 100%;
	height: auto;
	box-sizing: border-box;
	padding: 24px;
	margin-bottom: 20px;
	border-radius: 20px;
	box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.08);
	.searchMainText {
		font-size: ${styleConstant.FONT_SIZE.large};
		font-weight: bold;
		margin: 0;
	}
`;

const InputContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	align-items: center;
	gap: 16px;
	.input {
		width: 50%;
		margin: 0;
		padding: 0;
	}
	.btn {
		width: 8%;
		margin: 0;
		padding-top: 24px;
	}
`;
