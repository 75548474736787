import React from "react";
import "./App.css";
import "antd/dist/antd.css";
import { Router } from "./routes";
import axios from "api/axiosCustom";
import { SWRConfig } from "swr";

declare global {
  interface Window {
    naver: any;
    gotoLoginAccess: any;
  }
}

function App() {
  const swrConfig = {
    fetcher(url: string, params: object) {
      axios()
        .get(url, { params })
        .then((res) => res.data);
    },
  };

  return (
    <SWRConfig value={swrConfig}>
      <div className="App">
        <Router />
      </div>
    </SWRConfig>
  );
}

export default App;
