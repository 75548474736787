import { createSlice } from "@reduxjs/toolkit";

interface ITestPreviewInitState {
  index: number;
  question: any;
  answered: any;
}

const testPreviewInitState: ITestPreviewInitState = {
  index: 0,
  question: [],
  answered: [],
};

const testPreviewSlice = createSlice({
  name: "testPreview",
  initialState: testPreviewInitState,
  reducers: {
    questionAdd: (state, action) => {
      if (action.payload.questionIds) {
        let pushQuestionArr: any = [];
        const questionSequence = action.payload.questionIds
          .split(";")
          .slice(0, -1);

        for (let i = 0; i <= questionSequence.length - 1; i++) {
          action.payload.Questions.map((question: any) =>
            String(question.id) === String(questionSequence[i])
              ? pushQuestionArr.push(question)
              : null,
          );
        }
        state.question = pushQuestionArr;
      } else {
        state.question = action.payload.Questions;
      }
    },
    answerSubmit: (state, action) => {
      const index = state.answered.findIndex(
        (element: any) => element.id === action.payload.id,
      );

      index === -1
        ? state.answered.push(action.payload)
        : (state.answered[index] = action.payload);

      if (!action.payload.end) {
        state.index++;
      }
    },
    inputUnitReset: (state, action) => {
      if (!state.question[action.payload].inputUnit) {
        state.question[action.payload] = {
          ...state.question[action.payload],
          inputUnit: " ",
          EnQuestion: {
            ...state.question[action.payload].EnQuestion,
            inputUnit: " ",
          },
        };
      }
    },
    goBack: (state) => {
      state.index--;
    },
    allReset: (state) => {
      state.index = 0;
      state.question = [];
      state.answered = [];
    },
  },
});

export const { questionAdd, answerSubmit, inputUnitReset, goBack, allReset } =
  testPreviewSlice.actions;
export default testPreviewSlice.reducer;
